// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import { SetDesiredPayoutCoachFragmentDoc } from '../../__generated-gql-types__/SetDesiredPayout.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TrialSessionOnboardingUpdateOptInTrialSessionsMutationVariables =
  Types.Exact<{
    optInTrialSessions: Types.Scalars['Boolean']['input'];
  }>;

export type TrialSessionOnboardingUpdateOptInTrialSessionsMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: string;
    coach?: {
      __typename?: 'Coach';
      id: string;
      optInTrialSessions?: boolean | null;
    } | null;
  };
};

export type TrialSessionOnboardingCoachFragment = {
  __typename?: 'Coach';
  id: string;
  tier: Types.SkuTier;
  desiredPayout?: number | null;
  optInTrialSessions?: boolean | null;
  hourlyRate?: number | null;
  openToLowerThanDesiredPayout?: boolean | null;
};

export type TrialSessionOnboardingUserQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type TrialSessionOnboardingUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    coach?: {
      __typename?: 'Coach';
      id: string;
      tier: Types.SkuTier;
      desiredPayout?: number | null;
      optInTrialSessions?: boolean | null;
      hourlyRate?: number | null;
      openToLowerThanDesiredPayout?: boolean | null;
    } | null;
  } | null;
};

export type TrialSessionOnboardingOneSessionMonthlySkuQueryVariables =
  Types.Exact<{
    tier: Types.SkuTier;
  }>;

export type TrialSessionOnboardingOneSessionMonthlySkuQuery = {
  __typename?: 'Query';
  findSkuBySession: { __typename?: 'Sku'; id: string; unitPayout: number };
};

export const TrialSessionOnboardingCoachFragmentDoc = gql`
  fragment TrialSessionOnboarding_Coach on Coach {
    id
    tier
    desiredPayout
    optInTrialSessions
    ...SetDesiredPayout_Coach
  }
  ${SetDesiredPayoutCoachFragmentDoc}
`;
export const TrialSessionOnboardingUpdateOptInTrialSessionsDocument = gql`
  mutation TrialSessionOnboarding_UpdateOptInTrialSessions(
    $optInTrialSessions: Boolean!
  ) {
    updateUser(coach: { optInTrialSessions: $optInTrialSessions }) {
      id
      coach {
        id
        optInTrialSessions
      }
    }
  }
`;
export type TrialSessionOnboardingUpdateOptInTrialSessionsMutationFn =
  Apollo.MutationFunction<
    TrialSessionOnboardingUpdateOptInTrialSessionsMutation,
    TrialSessionOnboardingUpdateOptInTrialSessionsMutationVariables
  >;

/**
 * __useTrialSessionOnboardingUpdateOptInTrialSessionsMutation__
 *
 * To run a mutation, you first call `useTrialSessionOnboardingUpdateOptInTrialSessionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrialSessionOnboardingUpdateOptInTrialSessionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trialSessionOnboardingUpdateOptInTrialSessionsMutation, { data, loading, error }] = useTrialSessionOnboardingUpdateOptInTrialSessionsMutation({
 *   variables: {
 *      optInTrialSessions: // value for 'optInTrialSessions'
 *   },
 * });
 */
export function useTrialSessionOnboardingUpdateOptInTrialSessionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrialSessionOnboardingUpdateOptInTrialSessionsMutation,
    TrialSessionOnboardingUpdateOptInTrialSessionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TrialSessionOnboardingUpdateOptInTrialSessionsMutation,
    TrialSessionOnboardingUpdateOptInTrialSessionsMutationVariables
  >(TrialSessionOnboardingUpdateOptInTrialSessionsDocument, options);
}
export type TrialSessionOnboardingUpdateOptInTrialSessionsMutationHookResult =
  ReturnType<typeof useTrialSessionOnboardingUpdateOptInTrialSessionsMutation>;
export type TrialSessionOnboardingUpdateOptInTrialSessionsMutationResult =
  Apollo.MutationResult<TrialSessionOnboardingUpdateOptInTrialSessionsMutation>;
export type TrialSessionOnboardingUpdateOptInTrialSessionsMutationOptions =
  Apollo.BaseMutationOptions<
    TrialSessionOnboardingUpdateOptInTrialSessionsMutation,
    TrialSessionOnboardingUpdateOptInTrialSessionsMutationVariables
  >;
export const TrialSessionOnboardingUserDocument = gql`
  query TrialSessionOnboarding_User {
    user {
      id
      coach {
        ...TrialSessionOnboarding_Coach
      }
    }
  }
  ${TrialSessionOnboardingCoachFragmentDoc}
`;

/**
 * __useTrialSessionOnboardingUserQuery__
 *
 * To run a query within a React component, call `useTrialSessionOnboardingUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrialSessionOnboardingUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrialSessionOnboardingUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrialSessionOnboardingUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TrialSessionOnboardingUserQuery,
    TrialSessionOnboardingUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TrialSessionOnboardingUserQuery,
    TrialSessionOnboardingUserQueryVariables
  >(TrialSessionOnboardingUserDocument, options);
}
export function useTrialSessionOnboardingUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrialSessionOnboardingUserQuery,
    TrialSessionOnboardingUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TrialSessionOnboardingUserQuery,
    TrialSessionOnboardingUserQueryVariables
  >(TrialSessionOnboardingUserDocument, options);
}
export function useTrialSessionOnboardingUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TrialSessionOnboardingUserQuery,
    TrialSessionOnboardingUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    TrialSessionOnboardingUserQuery,
    TrialSessionOnboardingUserQueryVariables
  >(TrialSessionOnboardingUserDocument, options);
}
export type TrialSessionOnboardingUserQueryHookResult = ReturnType<
  typeof useTrialSessionOnboardingUserQuery
>;
export type TrialSessionOnboardingUserLazyQueryHookResult = ReturnType<
  typeof useTrialSessionOnboardingUserLazyQuery
>;
export type TrialSessionOnboardingUserSuspenseQueryHookResult = ReturnType<
  typeof useTrialSessionOnboardingUserSuspenseQuery
>;
export type TrialSessionOnboardingUserQueryResult = Apollo.QueryResult<
  TrialSessionOnboardingUserQuery,
  TrialSessionOnboardingUserQueryVariables
>;
export const TrialSessionOnboardingOneSessionMonthlySkuDocument = gql`
  query TrialSessionOnboarding_OneSessionMonthlySku($tier: SkuTier!) {
    findSkuBySession(tier: $tier, period: MONTHLY, numberOfSessions: 1) {
      id
      unitPayout
    }
  }
`;

/**
 * __useTrialSessionOnboardingOneSessionMonthlySkuQuery__
 *
 * To run a query within a React component, call `useTrialSessionOnboardingOneSessionMonthlySkuQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrialSessionOnboardingOneSessionMonthlySkuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrialSessionOnboardingOneSessionMonthlySkuQuery({
 *   variables: {
 *      tier: // value for 'tier'
 *   },
 * });
 */
export function useTrialSessionOnboardingOneSessionMonthlySkuQuery(
  baseOptions: Apollo.QueryHookOptions<
    TrialSessionOnboardingOneSessionMonthlySkuQuery,
    TrialSessionOnboardingOneSessionMonthlySkuQueryVariables
  > &
    (
      | {
          variables: TrialSessionOnboardingOneSessionMonthlySkuQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TrialSessionOnboardingOneSessionMonthlySkuQuery,
    TrialSessionOnboardingOneSessionMonthlySkuQueryVariables
  >(TrialSessionOnboardingOneSessionMonthlySkuDocument, options);
}
export function useTrialSessionOnboardingOneSessionMonthlySkuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrialSessionOnboardingOneSessionMonthlySkuQuery,
    TrialSessionOnboardingOneSessionMonthlySkuQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TrialSessionOnboardingOneSessionMonthlySkuQuery,
    TrialSessionOnboardingOneSessionMonthlySkuQueryVariables
  >(TrialSessionOnboardingOneSessionMonthlySkuDocument, options);
}
export function useTrialSessionOnboardingOneSessionMonthlySkuSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TrialSessionOnboardingOneSessionMonthlySkuQuery,
    TrialSessionOnboardingOneSessionMonthlySkuQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    TrialSessionOnboardingOneSessionMonthlySkuQuery,
    TrialSessionOnboardingOneSessionMonthlySkuQueryVariables
  >(TrialSessionOnboardingOneSessionMonthlySkuDocument, options);
}
export type TrialSessionOnboardingOneSessionMonthlySkuQueryHookResult =
  ReturnType<typeof useTrialSessionOnboardingOneSessionMonthlySkuQuery>;
export type TrialSessionOnboardingOneSessionMonthlySkuLazyQueryHookResult =
  ReturnType<typeof useTrialSessionOnboardingOneSessionMonthlySkuLazyQuery>;
export type TrialSessionOnboardingOneSessionMonthlySkuSuspenseQueryHookResult =
  ReturnType<typeof useTrialSessionOnboardingOneSessionMonthlySkuSuspenseQuery>;
export type TrialSessionOnboardingOneSessionMonthlySkuQueryResult =
  Apollo.QueryResult<
    TrialSessionOnboardingOneSessionMonthlySkuQuery,
    TrialSessionOnboardingOneSessionMonthlySkuQueryVariables
  >;
