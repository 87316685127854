// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  ApplicantUrn: { input: string; output: string };
  CategoryUrn: { input: string; output: string };
  ClassUrn: { input: string; output: string };
  CoachUrn: { input: string; output: string };
  CoachingCategoryUrn: { input: string; output: string };
  CoachingFocusUrn: { input: string; output: string };
  CoachingOutcomeUrn: { input: string; output: string };
  CoachingRelationshipUrn: { input: string; output: string };
  CoachingSessionUrn: { input: string; output: string };
  CoachingTimeLogUrn: { input: string; output: string };
  CompanyUrn: { input: string; output: string };
  CouponUrn: { input: string; output: string };
  CustomerCreditUrn: { input: string; output: string };
  CustomerGoalUrn: { input: string; output: string };
  CustomerSubscriptionUrn: { input: string; output: string };
  Date: { input: string; output: string };
  DepartmentUrn: { input: string; output: string };
  EntityReviewUrn: { input: string; output: string };
  JobTitleUrn: { input: string; output: string };
  LocalTime: { input: string; output: string };
  Long: { input: number; output: number };
  MatchedCoachUrn: { input: string; output: string };
  MediaUrn: { input: string; output: string };
  NonNegativeInt: { input: number; output: number };
  OrderUrn: { input: string; output: string };
  PackageUrn: { input: string; output: string };
  RecommendedOfferingUrn: { input: string; output: string };
  ReviewUrn: { input: string; output: string };
  SchoolUrn: { input: string; output: string };
  ShindigUrn: { input: string; output: string };
  SkuUrn: { input: string; output: string };
  StripePaymentMethodUrn: { input: string; output: string };
  SubCategoryUrn: { input: string; output: string };
  SubCategoryV2Urn: { input: string; output: string };
  TagUrn: { input: string; output: string };
  Upload: { input: File; output: File };
  Urn: { input: string; output: string };
  UserUrn: { input: string; output: string };
};

export type Applicant = {
  __typename?: 'Applicant';
  /**  Whether all the active orders between the coach and the client have expired */
  allActiveOrdersExpired?: Maybe<Scalars['Boolean']['output']>;
  applicationTiming?: Maybe<ApplicationTiming>;
  bio?: Maybe<Scalars['String']['output']>;
  /**  ignored if there's a match between coach and customer */
  blockIntroUntil?: Maybe<Scalars['Long']['output']>;
  categoriesInterested?: Maybe<Array<Category>>;
  coachingRelationship?: Maybe<CoachingRelationship>;
  /**  This is a light-weight call to get all coaching relationships. For more complex logic, use `coachingRelationshipsForCurrentApplicant` instead */
  coachingRelationships?: Maybe<Array<CoachingRelationship>>;
  coachingTimeSummary?: Maybe<CoachingTimeSummary>;
  /**  epoch in milli-second */
  createdAt: Scalars['Long']['output'];
  customerGoalCategories?: Maybe<Array<CategorySelection>>;
  customerGoals?: Maybe<Array<CustomerGoal>>;
  /**
   *  Limited to four coach contacts per day, 8 between purchases
   * @deprecated No longer supported
   */
  dailyCoachContactsRemaining?: Maybe<Scalars['Int']['output']>;
  /**  current login customer has ever bought coaching from a coach */
  everBoughtCoaching?: Maybe<Scalars['Boolean']['output']>;
  gpaScores?: Maybe<Array<GpaScore>>;
  hasActiveOrder?: Maybe<Scalars['Boolean']['output']>;
  /**  For the coach, when there are package(s) purchased and not marked as completed */
  hasPackageInProgress?: Maybe<Scalars['Boolean']['output']>;
  hasUnassignedTrialOrder?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ApplicantUrn']['output'];
  introSummary?: Maybe<IntroSummary>;
  latestUnassignedOrder?: Maybe<Order>;
  /** @deprecated No longer supported */
  lelandMembership?: Maybe<LelandMembership>;
  lelandPlus?: Maybe<LelandPlus>;
  linkedinResponse?: Maybe<LinkedinResponse>;
  /**  Get matched coach object between the current login coach and the target applicant */
  matchedCoach?: Maybe<MatchedCoach>;
  /**  Find all matched coaches for the current login applicant */
  matchedCoaches?: Maybe<Array<MatchedCoach>>;
  matchesPending?: Maybe<Scalars['Boolean']['output']>;
  moneyCreditAvailable: Scalars['Int']['output'];
  newExperience?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated No longer supported */
  nextCoachContactAvailableAt?: Maybe<Scalars['Long']['output']>;
  outcomes?: Maybe<Array<CoachingOutcome>>;
  preference?: Maybe<ApplicantPreference>;
  smsEnrollments: Array<SmsEnrollment>;
  testScores?: Maybe<Array<TestScore>>;
  /**  totalGrantedCredit - totalUsedCredit - futureScheduledSessionTime */
  timeCreditAvailable: Scalars['Int']['output'];
  /**
   *  Life time coached between the current login coach and this applicant
   * @deprecated No longer supported
   */
  totalCoachingTimeInMinute?: Maybe<Scalars['Int']['output']>;
  /**  For new experience, it is the credit time this applicant has. For old experience, it is the time this applicant has purchased for the current login coach */
  totalPurchasedTimeInMinute?: Maybe<Scalars['Int']['output']>;
  /**  Get unassigned orders for this applicant */
  unassignedOrders?: Maybe<Array<Order>>;
  upcomingEnrolledSessions?: Maybe<Array<CoachingSession>>;
  user: User;
};

export type ApplicantCoachingRelationshipsArgs = {
  statuses?: InputMaybe<Array<CoachingRelationshipStatus>>;
};

export type ApplicantMatchedCoachesArgs = {
  statuses?: InputMaybe<Array<MatchedCoachStatus>>;
};

export type ApplicantUnassignedOrdersArgs = {
  tier?: InputMaybe<SkuTier>;
};

export type ApplicantUpcomingEnrolledSessionsArgs = {
  sessionType?: InputMaybe<CoachingSessionType>;
};

export type ApplicantBatchResponse = {
  __typename?: 'ApplicantBatchResponse';
  count: Scalars['Int']['output'];
  results: Array<Applicant>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ApplicantInput = {
  applicationTiming?: InputMaybe<ApplicationTiming>;
  bio?: InputMaybe<Scalars['String']['input']>;
  blockIntroUntil?: InputMaybe<Scalars['Long']['input']>;
  gpaScores?: InputMaybe<Array<GpaScoreInput>>;
  linkedInTypeformResponseId?: InputMaybe<Scalars['String']['input']>;
  preference?: InputMaybe<ApplicantPreferenceInput>;
  smsOptedOutGroups?: InputMaybe<Array<SmsEnrollmentGroup>>;
  testScores?: InputMaybe<Array<TestScoreInput>>;
  typeformResponseId?: InputMaybe<Scalars['String']['input']>;
};

export type ApplicantPreference = {
  __typename?: 'ApplicantPreference';
  categories?: Maybe<Array<Category>>;
  categoriesFilter?: Maybe<Array<Scalars['CategoryUrn']['output']>>;
  coachingFocusList?: Maybe<Array<CoachingFocus>>;
  coachingFocusListFilter?: Maybe<Array<Scalars['CoachingFocusUrn']['output']>>;
  goalsFilter?: Maybe<Array<GoalName>>;
  hasInternationalServiceFilter?: Maybe<Scalars['Boolean']['output']>;
  hasPackageFilter?: Maybe<Scalars['Boolean']['output']>;
  hourlyRateFilter?: Maybe<IntRange>;
  offeringsFilter?: Maybe<Array<Maybe<Offering>>>;
  schoolsFilter?: Maybe<Array<School>>;
  subCategoryListFilter?: Maybe<Array<SubCategory>>;
};

export type ApplicantPreferenceInput = {
  categoriesFilter?: InputMaybe<Array<Scalars['CategoryUrn']['input']>>;
  coachingFocusListFilter?: InputMaybe<
    Array<Scalars['CoachingFocusUrn']['input']>
  >;
  goalsFilter?: InputMaybe<Array<GoalName>>;
  hasInternationalServiceFilter?: InputMaybe<Scalars['Boolean']['input']>;
  hasPackageFilter?: InputMaybe<Scalars['Boolean']['input']>;
  hourlyRateFilter?: InputMaybe<IntRangeInput>;
  offeringsFilter?: InputMaybe<Array<InputMaybe<Offering>>>;
  schoolsFilter?: InputMaybe<Array<Scalars['SchoolUrn']['input']>>;
  subCategoryListFilter?: InputMaybe<Array<Scalars['SubCategoryUrn']['input']>>;
};

export type ApplicantQuestionnaire = {
  __typename?: 'ApplicantQuestionnaire';
  allOtherChoice?: Maybe<Scalars['String']['output']>;
  budget?: Maybe<Scalars['String']['output']>;
  keyFactor?: Maybe<Scalars['String']['output']>;
  personalBackground?: Maybe<Scalars['String']['output']>;
  professionalBackground?: Maybe<Scalars['String']['output']>;
  topChoice?: Maybe<Scalars['String']['output']>;
};

export type ApplicantQuestionnaireInput = {
  allOtherChoice?: InputMaybe<Scalars['String']['input']>;
  budget?: InputMaybe<Scalars['String']['input']>;
  keyFactor?: InputMaybe<Scalars['String']['input']>;
  personalBackground?: InputMaybe<Scalars['String']['input']>;
  professionalBackground?: InputMaybe<Scalars['String']['input']>;
  topChoice?: InputMaybe<Scalars['String']['input']>;
};

export enum ApplicationTiming {
  OTHER = 'OTHER',
  /** @deprecated No longer supported */
  ROUND1_FALL_2021 = 'ROUND1_FALL_2021',
  ROUND1_FALL_2022 = 'ROUND1_FALL_2022',
  ROUND1_FALL_2023 = 'ROUND1_FALL_2023',
  ROUND1_FALL_2024 = 'ROUND1_FALL_2024',
  ROUND2_WINTER_2021 = 'ROUND2_WINTER_2021',
  ROUND2_WINTER_2022 = 'ROUND2_WINTER_2022',
  ROUND2_WINTER_2023 = 'ROUND2_WINTER_2023',
  ROUND2_WINTER_2024 = 'ROUND2_WINTER_2024',
  ROUND3_SPRING_2022 = 'ROUND3_SPRING_2022',
  ROUND3_SPRING_2023 = 'ROUND3_SPRING_2023',
  ROUND3_SPRING_2024 = 'ROUND3_SPRING_2024',
  ROUND3_SPRING_2025 = 'ROUND3_SPRING_2025',
}

export type ArticleSummary = {
  __typename?: 'ArticleSummary';
  gmvFromSignUps: Scalars['Long']['output'];
  name?: Maybe<Scalars['String']['output']>;
  numberOfEmails: Scalars['Int']['output'];
  numberOfOrdersFromSignUps: Scalars['Int']['output'];
  numberOfSignUps: Scalars['Int']['output'];
};

export enum AuthTarget {
  APPLICANT = 'APPLICANT',
  COACH = 'COACH',
}

export type CalendarConnection = {
  __typename?: 'CalendarConnection';
  calendarId: Scalars['String']['output'];
  calendarType: CalendarType;
  connected: Scalars['Boolean']['output'];
  connectedCalendars?: Maybe<Array<ConnectedCalendar>>;
  sendEmailUpdates?: Maybe<Scalars['Boolean']['output']>;
};

export enum CalendarType {
  GOOGLE = 'GOOGLE',
  OUTLOOK = 'OUTLOOK',
}

export enum CancelReason {
  /**  The order was not accepted by the coach before the hold expired */
  EXPIRED = 'EXPIRED',
  /** The order was requested to be canceled by the customer, before the coach accepts it */
  REQUESTED_BY_CUSTOMER = 'REQUESTED_BY_CUSTOMER',
}

export enum CardBrand {
  AMEX = 'AMEX',
  DINERS = 'DINERS',
  DISCOVER = 'DISCOVER',
  JCB = 'JCB',
  MASTERCARD = 'MASTERCARD',
  UNION_PAY = 'UNION_PAY',
  UNKNOWN = 'UNKNOWN',
  VISA = 'VISA',
}

export enum CardFundingType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  PREPAID = 'PREPAID',
  UNKNOWN = 'UNKNOWN',
}

export type CareerCategoryFeaturedSection = {
  __typename?: 'CareerCategoryFeaturedSection';
  companies?: Maybe<Array<Company>>;
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum CareerGoalTarget {
  CHANGE_COMPANIES = 'CHANGE_COMPANIES',
  FULL_TIME_JOB = 'FULL_TIME_JOB',
  INTERNSHIP = 'INTERNSHIP',
  LEVEL_UP = 'LEVEL_UP',
  OTHER = 'OTHER',
  PIVOT = 'PIVOT',
}

/**  Aggregate across all career categories */
export type CareerOutcomeSummary = {
  __typename?: 'CareerOutcomeSummary';
  companies: Array<Company>;
  number: Scalars['Int']['output'];
};

export type Category = {
  __typename?: 'Category';
  averageReviewRate: Scalars['Float']['output'];
  /**  AKA services */
  coachingFocusList?: Maybe<Array<CoachingFocus>>;
  comprehensiveFocusList?: Maybe<Array<CoachingFocus>>;
  ctas?: Maybe<Array<CategoryCta>>;
  department: Department;
  faqs?: Maybe<Array<Faq>>;
  featuredFocusList?: Maybe<Array<CoachingFocus>>;
  featuredSection?: Maybe<FeaturedSectionUnion>;
  goal: GoalName;
  hasActiveCoach: Scalars['Boolean']['output'];
  id: Scalars['CategoryUrn']['output'];
  isFeatured: Scalars['Boolean']['output'];
  mediaLink?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  offerings?: Maybe<Array<Offering>>;
  outcomes?: Maybe<Array<OutcomeSummaryUnion>>;
  reviews: ReviewBatchResponse;
  /**  If FE should display sub categories in nav or not */
  shouldShowSubCategories?: Maybe<Scalars['Boolean']['output']>;
  slug: Scalars['String']['output'];
  /**  Higher sortRank values get sorted higher. This rank gets applied after GOAL and before all other sorting actions */
  sortRank?: Maybe<Scalars['Int']['output']>;
  subCategoryList?: Maybe<Array<SubCategory>>;
  subCategoryTitle?: Maybe<Scalars['String']['output']>;
};

export type CategoryAverageReviewRateArgs = {
  packageOnly?: Scalars['Boolean']['input'];
};

export type CategoryOutcomesArgs = {
  packageOnly?: Scalars['Boolean']['input'];
};

export type CategoryReviewsArgs = {
  count?: Scalars['Int']['input'];
  packageOnly?: Scalars['Boolean']['input'];
  start?: Scalars['Int']['input'];
};

export type CategoryBatchResponse = {
  __typename?: 'CategoryBatchResponse';
  count: Scalars['Int']['output'];
  results: Array<Category>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CategoryCta = {
  __typename?: 'CategoryCTA';
  cta: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type CategoryCtaInput = {
  cta: Scalars['String']['input'];
  description: Scalars['String']['input'];
};

export type CategoryFeaturedSectionInput = {
  companyUrns?: InputMaybe<Array<Scalars['CompanyUrn']['input']>>;
  description: Scalars['String']['input'];
  schoolUrns?: InputMaybe<Array<Scalars['SchoolUrn']['input']>>;
  title: Scalars['String']['input'];
};

export type CategoryInformation = {
  __typename?: 'CategoryInformation';
  canDeleteCategory: Scalars['Boolean']['output'];
  category: Category;
  coach: Coach;
  coachingFocusList?: Maybe<Array<CoachingFocus>>;
  customersCoached?: Maybe<Scalars['Int']['output']>;
  displayTestScore?: Maybe<Scalars['Boolean']['output']>;
  experienceLevel?: Maybe<Scalars['Int']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  id: Scalars['CoachingCategoryUrn']['output'];
  introVideo?: Maybe<VideoLink>;
  isCategoryComplete?: Maybe<Scalars['Boolean']['output']>;
  isHidden: Scalars['Boolean']['output'];
  offerings?: Maybe<Array<Offering>>;
  qualifications?: Maybe<Scalars['String']['output']>;
  subCategoryList?: Maybe<Array<SubCategory>>;
  testScore?: Maybe<Scalars['Int']['output']>;
};

export type CategoryInformationInput = {
  categoryUrn: Scalars['CategoryUrn']['input'];
  coachingFocusUrns?: InputMaybe<Array<Scalars['CoachingFocusUrn']['input']>>;
  customersCoached?: InputMaybe<Scalars['Int']['input']>;
  displayTestScore?: InputMaybe<Scalars['Boolean']['input']>;
  experienceLevel?: InputMaybe<Scalars['Int']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  introVideo?: InputMaybe<VideoLinkInput>;
  offerings?: InputMaybe<Array<Offering>>;
  qualifications?: InputMaybe<Scalars['String']['input']>;
  subCategoryUrns?: InputMaybe<Array<Scalars['SubCategoryUrn']['input']>>;
  testScore?: InputMaybe<Scalars['Int']['input']>;
};

export type CategoryInput = {
  /**  AKA services */
  coachingFocusUrns?: InputMaybe<Array<Scalars['CoachingFocusUrn']['input']>>;
  comprehensiveFocusUrns?: InputMaybe<
    Array<Scalars['CoachingFocusUrn']['input']>
  >;
  ctas?: InputMaybe<Array<CategoryCtaInput>>;
  departmentUrn: Scalars['DepartmentUrn']['input'];
  faqs?: InputMaybe<Array<FaqInput>>;
  featuredFocusUrns?: InputMaybe<Array<Scalars['CoachingFocusUrn']['input']>>;
  featuredSection?: InputMaybe<CategoryFeaturedSectionInput>;
  goal: GoalName;
  isFeatured: Scalars['Boolean']['input'];
  /**  Name should be a human-friendly alphanumeric name, whitespace is allowed */
  name: Scalars['String']['input'];
  shouldShowSubCategories?: Scalars['Boolean']['input'];
  /**  Higher sortRank values get sorted higher */
  sortRank?: InputMaybe<Scalars['Int']['input']>;
  subCategoryTitle?: InputMaybe<Scalars['String']['input']>;
  subCategoryUrns?: InputMaybe<Array<Scalars['SubCategoryUrn']['input']>>;
};

/**  Only allows changing offerings and featured flag on update, as other changes could mess with how we store and get categories */
export type CategoryPatchInput = {
  /**  AKA services */
  coachingFocusUrns?: InputMaybe<Array<Scalars['CoachingFocusUrn']['input']>>;
  comprehensiveFocusUrns?: InputMaybe<
    Array<Scalars['CoachingFocusUrn']['input']>
  >;
  ctas?: InputMaybe<Array<CategoryCtaInput>>;
  departmentUrn?: InputMaybe<Scalars['DepartmentUrn']['input']>;
  faqs?: InputMaybe<Array<FaqInput>>;
  featuredFocusUrns?: InputMaybe<Array<Scalars['CoachingFocusUrn']['input']>>;
  featuredSection?: InputMaybe<CategoryFeaturedSectionInput>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  offerings?: InputMaybe<Array<Offering>>;
  shouldShowSubCategories?: InputMaybe<Scalars['Boolean']['input']>;
  sortRank?: InputMaybe<Scalars['Int']['input']>;
  subCategoryTitle?: InputMaybe<Scalars['String']['input']>;
  subCategoryUrns?: InputMaybe<Array<Scalars['SubCategoryUrn']['input']>>;
};

export type CategoryReview = {
  __typename?: 'CategoryReview';
  category: Category;
  coachingFocusList?: Maybe<Array<CoachingFocus>>;
  knowledge?: Maybe<Scalars['Int']['output']>;
  /**  contains test name */
  offerings?: Maybe<Array<Offering>>;
  outcomes?: Maybe<Array<CoachingOutcomeUnion>>;
  responsiveness?: Maybe<Scalars['Int']['output']>;
  /**  School: MBA fields */
  subCategoryList?: Maybe<Array<SubCategory>>;
  supportiveness?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type CategoryReviewInput = {
  categoryUrn: Scalars['CategoryUrn']['input'];
  coachingFocusUrns?: InputMaybe<Array<Scalars['CoachingFocusUrn']['input']>>;
  knowledge?: InputMaybe<Scalars['Int']['input']>;
  offerings?: InputMaybe<Array<Offering>>;
  responsiveness?: InputMaybe<Scalars['Int']['input']>;
  /**  MBA fields */
  subCategoryUrns?: InputMaybe<Array<Scalars['SubCategoryUrn']['input']>>;
  supportiveness?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type CategoryReviewVouchInput = {
  categoryUrn: Scalars['CategoryUrn']['input'];
  /**  Career fields */
  companyUrn?: InputMaybe<Scalars['CompanyUrn']['input']>;
  jobTitleUrn?: InputMaybe<Scalars['JobTitleUrn']['input']>;
  /**  School fields */
  schoolAdmissionInfoList?: InputMaybe<Array<SchoolAdmissionInfoInput>>;
  /**  Test fields */
  score?: InputMaybe<Scalars['Float']['input']>;
  /**  MBA fields */
  subCategoryUrns?: InputMaybe<Array<Scalars['SubCategoryUrn']['input']>>;
};

export type CategorySelection = {
  __typename?: 'CategorySelection';
  category: Category;
  subCategories?: Maybe<Array<SubCategory>>;
};

export type Class = {
  __typename?: 'Class';
  allSessionsV2?: Maybe<Array<CoachingSession>>;
  averageReviewRate?: Maybe<Scalars['Float']['output']>;
  category?: Maybe<Category>;
  coach?: Maybe<Coach>;
  coachingFocusList?: Maybe<Array<CoachingFocus>>;
  /**  Date this class was moved to discovery status */
  dateMovedToDiscovery?: Maybe<Scalars['Long']['output']>;
  /**  Longer text description */
  description?: Maybe<Scalars['String']['output']>;
  /**  OPS only, the number on the list (discoveryListSize) returned to coach */
  discoveryList?: Maybe<Array<UserOrGuest>>;
  /**  fetchFromPublishedClass only needs to be set if the query/mutation returns the draft, not published version */
  discoveryListSize?: Maybe<Scalars['Int']['output']>;
  /**  Time in minutes */
  durationInMinute?: Maybe<Scalars['Int']['output']>;
  entityReview?: Maybe<EntityReview>;
  /**  Feedback from ops to coach. Only available on drafts */
  feedback?: Maybe<Scalars['String']['output']>;
  /**  First proposed session time for the draft class. Meant for free event. */
  firstStartAt?: Maybe<Scalars['Long']['output']>;
  id: Scalars['ClassUrn']['output'];
  imageLink?: Maybe<Scalars['String']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isFreeEvent?: Maybe<Scalars['Boolean']['output']>;
  isUserOnDiscoveryList?: Maybe<Scalars['Boolean']['output']>;
  /**  Highlight deliverables and output from the class */
  keyTakeaways?: Maybe<Array<Scalars['String']['output']>>;
  lastModified?: Maybe<Scalars['Long']['output']>;
  maximumAttendees?: Maybe<Scalars['Int']['output']>;
  mediaUrn?: Maybe<Scalars['MediaUrn']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  offerings?: Maybe<Array<Offering>>;
  /** @deprecated No longer supported */
  pastSessions?: Maybe<Scalars['Int']['output']>;
  pastSessionsV2?: Maybe<CoachingSessionBatchResponse>;
  /**  Price in cents */
  price?: Maybe<Scalars['Int']['output']>;
  /**  Null unless this class has a published version is true */
  publishedVersionStatus?: Maybe<ClassStatus>;
  reviewTotal: Scalars['Int']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ClassStatus>;
  subCategories?: Maybe<Array<SubCategory>>;
  /**  Short explanation of who this class is designed for */
  targetAudience?: Maybe<Scalars['String']['output']>;
  totalAttendees?: Maybe<Scalars['Int']['output']>;
  totalEarned?: Maybe<Scalars['Int']['output']>;
  totalNumberOfSessions?: Maybe<Scalars['Int']['output']>;
  upcomingSessionsV2?: Maybe<Array<CoachingSession>>;
  userEnrolledInSessionsV2?: Maybe<Array<CoachingSession>>;
};

export type ClassUpcomingSessionsV2Args = {
  ignoreFullSessions?: Scalars['Boolean']['input'];
};

export type ClassAndShindig = Class | Shindig;

export type ClassBatchResponse = {
  __typename?: 'ClassBatchResponse';
  count: Scalars['Int']['output'];
  results: Array<Class>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ClassInput = {
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  coachingFocusUrns?: InputMaybe<Array<Scalars['CoachingFocusUrn']['input']>>;
  /**  Longer text description */
  description?: InputMaybe<Scalars['String']['input']>;
  durationInMinute?: InputMaybe<Scalars['Int']['input']>;
  /**  Auto create first class session on approval */
  firstStartAt?: InputMaybe<Scalars['Long']['input']>;
  isFreeEvent?: InputMaybe<Scalars['Boolean']['input']>;
  /**  Highlight deliverables and output from the class */
  keyTakeaways?: InputMaybe<Array<Scalars['String']['input']>>;
  maximumAttendees?: InputMaybe<Scalars['Int']['input']>;
  mediaUrn?: InputMaybe<Scalars['MediaUrn']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offerings?: InputMaybe<Array<Offering>>;
  /**  Price in cents */
  price?: InputMaybe<Scalars['Int']['input']>;
  subCategoryUrns?: InputMaybe<Array<Scalars['SubCategoryUrn']['input']>>;
  /**  One sentence summary: character limit of? */
  summary?: InputMaybe<Scalars['String']['input']>;
  /**  Short explanation of who this class is designed for */
  targetAudience?: InputMaybe<Scalars['String']['input']>;
};

export type ClassOrder = {
  __typename?: 'ClassOrder';
  coachingSession: CoachingSession;
};

export type ClassOrderInput = {
  classSessionUrn: Scalars['CoachingSessionUrn']['input'];
};

export type ClassShindigBatchResponse = {
  __typename?: 'ClassShindigBatchResponse';
  end?: Maybe<Scalars['Long']['output']>;
  results: Array<ClassAndShindig>;
  start: Scalars['Long']['output'];
  total: Scalars['Int']['output'];
};

export enum ClassSortBy {
  DATE = 'DATE',
  PRICE = 'PRICE',
}

export enum ClassStatus {
  APPROVED = 'APPROVED',
  DISCOVERY = 'DISCOVERY',
  DRAFT = 'DRAFT',
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
}

export type Coach = {
  __typename?: 'Coach';
  allowPaidClass?: Maybe<Scalars['Boolean']['output']>;
  /**  Cutoff date to allow vouches for coach. If not present or in the past, will use default logic. */
  allowVouchesUntil?: Maybe<Scalars['Long']['output']>;
  approvedAt?: Maybe<Scalars['Long']['output']>;
  availability?: Maybe<CoachAvailability>;
  /**  average first response latency for the past 90 days in float hours */
  averageFirstResponseLatency?: Maybe<Scalars['Float']['output']>;
  averageKnowledgeReviewRate: Scalars['Float']['output'];
  averageResponsivenessReviewRate: Scalars['Float']['output'];
  averageReviewRate: Scalars['Float']['output'];
  averageSupportivenessReviewRate: Scalars['Float']['output'];
  averageValueReviewRate: Scalars['Float']['output'];
  bio?: Maybe<Scalars['String']['output']>;
  calendarLink?: Maybe<Scalars['String']['output']>;
  categoryInformation?: Maybe<CategoryInformation>;
  categoryInformationList?: Maybe<Array<CategoryInformation>>;
  coachingRelationship?: Maybe<CoachingRelationship>;
  coachingTimeSummary?: Maybe<CoachingTimeSummary>;
  companiesAdmitted?: Maybe<Array<Company>>;
  couldScheduleIntro?: Maybe<Scalars['Boolean']['output']>;
  /**  epoch in milli-second */
  createdAt: Scalars['Long']['output'];
  /**  Reports customers coached in active categories */
  customersCoached: Scalars['Int']['output'];
  /**
   *  Desired coach hourly payout in cents. This defaults to min(coach hourly rate * 0.8, tier hourly payout) if not set
   * @deprecated No longer supported
   */
  desiredPayout?: Maybe<Scalars['Int']['output']>;
  educationExperiences?: Maybe<Array<Education>>;
  geoLocation?: Maybe<GeoLocation>;
  groupClasses?: Maybe<Array<Class>>;
  /**  For the customer, when there are package(s) purchased and not marked as completed */
  hasPackageInProgress?: Maybe<Scalars['Boolean']['output']>;
  hasPendingIntroMessage?: Maybe<Scalars['Boolean']['output']>;
  hasPendingIntroRequest?: Maybe<Scalars['Boolean']['output']>;
  hasPendingOrder?: Maybe<Scalars['Boolean']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  /**  number in cent */
  hourlyRate?: Maybe<Scalars['Int']['output']>;
  hourlyTerm?: Maybe<PackageTerm>;
  id: Scalars['CoachUrn']['output'];
  introCallResponseTemplate?: Maybe<Scalars['String']['output']>;
  introVideo?: Maybe<VideoLink>;
  lelandPlusContributor: Scalars['Boolean']['output'];
  liveAt?: Maybe<Scalars['Long']['output']>;
  matchedCoach?: Maybe<MatchedCoach>;
  /**  Most recent paid order, if present */
  mostRecentOrder?: Maybe<Order>;
  myVouch?: Maybe<Review>;
  numFeaturedReviews?: Maybe<Scalars['Int']['output']>;
  /**  The field would be null if the viewer is not coach self, or not ops */
  onboardingSessionAttended?: Maybe<Scalars['Boolean']['output']>;
  openToInternational: Scalars['Boolean']['output'];
  /** @deprecated No longer supported */
  openToLowerThanDesiredPayout?: Maybe<Scalars['Boolean']['output']>;
  opsNote?: Maybe<Scalars['String']['output']>;
  optInSmartPricing?: Maybe<Scalars['Boolean']['output']>;
  optInTrialSessions?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated No longer supported */
  optOutTrialSessions?: Maybe<Scalars['Boolean']['output']>;
  /**  Coaching outcome summaries */
  outcomes?: Maybe<Array<OutcomeSummaryUnion>>;
  packages?: Maybe<Array<OfferingPackage>>;
  payoutInformation?: Maybe<CoachPayoutInformation>;
  professionalCoach: Scalars['Boolean']['output'];
  professionalExperiences?: Maybe<Array<ProfessionalExperience>>;
  rankingAdjustment?: Maybe<RankingAdjustment>;
  reviewTotal: Scalars['Int']['output'];
  /**  Used for static builds of coach page */
  reviews: ReviewBatchResponse;
  schoolAdmissionInfoList?: Maybe<Array<SchoolAdmissionInfo>>;
  searchPackages: PackageSearchResponse;
  slug: Scalars['String']['output'];
  smsEnrollments: Array<SmsEnrollment>;
  specialBackgrounds?: Maybe<Array<SpecialBackground>>;
  status: CoachStatus;
  stripeActivated?: Maybe<Scalars['Boolean']['output']>;
  submittedAt?: Maybe<Scalars['Long']['output']>;
  tier: SkuTier;
  /**
   *  Life time coached between the current log-in applicant and this coach
   * @deprecated No longer supported
   */
  totalCoachingTimeInMinute?: Maybe<Scalars['Int']['output']>;
  /**  For new experience, it is always the credit time the current log-in applicant has. For old experience, it is the time the current log-in applicant has purchased for this coach */
  totalPurchasedTimeInMinute?: Maybe<Scalars['Int']['output']>;
  /**  When null, it means there's no trial hour coupon available */
  trialHourCoupon?: Maybe<Coupon>;
  upcomingEnrolledSessions?: Maybe<Array<CoachingSession>>;
  user: User;
  visibility: CoachVisibility;
  whyICoach?: Maybe<Scalars['String']['output']>;
  yearOfExperience?: Maybe<Scalars['Int']['output']>;
};

export type CoachCategoryInformationArgs = {
  categoryUrn: Scalars['CategoryUrn']['input'];
};

export type CoachCategoryInformationListArgs = {
  showHidden?: Scalars['Boolean']['input'];
};

export type CoachGroupClassesArgs = {
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
};

export type CoachIntroVideoArgs = {
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
};

export type CoachPackagesArgs = {
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  hidePrivate?: Scalars['Boolean']['input'];
  showNewExperiencePackagesToCoach?: Scalars['Boolean']['input'];
  tier?: InputMaybe<SkuTier>;
};

export type CoachReviewsArgs = {
  categoryUrnFilter?: InputMaybe<Scalars['CategoryUrn']['input']>;
  categoryUrnSort?: InputMaybe<Scalars['CategoryUrn']['input']>;
  count?: Scalars['Int']['input'];
  sortBy?: ReviewSortBy;
  sortOrder?: SortOrder;
  start?: Scalars['Int']['input'];
  type?: InputMaybe<ReviewType>;
};

export type CoachSearchPackagesArgs = {
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  coachingFocusListFilter?: InputMaybe<
    Array<Scalars['CoachingFocusUrn']['input']>
  >;
  companiesFilter?: InputMaybe<Array<Scalars['CompanyUrn']['input']>>;
  companiesSort?: InputMaybe<Array<Scalars['CompanyUrn']['input']>>;
  count?: Scalars['Int']['input'];
  idsToExclude?: InputMaybe<Array<Scalars['PackageUrn']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  offeringsFilter?: InputMaybe<Array<Offering>>;
  priceFilter?: InputMaybe<IntRangeInput>;
  schoolsFilter?: InputMaybe<Array<Scalars['SchoolUrn']['input']>>;
  schoolsSort?: InputMaybe<Array<Scalars['SchoolUrn']['input']>>;
  sortBy?: InputMaybe<CoachSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  start?: Scalars['Int']['input'];
  subCategoriesFilter?: InputMaybe<Array<Scalars['SubCategoryUrn']['input']>>;
};

export type CoachUpcomingEnrolledSessionsArgs = {
  sessionType?: InputMaybe<CoachingSessionType>;
};

export type CoachAvailability = {
  __typename?: 'CoachAvailability';
  betweenMeetingBufferInMinute?: Maybe<Scalars['Int']['output']>;
  /**  Range of days, INCLUSIVE of ending day */
  blackoutDateRanges?: Maybe<Array<LocalDateRange>>;
  blackoutDates?: Maybe<Array<Scalars['Date']['output']>>;
  /**  Will initially likely only contain a single value, but could grow to multiple */
  calendarConnections?: Maybe<Array<CalendarConnection>>;
  capacity?: Maybe<CoachCapacity>;
  defaultAvailability?: Maybe<DefaultAvailability>;
  detail?: Maybe<Scalars['String']['output']>;
  hourRangePerWeek?: Maybe<IntRange>;
  /**  Returns next available 30 minute window inside of a coach's availability (if possible), otherwise null */
  nextAvailableAt?: Maybe<Scalars['Long']['output']>;
  scheduleBufferInMinute?: Maybe<Scalars['Int']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
};

export type CoachAvailabilityInput = {
  betweenMeetingBufferInMinute?: InputMaybe<Scalars['Int']['input']>;
  blackoutDateRanges?: InputMaybe<Array<LocalDateRangeInput>>;
  capacity?: InputMaybe<CoachCapacity>;
  defaultAvailability?: InputMaybe<DefaultAvailabilityInput>;
  detail?: InputMaybe<Scalars['String']['input']>;
  hourRangePerWeek?: InputMaybe<IntRangeInput>;
  scheduleBufferInMinute?: InputMaybe<Scalars['Int']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type CoachBatchResponse = {
  __typename?: 'CoachBatchResponse';
  count: Scalars['Int']['output'];
  results: Array<Coach>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum CoachCapacity {
  NO_NEW = 'NO_NEW',
  OPEN_LIST = 'OPEN_LIST',
  OPEN_PRIVATE = 'OPEN_PRIVATE',
}

export type CoachInput = {
  availability?: InputMaybe<CoachAvailabilityInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  calendarLink?: InputMaybe<Scalars['String']['input']>;
  /**  the order for the coaching categories. If not set, it would by default use the creation order */
  categoryInfoOrder?: InputMaybe<Array<Scalars['CategoryUrn']['input']>>;
  companiesAdmitted?: InputMaybe<Array<Scalars['CompanyUrn']['input']>>;
  desiredPayout?: InputMaybe<Scalars['Int']['input']>;
  educationExperiences?: InputMaybe<Array<EducationInput>>;
  geoLocation?: InputMaybe<GeoLocationInput>;
  headline?: InputMaybe<Scalars['String']['input']>;
  /**  number in cent */
  hourlyRate?: InputMaybe<Scalars['Int']['input']>;
  hourlyTerm?: InputMaybe<PackageTermInput>;
  introCallResponseTemplate?: InputMaybe<Scalars['String']['input']>;
  introVideo?: InputMaybe<VideoLinkInput>;
  minDesiredPayout?: InputMaybe<Scalars['Int']['input']>;
  onboardingSessionAttended?: InputMaybe<Scalars['Boolean']['input']>;
  openToInternational?: InputMaybe<Scalars['Boolean']['input']>;
  openToLowerThanDesiredPayout?: InputMaybe<Scalars['Boolean']['input']>;
  opsNote?: InputMaybe<Scalars['String']['input']>;
  optInSmartPricing?: InputMaybe<Scalars['Boolean']['input']>;
  optInTrialSessions?: InputMaybe<Scalars['Boolean']['input']>;
  optOutTrialSessions?: InputMaybe<Scalars['Boolean']['input']>;
  professionalCoach?: InputMaybe<Scalars['Boolean']['input']>;
  professionalExperiences?: InputMaybe<Array<ProfessionalExperiencesInput>>;
  rankingAdjustment?: InputMaybe<RankingAdjustmentInput>;
  schoolAdmissionInfoList?: InputMaybe<Array<SchoolAdmissionInfoInput>>;
  smsOptedOutGroups?: InputMaybe<Array<SmsEnrollmentGroup>>;
  specialBackgrounds?: InputMaybe<Array<SpecialBackground>>;
  tier?: InputMaybe<SkuTier>;
  visibility?: InputMaybe<CoachVisibility>;
  whyICoach?: InputMaybe<Scalars['String']['input']>;
  yearOfExperience?: InputMaybe<Scalars['Int']['input']>;
};

export type CoachPayoutInformation = {
  __typename?: 'CoachPayoutInformation';
  /**  Leland enforced payout = min(desired, tier hourly payout) */
  enforced: Scalars['Long']['output'];
  /**  Coach's desired hourly payout */
  minDesired: Scalars['Long']['output'];
  /**  post-trial hourly payout = min(enforced, post-trial hourly payout) */
  postTrial: Scalars['Long']['output'];
  /**  trial hourly payout = min(enforced, trial hourly payout) */
  trial: Scalars['Long']['output'];
};

export type CoachRecommendation = {
  __typename?: 'CoachRecommendation';
  coach: Coach;
  reason: Scalars['String']['output'];
};

export type CoachSearchResponse = {
  __typename?: 'CoachSearchResponse';
  count: Scalars['Int']['output'];
  metadata: SearchMetadata;
  results: Array<Coach>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum CoachSortBy {
  HOURLY_RATE = 'HOURLY_RATE',
  LAST_MODIFIED_AT = 'LAST_MODIFIED_AT',
  RELEVANCE = 'RELEVANCE',
  REVIEW_NUMBER = 'REVIEW_NUMBER',
  REVIEW_RATE = 'REVIEW_RATE',
}

export enum CoachStatus {
  APPROVED = 'APPROVED',
  /**  The coach profile is de-activated */
  DEACTIVATED = 'DEACTIVATED',
  /**  The coach profile is disabled by ops due to some bad behaviors */
  DISABLED = 'DISABLED',
  /**  Coach is live on the site */
  LIVE = 'LIVE',
  /**  The coach just signed up the account */
  ONBOARDING = 'ONBOARDING',
  /**
   *  Coach paused leads but still an active coach
   * @deprecated No longer supported
   */
  PAUSED = 'PAUSED',
  /**  The coach profile is rejected by ops during application */
  REJECTED = 'REJECTED',
  /**  Coach finished the profile, and submit for review */
  UNDER_REVIEW = 'UNDER_REVIEW',
  WAITLISTED = 'WAITLISTED',
}

export enum CoachVisibility {
  HIDDEN = 'HIDDEN',
  PRIVATE_CLOSED = 'PRIVATE_CLOSED',
  PRIVATE_OPEN = 'PRIVATE_OPEN',
  PUBLIC = 'PUBLIC',
}

export enum CoachingDeliveryFormat {
  ASYNC = 'ASYNC',
  HYBRID = 'HYBRID',
  LIVE = 'LIVE',
}

export type CoachingFocus = {
  __typename?: 'CoachingFocus';
  /**  Future: store the svg and other information for a service here. */
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['CoachingFocusUrn']['output'];
  libraryPath?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type CoachingFocusInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  /**  Expects in the format: /a/article-slug */
  libraryPath?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CoachingOutcome = {
  __typename?: 'CoachingOutcome';
  applicant: Applicant;
  category?: Maybe<Category>;
  coaches: Array<Coach>;
  createdByCurrentUser?: Maybe<Scalars['Boolean']['output']>;
  goal: GoalName;
  id: Scalars['CoachingOutcomeUrn']['output'];
  outcome: CoachingOutcomeUnion;
  subCategories?: Maybe<Array<SubCategory>>;
};

export type CoachingOutcomeForVouchInput = {
  applicantUrn: Scalars['ApplicantUrn']['input'];
  goal: GoalName;
  /**  Career items */
  jobOfferInfo?: InputMaybe<JobOfferInfoInput>;
  /**  School items */
  schoolAdmissionInfo?: InputMaybe<SchoolAdmissionInfoInput>;
  subCategoryUrns?: InputMaybe<Array<Scalars['SubCategoryUrn']['input']>>;
  /**  Test items */
  testScore?: InputMaybe<TestScoreV2Input>;
};

export type CoachingOutcomeInput = {
  applicantUrn: Scalars['ApplicantUrn']['input'];
  categoryUrn: Scalars['CategoryUrn']['input'];
  /**  Career items */
  jobOfferInfo?: InputMaybe<JobOfferInfoInput>;
  /**  School items */
  schoolAdmissionInfo?: InputMaybe<SchoolAdmissionInfoInput>;
  subCategoryUrns?: InputMaybe<Array<Scalars['SubCategoryUrn']['input']>>;
  /**  Test items */
  testScore?: InputMaybe<TestScoreV2Input>;
};

export type CoachingOutcomePatchInput = {
  /**  Career items */
  jobOfferInfo?: InputMaybe<JobOfferInfoInput>;
  /**  School items */
  schoolAdmissionInfo?: InputMaybe<SchoolAdmissionInfoInput>;
  /**  Test items */
  testScore?: InputMaybe<TestScoreV2Input>;
};

export type CoachingOutcomeUnion =
  | JobOfferInfo
  | SchoolAdmissionInfo
  | TestScoreV2;

export type CoachingPackageInput = {
  coachUrn: Scalars['CoachUrn']['input'];
  /**  Whether the coach opt in for this Leland package. Default to true if DB does not have this record */
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  packageUrn: Scalars['PackageUrn']['input'];
  priceOverrides?: InputMaybe<Array<OfferingPackagePricePatchInput>>;
};

export type CoachingRelationship = {
  __typename?: 'CoachingRelationship';
  applicant: Applicant;
  archivedByCoach: Scalars['Boolean']['output'];
  blockedByCoach: Scalars['Boolean']['output'];
  /**  All categories that a customer has interacted with this coach in, from messages/intros, to orders, to new experience matches */
  categories?: Maybe<Array<Category>>;
  coach: Coach;
  coachingOutcomes?: Maybe<Array<CoachingOutcome>>;
  couldScheduleIntro: Scalars['Boolean']['output'];
  /**  epoch in milli-second */
  createdAt: Scalars['Long']['output'];
  customerSubscription?: Maybe<CustomerSubscription>;
  hasPendingIntroMessage: Scalars['Boolean']['output'];
  hasPendingIntroRequest: Scalars['Boolean']['output'];
  hasPendingOrder: Scalars['Boolean']['output'];
  id: Scalars['CoachingRelationshipUrn']['output'];
  lastArchivedByCoachAt?: Maybe<Scalars['Long']['output']>;
  lastBlockedByCoachAt?: Maybe<Scalars['Long']['output']>;
  lastMessagedAt?: Maybe<Scalars['Long']['output']>;
  lastPurchasedAt?: Maybe<Scalars['Long']['output']>;
  lastScheduledAt?: Maybe<Scalars['Long']['output']>;
  lastSortingUpdatedAt?: Maybe<Scalars['Long']['output']>;
  /** @deprecated prefer Coach.matchedCoach or Applicant.matchedCoach */
  matchedCoach?: Maybe<MatchedCoach>;
  note?: Maybe<Scalars['String']['output']>;
  /**  Number of pending intros and orders */
  numberOfPendingRequests: Scalars['Int']['output'];
  opportunitySource?: Maybe<MatchedCoach>;
  /** @deprecated prefer CoachingRelationship.coachingOutcomes */
  outcomes?: Maybe<Array<CoachingOutcomeUnion>>;
  paidTimeInMinute?: Maybe<Scalars['Int']['output']>;
  pastEnrolledSessions?: Maybe<Array<CoachingSession>>;
  recommendedOfferings?: Maybe<Array<RecommendedOffering>>;
  review?: Maybe<Review>;
  reviews?: Maybe<Array<Review>>;
  sendBirdChannelUrl: Scalars['String']['output'];
  status: CoachingRelationshipStatus;
  tier?: Maybe<SkuTier>;
  unitPayoutAmount: Scalars['Int']['output'];
  upcomingEnrolledSessions?: Maybe<Array<CoachingSession>>;
};

export type CoachingRelationshipPastEnrolledSessionsArgs = {
  includingCanceled?: Scalars['Boolean']['input'];
  sessionType?: InputMaybe<CoachingSessionType>;
};

export type CoachingRelationshipUpcomingEnrolledSessionsArgs = {
  sessionType?: InputMaybe<CoachingSessionType>;
};

export type CoachingRelationshipBatchResponse = {
  __typename?: 'CoachingRelationshipBatchResponse';
  count: Scalars['Int']['output'];
  results: Array<CoachingRelationship>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum CoachingRelationshipStatus {
  ACTIVE = 'ACTIVE',
  CLASS_ATTENDEE = 'CLASS_ATTENDEE',
  CONFIRMED_LEAD = 'CONFIRMED_LEAD',
  LEAD = 'LEAD',
}

export type CoachingSession = {
  __typename?: 'CoachingSession';
  additionalTimeToBuy: Scalars['Int']['output'];
  attendees?: Maybe<Array<Applicant>>;
  canceled: Scalars['Boolean']['output'];
  coach: Coach;
  coachGhosted?: Maybe<Scalars['Boolean']['output']>;
  content: CoachingSessionContent;
  createdAt: Scalars['Long']['output'];
  customerGhosted?: Maybe<Scalars['Boolean']['output']>;
  durationInMinute: Scalars['Int']['output'];
  endAt: Scalars['Long']['output'];
  hasRecording: Scalars['Boolean']['output'];
  hostMeetingLink?: Maybe<Scalars['String']['output']>;
  id: Scalars['CoachingSessionUrn']['output'];
  maximumAttendees: Scalars['Int']['output'];
  /**  Will automatically return host or attendee link based on the user */
  meetingLink?: Maybe<Scalars['String']['output']>;
  okNotPay?: Maybe<Scalars['Boolean']['output']>;
  previousSchedule?: Maybe<CoachingSessionScheduleLog>;
  scheduledByCoach: Scalars['Boolean']['output'];
  sessionMetrics?: Maybe<Array<Metric>>;
  startAt: Scalars['Long']['output'];
  status: CoachingSessionStatus;
  summary?: Maybe<Scalars['String']['output']>;
  topFacePiles: Array<Scalars['String']['output']>;
  totalAttendees: Scalars['Int']['output'];
  userIsEnrolled?: Maybe<Scalars['Boolean']['output']>;
  viewerMeetingLink?: Maybe<Scalars['String']['output']>;
};

export type CoachingSessionAttendeesArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

export type CoachingSessionTopFacePilesArgs = {
  count?: Scalars['Int']['input'];
};

export type CoachingSessionBatchResponse = {
  __typename?: 'CoachingSessionBatchResponse';
  count: Scalars['Int']['output'];
  results: Array<CoachingSession>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CoachingSessionContent = Class | OneOnOneSession;

export type CoachingSessionEnrollment = {
  __typename?: 'CoachingSessionEnrollment';
  attended: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  enrolledAt?: Maybe<Scalars['Long']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  page?: Maybe<Scalars['String']['output']>;
  utm?: Maybe<Scalars['String']['output']>;
};

export type CoachingSessionInput = {
  attendees?: InputMaybe<Array<Scalars['ApplicantUrn']['input']>>;
  /**  endAt is optional for class session, where the endAt will be pulled from class data */
  endAt?: InputMaybe<Scalars['Long']['input']>;
  groupClassSession?: InputMaybe<GroupClassSessionInput>;
  maximumAttendees?: Scalars['Int']['input'];
  okNotPay?: InputMaybe<Scalars['Boolean']['input']>;
  oneOnOneSession?: InputMaybe<OneOnOneSessionInput>;
  startAt: Scalars['Long']['input'];
};

export type CoachingSessionInputByApplicant = {
  coachUrn: Scalars['CoachUrn']['input'];
  endAt: Scalars['Long']['input'];
  okNotPay?: InputMaybe<Scalars['Boolean']['input']>;
  oneOnOneSession: OneOnOneSessionInput;
  startAt: Scalars['Long']['input'];
};

export type CoachingSessionIssueInput = {
  explanation?: InputMaybe<Scalars['String']['input']>;
  issueType: CoachingSessionIssueType;
};

export enum CoachingSessionIssueType {
  COACH_NO_SHOW = 'COACH_NO_SHOW',
  OTHER = 'OTHER',
  TECHNICAL_ISSUES = 'TECHNICAL_ISSUES',
}

export type CoachingSessionPatchInput = {
  endAt?: InputMaybe<Scalars['Long']['input']>;
  maximumAttendees?: InputMaybe<Scalars['Int']['input']>;
  okNotPay?: InputMaybe<Scalars['Boolean']['input']>;
  oneOnOneSession?: InputMaybe<OneOnOneSessionInput>;
  startAt?: InputMaybe<Scalars['Long']['input']>;
  status?: InputMaybe<CoachingSessionStatus>;
};

export type CoachingSessionScheduleLog = {
  __typename?: 'CoachingSessionScheduleLog';
  endAt: Scalars['Long']['output'];
  scheduledByCoach: Scalars['Boolean']['output'];
  startAt: Scalars['Long']['output'];
};

export enum CoachingSessionStatus {
  CONFIRMED = 'CONFIRMED',
  PROPOSED = 'PROPOSED',
}

export enum CoachingSessionType {
  GROUP_CLASS = 'GROUP_CLASS',
  ONE_ON_ONE = 'ONE_ON_ONE',
}

export type CoachingTimeLog = {
  __typename?: 'CoachingTimeLog';
  activity: Scalars['String']['output'];
  applicant: Applicant;
  coach: Coach;
  coachedAtTime: Scalars['Long']['output'];
  createdAt: Scalars['Long']['output'];
  id: Scalars['CoachingTimeLogUrn']['output'];
  pendingPayout: Scalars['Boolean']['output'];
  timeUsedInMinute: Scalars['Int']['output'];
};

export type CoachingTimeLogBatchResponse = {
  __typename?: 'CoachingTimeLogBatchResponse';
  count: Scalars['Int']['output'];
  results: Array<CoachingTimeLog>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CoachingTimeLogInput = {
  activity: Scalars['String']['input'];
  applicantUrn: Scalars['ApplicantUrn']['input'];
  coachedAtTime: Scalars['Long']['input'];
  timeUsedInMinute: Scalars['Int']['input'];
};

export type CoachingTimeSummary = {
  __typename?: 'CoachingTimeSummary';
  /**  The available time the current login user is seeing. totalPurchasedTime - totalUsedTime - futureScheduledSessionTime for applicant, and totalPurchasedTime - totalUsedTime for coach */
  availableTime: Scalars['Int']['output'];
  /**  whether the customer has ever bought universal credit */
  everBoughtCredit: Scalars['Boolean']['output'];
  /**  whether the customer and the coach have orders in the past */
  everHadOrder: Scalars['Boolean']['output'];
  /**  whether the customer and the coach have ongoing unlimited packages */
  hasUnlimitedPackage: Scalars['Boolean']['output'];
  /**  Life time coached - if for the login counterpart, it means the used time between them */
  usedTime: Scalars['Int']['output'];
  usingOld: Scalars['Boolean']['output'];
};

export type Company = {
  __typename?: 'Company';
  domain?: Maybe<Scalars['String']['output']>;
  /**  Note: if a CompanyUrn was not returned, it means it is not currently being used in our DB */
  id?: Maybe<Scalars['CompanyUrn']['output']>;
  lastModifiedBy?: Maybe<User>;
  logoLink?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type CompanyBatchResponse = {
  __typename?: 'CompanyBatchResponse';
  count: Scalars['Int']['output'];
  results: Array<Company>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CompanyInput = {
  /**  Please supply a domain input if possible, you'll get much better results if you do */
  domain?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type ConnectedCalendar = {
  __typename?: 'ConnectedCalendar';
  calendarId: Scalars['String']['output'];
  connected: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type Coupon = {
  __typename?: 'Coupon';
  appliesTo?: Maybe<Array<Applicant>>;
  /**  Only applicable when creator is null */
  appliesToCoaches?: Maybe<Array<Coach>>;
  appliesToOpsOnly?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  /**  epoch in milli-second */
  createdAt: Scalars['Long']['output'];
  creator?: Maybe<Coach>;
  discountOnLeland?: Maybe<Scalars['Boolean']['output']>;
  discountValue: Scalars['Int']['output'];
  id: Scalars['CouponUrn']['output'];
  /**  Default null means infinite redemption */
  maxRedemption?: Maybe<Scalars['Int']['output']>;
  /**  Default null means infinite redemption per applicant */
  maxRedemptionPerApplicant?: Maybe<Scalars['Int']['output']>;
  minimumSpend: Scalars['Int']['output'];
  numUsed: Scalars['Int']['output'];
  pricingType?: Maybe<CouponPricingType>;
  redeemBy?: Maybe<Scalars['Long']['output']>;
  startOn?: Maybe<Scalars['Long']['output']>;
  type: CouponType;
};

export type CouponBatchResponse = {
  __typename?: 'CouponBatchResponse';
  count: Scalars['Int']['output'];
  results: Array<Coupon>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CouponInput = {
  appliesTo?: InputMaybe<Array<Scalars['ApplicantUrn']['input']>>;
  appliesToEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  appliesToOpsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  discountValue: Scalars['Int']['input'];
  /**  Default null means infinite redemption */
  maxRedemption?: InputMaybe<Scalars['Int']['input']>;
  /**  Default null means infinite redemption per applicant */
  maxRedemptionPerApplicant?: InputMaybe<Scalars['Int']['input']>;
  minimumSpend?: Scalars['Int']['input'];
  pricingType?: InputMaybe<CouponPricingType>;
  redeemBy?: InputMaybe<Scalars['Long']['input']>;
  startOn?: InputMaybe<Scalars['Long']['input']>;
  type: CouponType;
};

export enum CouponPricingType {
  CLASS = 'CLASS',
  HOURLY = 'HOURLY',
  PACKAGE = 'PACKAGE',
}

export enum CouponType {
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  PERCENTAGE_OFF = 'PERCENTAGE_OFF',
}

export type CustomerCareerGoal = {
  __typename?: 'CustomerCareerGoal';
  careerGoalTarget?: Maybe<CareerGoalTarget>;
  targetCompanies?: Maybe<Array<Company>>;
};

export type CustomerCareerGoalInput = {
  careerGoalTarget?: InputMaybe<CareerGoalTarget>;
  targetCompanyUrns?: InputMaybe<Array<Scalars['CompanyUrn']['input']>>;
};

export type CustomerCredit = {
  __typename?: 'CustomerCredit';
  applicant: Applicant;
  /** @deprecated No longer supported */
  availableTime: Scalars['Int']['output'];
  createdAt: Scalars['Long']['output'];
  expireAt?: Maybe<Scalars['Long']['output']>;
  id: Scalars['CustomerCreditUrn']['output'];
  lastModifiedAt: Scalars['Long']['output'];
  moneyAmount: Scalars['Int']['output'];
  source?: Maybe<CustomerCreditSource>;
  /** @deprecated No longer supported */
  timeInMinute?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<CustomerCreditType>;
};

export type CustomerCreditSource = Order | Sku;

export enum CustomerCreditType {
  GIFT_CARD = 'GIFT_CARD',
  PARTNERSHIP = 'PARTNERSHIP',
  REFUND = 'REFUND',
  TRIAL = 'TRIAL',
}

export type CustomerGoal = {
  __typename?: 'CustomerGoal';
  additionalDetail?: Maybe<Scalars['String']['output']>;
  categories?: Maybe<Array<CategorySelection>>;
  completedAt?: Maybe<Scalars['Long']['output']>;
  /**  Category-specific goal info: */
  customerGoal?: Maybe<CustomerGoalUnion>;
  id: Scalars['CustomerGoalUrn']['output'];
  name?: Maybe<Scalars['String']['output']>;
  outcomes?: Maybe<Array<CoachingOutcome>>;
  targetDate?: Maybe<Scalars['Long']['output']>;
};

export type CustomerGoalInput = {
  additionalDetail?: InputMaybe<Scalars['String']['input']>;
  categoryUrns: Array<Scalars['CategoryUrn']['input']>;
  customerCareerGoal?: InputMaybe<CustomerCareerGoalInput>;
  customerSchoolGoal?: InputMaybe<CustomerSchoolGoalInput>;
  customerTestGoal?: InputMaybe<CustomerTestGoalInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  subCategoryUrns?: InputMaybe<Array<Scalars['SubCategoryV2Urn']['input']>>;
  targetDate?: InputMaybe<Scalars['Long']['input']>;
};

export type CustomerGoalUnion =
  | CustomerCareerGoal
  | CustomerSchoolGoal
  | CustomerTestGoal;

export type CustomerSchoolGoal = {
  __typename?: 'CustomerSchoolGoal';
  gpaScores?: Maybe<Array<GpaScore>>;
  targetSchools?: Maybe<Array<School>>;
  testScores?: Maybe<Array<TestScoreV2>>;
};

export type CustomerSchoolGoalInput = {
  gpaScores?: InputMaybe<Array<GpaScoreInput>>;
  targetSchoolUrns?: InputMaybe<Array<Scalars['SchoolUrn']['input']>>;
  testScores?: InputMaybe<Array<TestScoreV2ScoreOptionalInput>>;
};

export type CustomerSubscription = {
  __typename?: 'CustomerSubscription';
  applicant: Applicant;
  coach?: Maybe<Coach>;
  createdAt: Scalars['Long']['output'];
  id: Scalars['CustomerSubscriptionUrn']['output'];
  latestOrder?: Maybe<Order>;
  /**  Shows when a membership should renew without returning the entire subscription object */
  nextCreditGrantAt?: Maybe<Scalars['Long']['output']>;
  nextSku?: Maybe<Sku>;
  pauseStartAt?: Maybe<Scalars['Long']['output']>;
  sku: Sku;
  status: CustomerSubscriptionStatus;
  subscription: StripeSubscription;
};

export enum CustomerSubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  PAUSED = 'PAUSED',
}

export type CustomerTestGoal = {
  __typename?: 'CustomerTestGoal';
  targetScore?: Maybe<Scalars['Float']['output']>;
};

export type CustomerTestGoalInput = {
  targetScore?: InputMaybe<Scalars['Float']['input']>;
};

export type DeclineReason = {
  /**  This comment is sent to Leland for why a coach declined an order */
  comment?: InputMaybe<Scalars['String']['input']>;
  option: DeclineReasonOption;
};

export enum DeclineReasonOption {
  FORGOT_TO_USE_DISCOUNT = 'FORGOT_TO_USE_DISCOUNT',
  /** @deprecated No longer supported */
  INDUSTRY_GEOGRAPHIC_BACKGROUND = 'INDUSTRY_GEOGRAPHIC_BACKGROUND',
  INTRO_CALL_NOT_NEEDED = 'INTRO_CALL_NOT_NEEDED',
  ISSUE_WITH_ORDER = 'ISSUE_WITH_ORDER',
  I_AM_TOO_BUSY = 'I_AM_TOO_BUSY',
  NEEDS_MORE_ATTENTION_THAN_I_CAN_OFFER = 'NEEDS_MORE_ATTENTION_THAN_I_CAN_OFFER',
  /** @deprecated No longer supported */
  NOT_A_COMPETITIVE_APPLICANT = 'NOT_A_COMPETITIVE_APPLICANT',
  OTHER = 'OTHER',
  PERSONALITY_FIT = 'PERSONALITY_FIT',
  PRICE = 'PRICE',
}

export type DefaultAvailability = {
  __typename?: 'DefaultAvailability';
  friday?: Maybe<Array<LocalTimeRange>>;
  monday?: Maybe<Array<LocalTimeRange>>;
  saturday?: Maybe<Array<LocalTimeRange>>;
  sunday?: Maybe<Array<LocalTimeRange>>;
  thursday?: Maybe<Array<LocalTimeRange>>;
  totalInMinute: Scalars['Int']['output'];
  tuesday?: Maybe<Array<LocalTimeRange>>;
  wednesday?: Maybe<Array<LocalTimeRange>>;
};

export type DefaultAvailabilityInput = {
  friday?: InputMaybe<Array<LocalTimeRangeInput>>;
  monday?: InputMaybe<Array<LocalTimeRangeInput>>;
  saturday?: InputMaybe<Array<LocalTimeRangeInput>>;
  sunday?: InputMaybe<Array<LocalTimeRangeInput>>;
  thursday?: InputMaybe<Array<LocalTimeRangeInput>>;
  tuesday?: InputMaybe<Array<LocalTimeRangeInput>>;
  wednesday?: InputMaybe<Array<LocalTimeRangeInput>>;
};

/**  Departments are like sub-goals, with a 1:n relationship to categories */
export type Department = {
  __typename?: 'Department';
  activeCategories?: Maybe<Array<Category>>;
  goal: GoalName;
  id: Scalars['DepartmentUrn']['output'];
  /**  Human-readable name */
  name: Scalars['String']['output'];
  /**  Something to appear in the url bar. Must be unique and alphanumeric camel case */
  slug: Scalars['String']['output'];
  /**  Higher sortRank values get sorted higher in results */
  sortRank?: Maybe<Scalars['Int']['output']>;
};

export type DepartmentInput = {
  goal: GoalName;
  name: Scalars['String']['input'];
  /**  Allowing this to be set on creation, but there will be a default value if not set */
  sortRank?: InputMaybe<Scalars['Int']['input']>;
};

export type EarningMetadata = {
  __typename?: 'EarningMetadata';
  approvedDateFacets?: Maybe<Array<FacetLongRangeContainer>>;
  nextPayoutAt: Scalars['Long']['output'];
  numberOfOrdersToPay: Scalars['Int']['output'];
  payoutDateFacets?: Maybe<Array<FacetLongRangeContainer>>;
  remainingPayout: Scalars['Int']['output'];
  totalPayout: Scalars['Int']['output'];
  upcomingPayout: Scalars['Int']['output'];
};

export type Education = {
  __typename?: 'Education';
  admissionCommittee: Scalars['Boolean']['output'];
  degree?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endAt?: Maybe<MonthYear>;
  isFeatured?: Maybe<Scalars['Boolean']['output']>;
  major?: Maybe<Scalars['String']['output']>;
  school: School;
  startAt?: Maybe<MonthYear>;
};

export type EducationInput = {
  admissionCommittee?: Scalars['Boolean']['input'];
  degree?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endAt?: InputMaybe<MonthYearInput>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  major?: InputMaybe<Scalars['String']['input']>;
  schoolUrn: Scalars['SchoolUrn']['input'];
  startAt?: InputMaybe<MonthYearInput>;
};

export type EducationSuggestion = {
  __typename?: 'EducationSuggestion';
  degree?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endAt?: Maybe<MonthYear>;
  major?: Maybe<Scalars['String']['output']>;
  school?: Maybe<School>;
  startAt?: Maybe<MonthYear>;
};

export type EntityReview = {
  __typename?: 'EntityReview';
  anonymous: Scalars['Boolean']['output'];
  applicant?: Maybe<Applicant>;
  category?: Maybe<Category>;
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['EntityReviewUrn']['output'];
  rate: Scalars['Int']['output'];
  reviewedEvent: Class;
};

export type EntityReviewInput = {
  anonymous: Scalars['Boolean']['input'];
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  eventUrn: Scalars['ClassUrn']['input'];
  rate: Scalars['Int']['input'];
};

export enum Ethnicity {
  AMERICAN_INDIAN_OR_ALASKAN_NATIVE = 'AMERICAN_INDIAN_OR_ALASKAN_NATIVE',
  ASIAN = 'ASIAN',
  BLACK_OR_AFRICAN_AMERICAN = 'BLACK_OR_AFRICAN_AMERICAN',
  HISPANIC_OR_LATINO = 'HISPANIC_OR_LATINO',
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER = 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
  WHITE = 'WHITE',
}

export enum ExperienceType {
  ADMISSIONS_COMMITTEE = 'ADMISSIONS_COMMITTEE',
  HIRING_MANAGER = 'HIRING_MANAGER',
}

export type ExperimentalOrder = {
  __typename?: 'ExperimentalOrder';
  name: Scalars['String']['output'];
};

export type Faq = {
  __typename?: 'FAQ';
  answer: Scalars['String']['output'];
  question: Scalars['String']['output'];
};

export type FaqInput = {
  answer: Scalars['String']['input'];
  question: Scalars['String']['input'];
};

export type FacetCategoryContainer = {
  __typename?: 'FacetCategoryContainer';
  category: Category;
  count: Scalars['Int']['output'];
};

export type FacetIntRangeContainer = {
  __typename?: 'FacetIntRangeContainer';
  number: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
};

export type FacetLongRangeContainer = {
  __typename?: 'FacetLongRangeContainer';
  number: Scalars['Int']['output'];
  start: Scalars['Long']['output'];
};

export type FacetOfferingContainer = {
  __typename?: 'FacetOfferingContainer';
  count: Scalars['Int']['output'];
  offering: Offering;
};

export type FeaturedSectionUnion =
  | CareerCategoryFeaturedSection
  | SchoolCategoryFeaturedSection;

export type FirstContact = {
  __typename?: 'FirstContact';
  campaign?: Maybe<Scalars['String']['output']>;
  gclid?: Maybe<Scalars['String']['output']>;
  medium?: Maybe<Scalars['String']['output']>;
  referrer?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

export type FirstContactInput = {
  campaign?: InputMaybe<Scalars['String']['input']>;
  gclid?: InputMaybe<Scalars['String']['input']>;
  medium?: InputMaybe<Scalars['String']['input']>;
  referrer?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type GpaScore = {
  __typename?: 'GPAScore';
  scale?: Maybe<Scalars['String']['output']>;
  score: Scalars['String']['output'];
};

export type GpaScoreInput = {
  scale?: InputMaybe<Scalars['String']['input']>;
  score: Scalars['String']['input'];
};

export enum Gender {
  MAN = 'MAN',
  NON_BINARY_OR_NON_CONFORMING = 'NON_BINARY_OR_NON_CONFORMING',
  PREFER_NOT_TO_RESPOND = 'PREFER_NOT_TO_RESPOND',
  TRANSGENDER = 'TRANSGENDER',
  WOMAN = 'WOMAN',
}

export type GeoLocation = {
  __typename?: 'GeoLocation';
  address: Scalars['String']['output'];
  country: Scalars['String']['output'];
};

export type GeoLocationInput = {
  rawAddress: Scalars['String']['input'];
};

/**  We can hard code this, new goals will likely require other eng work */
export enum GoalName {
  CAREER = 'CAREER',
  SCHOOL = 'SCHOOL',
  TEST = 'TEST',
}

export type GroupClassSessionInput = {
  classUrn: Scalars['ClassUrn']['input'];
};

export type Guest = {
  __typename?: 'Guest';
  email: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type HourlyOrder = {
  __typename?: 'HourlyOrder';
  sku?: Maybe<Sku>;
  timeInMinute: Scalars['Int']['output'];
};

export type HourlyOrderInput = {
  timeInMinute: Scalars['Int']['input'];
};

export enum IndustryBackground {
  CONSULTING = 'CONSULTING',
  CONSUMER_PRODUCTS__RETAIL__E_COMMERCE = 'CONSUMER_PRODUCTS__RETAIL__E_COMMERCE',
  FINANCIAL_SERVICES = 'FINANCIAL_SERVICES',
  HEALTH_CARE__BIOTECH = 'HEALTH_CARE__BIOTECH',
  MANUFACTURING__INDUSTRIAL__ENERGY = 'MANUFACTURING__INDUSTRIAL__ENERGY',
  MEDIA__ENTERTAINMENT__TRAVEL = 'MEDIA__ENTERTAINMENT__TRAVEL',
  MILITARY = 'MILITARY',
  NONPROFIT__GOVERNMENT__EDUCATION = 'NONPROFIT__GOVERNMENT__EDUCATION',
  OTHER = 'OTHER',
  TECHNOLOGY = 'TECHNOLOGY',
  VC__PE = 'VC__PE',
}

export type Installment = {
  __typename?: 'Installment';
  frequency?: Maybe<TimePeriod>;
  logs?: Maybe<Array<InstallmentLog>>;
  status?: Maybe<InstallmentStatus>;
  stripeInformation?: Maybe<StripeSubscription>;
};

export type InstallmentLog = {
  __typename?: 'InstallmentLog';
  amount: Scalars['Int']['output'];
  paidAt: Scalars['Long']['output'];
};

export enum InstallmentStatus {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
}

export type IntRange = {
  __typename?: 'IntRange';
  /**  Represents the inclusive (less than or equal to) value in which to end the range. This field is optional. An unset field indicates an open range */
  end?: Maybe<Scalars['Int']['output']>;
  /**  Represents the inclusive (greater than or equal to) value in which to start the range. This field is optional. An unset field indicates an open range */
  start?: Maybe<Scalars['Int']['output']>;
};

export type IntRangeInput = {
  /**  Represents the inclusive (less than or equal to) value in which to end the range. This field is optional. An unset field indicates an open range */
  end?: InputMaybe<Scalars['Int']['input']>;
  /**  Represents the inclusive (greater than or equal to) value in which to start the range. This field is optional. An unset field indicates an open range */
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type IntentToSubscribeResponse = {
  __typename?: 'IntentToSubscribeResponse';
  clientSecret: Scalars['String']['output'];
  creditUsed: Scalars['Int']['output'];
  discount: Scalars['Int']['output'];
  subtotal: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type IntroCall = {
  __typename?: 'IntroCall';
  /** @deprecated No longer supported */
  category?: Maybe<Category>;
  interest?: Maybe<IntroCallInterest>;
  interests?: Maybe<Array<IntroCallInterest>>;
  packages?: Maybe<Array<OfferingPackage>>;
};

export enum IntroCallInterest {
  HOURLY = 'HOURLY',
  PACKAGE = 'PACKAGE',
  UNDECIDED = 'UNDECIDED',
}

export type IntroSummary = {
  __typename?: 'IntroSummary';
  /**  Limited to four coach contacts per day, 8 between purchases */
  dailyCoachContactsRemaining: Scalars['Int']['output'];
  introCallsSinceLastPurchase: Scalars['Int']['output'];
  /**  when next coach contact is available, or null if unavailable */
  nextCoachContactAvailableAt?: Maybe<Scalars['Long']['output']>;
};

export type JobOfferInfo = {
  __typename?: 'JobOfferInfo';
  company: Company;
  jobTitle?: Maybe<JobTitle>;
};

export type JobOfferInfoInput = {
  companyUrn: Scalars['CompanyUrn']['input'];
  jobTitleUrn?: InputMaybe<Scalars['JobTitleUrn']['input']>;
};

export type JobTitle = {
  __typename?: 'JobTitle';
  id: Scalars['JobTitleUrn']['output'];
  name: Scalars['String']['output'];
};

export type KeyValuePair = {
  __typename?: 'KeyValuePair';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type LlmChat = {
  __typename?: 'LLMChat';
  message: Scalars['String']['output'];
  role: LlmChatRole;
};

export type LlmChatInput = {
  message: Scalars['String']['input'];
  role: LlmChatRole;
};

export enum LlmChatRole {
  ASSISTANT = 'ASSISTANT',
  SYSTEM = 'SYSTEM',
  USER = 'USER',
}

export type LlmPrompt = {
  __typename?: 'LLMPrompt';
  backupPrompts?: Maybe<Array<LlmChat>>;
  experimentPrompts: Array<LlmChat>;
  experimentUsers?: Maybe<Array<User>>;
  id: LlmPromptName;
  livePrompts?: Maybe<Array<LlmChat>>;
};

export type LlmPromptInput = {
  experimentPrompts?: InputMaybe<Array<LlmChatInput>>;
  experimentUsers?: InputMaybe<Array<Scalars['UserUrn']['input']>>;
};

export enum LlmPromptName {
  COACH_EVENT_DESCRIPTION = 'COACH_EVENT_DESCRIPTION',
  COACH_EVENT_KEY_TAKEAWAYS = 'COACH_EVENT_KEY_TAKEAWAYS',
  COACH_HEADLINE = 'COACH_HEADLINE',
  COACH_QUALIFICATIONS = 'COACH_QUALIFICATIONS',
  COACH_RECOMMENDATION = 'COACH_RECOMMENDATION',
  MEETING_SUMMARY = 'MEETING_SUMMARY',
  PACKAGE_DESCRIPTION = 'PACKAGE_DESCRIPTION',
  PACKAGE_TAKEAWAYS = 'PACKAGE_TAKEAWAYS',
}

export type Ledger = {
  __typename?: 'Ledger';
  paidAt: Scalars['Long']['output'];
  paidTimeInMinute?: Maybe<Scalars['Int']['output']>;
  payoutAmount: Scalars['Int']['output'];
};

export enum LelandErrorCode {
  ACTIVE_SUBSCRIPTION_EXISTS = 'ACTIVE_SUBSCRIPTION_EXISTS',
  CANNOT_APPLY_OUTCOME_TO_COACH = 'CANNOT_APPLY_OUTCOME_TO_COACH',
  CANNOT_DELETE_CATEGORY_CLASS_SCHEDULED = 'CANNOT_DELETE_CATEGORY_CLASS_SCHEDULED',
  /**  Class */
  CANNOT_EDIT_SCHEDULED_CLASS = 'CANNOT_EDIT_SCHEDULED_CLASS',
  /**  Coach Categories */
  CANNOT_HIDE_ALL_CATEGORIES = 'CANNOT_HIDE_ALL_CATEGORIES',
  CANNOT_MUTATE_PAST_SESSION = 'CANNOT_MUTATE_PAST_SESSION',
  CLASS_FULL = 'CLASS_FULL',
  CLASS_SCHEDULE_LIMIT_REACHED = 'CLASS_SCHEDULE_LIMIT_REACHED',
  COACH_NOT_MATCHED = 'COACH_NOT_MATCHED',
  COACH_TRIAL_SESSIONS_DISABLED = 'COACH_TRIAL_SESSIONS_DISABLED',
  COUPON_ALREADY_EXPIRED = 'COUPON_ALREADY_EXPIRED',
  COUPON_CANNOT_APPLY_TO_APPLICANT = 'COUPON_CANNOT_APPLY_TO_APPLICANT',
  COUPON_CANNOT_APPLY_TO_COACH = 'COUPON_CANNOT_APPLY_TO_COACH',
  COUPON_CLASS_ORDER_ONLY = 'COUPON_CLASS_ORDER_ONLY',
  COUPON_CODE_ALREADY_EXISTS = 'COUPON_CODE_ALREADY_EXISTS',
  COUPON_HOURLY_ORDER_ONLY = 'COUPON_HOURLY_ORDER_ONLY',
  COUPON_IS_OPS_ONLY = 'COUPON_IS_OPS_ONLY',
  COUPON_MAX_REDEMPTION_PER_APPLICANT_REACHED = 'COUPON_MAX_REDEMPTION_PER_APPLICANT_REACHED',
  COUPON_MAX_REDEMPTION_REACHED = 'COUPON_MAX_REDEMPTION_REACHED',
  COUPON_MIN_SPEND_NEEDED = 'COUPON_MIN_SPEND_NEEDED',
  COUPON_MUST_START_WITH_SLUG = 'COUPON_MUST_START_WITH_SLUG',
  COUPON_NOT_STARTED = 'COUPON_NOT_STARTED',
  COUPON_PACKAGE_ORDER_ONLY = 'COUPON_PACKAGE_ORDER_ONLY',
  /**  Matching */
  CUSTOMER_COACH_TIER_MISMATCH = 'CUSTOMER_COACH_TIER_MISMATCH',
  EMAIL_IN_USE = 'EMAIL_IN_USE',
  /**  Shindig */
  EVENT_EXISTS_FOR_CATEGORY = 'EVENT_EXISTS_FOR_CATEGORY',
  /**  Auth/Profile */
  FIRST_NAME_MISSING = 'FIRST_NAME_MISSING',
  /**  Common */
  ILLEGAL_RANGE = 'ILLEGAL_RANGE',
  IMAGE_REQUIRED_FOR_PACKAGE = 'IMAGE_REQUIRED_FOR_PACKAGE',
  INSUFFICIENT_CREDIT = 'INSUFFICIENT_CREDIT',
  INVALID_DATE_RANGE = 'INVALID_DATE_RANGE',
  INVALID_GEO_LOCATION = 'INVALID_GEO_LOCATION',
  INVALID_NEXT_STATUS_FOR_PACKAGE = 'INVALID_NEXT_STATUS_FOR_PACKAGE',
  INVALID_ORDER_STATUS_FOR_ASSIGNMENT = 'INVALID_ORDER_STATUS_FOR_ASSIGNMENT',
  /**  Comprehensive package assignment */
  INVALID_ORDER_TYPE_FOR_ASSIGNMENT = 'INVALID_ORDER_TYPE_FOR_ASSIGNMENT',
  INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
  /**  Package */
  INVALID_TARGET_FOR_PACKAGE = 'INVALID_TARGET_FOR_PACKAGE',
  INVALID_VIDEO_LINK = 'INVALID_VIDEO_LINK',
  LAST_NAME_MISSING = 'LAST_NAME_MISSING',
  /**  Coupon Code */
  LOGIN_TO_VIEW_COUPON = 'LOGIN_TO_VIEW_COUPON',
  LUMA_EVENT_NOT_FOUND = 'LUMA_EVENT_NOT_FOUND',
  MAX_COACHING_TIME_LESS_THAN_MIN = 'MAX_COACHING_TIME_LESS_THAN_MIN',
  MISSING_FIELDS_IN_PACKAGE_PRICE = 'MISSING_FIELDS_IN_PACKAGE_PRICE',
  /**  Outcomes */
  NO_COACHES_FOUND_FOR_OUTCOME = 'NO_COACHES_FOUND_FOR_OUTCOME',
  OPPORTUNITY_EXPIRED = 'OPPORTUNITY_EXPIRED',
  ORDER_ALREADY_ASSIGNED = 'ORDER_ALREADY_ASSIGNED',
  PACKAGE_NAME_CONFLICT = 'PACKAGE_NAME_CONFLICT',
  PAYOUT_GREATER_THAN_PRICE = 'PAYOUT_GREATER_THAN_PRICE',
  PRICING_TIER_PRICE_ZERO = 'PRICING_TIER_PRICE_ZERO',
  PRICING_TIER_SKU_TIER_MISMATCH = 'PRICING_TIER_SKU_TIER_MISMATCH',
  PRICING_TIER_UNIQUE_NAME_REQUIRED = 'PRICING_TIER_UNIQUE_NAME_REQUIRED',
  REDEEM_CODE_INVALID = 'REDEEM_CODE_INVALID',
  REDEEM_CODE_REDEEMED = 'REDEEM_CODE_REDEEMED',
  SESSION_INSUFFICIENT_TIME = 'SESSION_INSUFFICIENT_TIME',
  SESSION_INSUFFICIENT_TIME_ON_ORDER = 'SESSION_INSUFFICIENT_TIME_ON_ORDER',
  SESSION_INVALID_START_DATE = 'SESSION_INVALID_START_DATE',
  SESSION_MAX_SCHEDULED_INTROS_REACHED_WITH_COACH = 'SESSION_MAX_SCHEDULED_INTROS_REACHED_WITH_COACH',
  SESSION_MINIMUM_LENGTH = 'SESSION_MINIMUM_LENGTH',
  SESSION_MISSING_END_DATE = 'SESSION_MISSING_END_DATE',
  SESSION_MISSING_TITLE = 'SESSION_MISSING_TITLE',
  SESSION_NOT_DURING_COACH_AVAILABILITY = 'SESSION_NOT_DURING_COACH_AVAILABILITY',
  /**  Coaching Session */
  SESSION_SCHEDULE_BUFFER_NEEDED = 'SESSION_SCHEDULE_BUFFER_NEEDED',
  SESSION_SCHEDULE_CONFLICT = 'SESSION_SCHEDULE_CONFLICT',
  SESSION_TITLE_TOO_LONG = 'SESSION_TITLE_TOO_LONG',
  TARGETS_NOT_AVAILABLE_FOR_PACKAGE_CATEGORY = 'TARGETS_NOT_AVAILABLE_FOR_PACKAGE_CATEGORY',
  /**  Time log */
  TOO_LATE_TO_UPDATE_TIME_LOG = 'TOO_LATE_TO_UPDATE_TIME_LOG',
  /**  Applicant */
  TOO_MANY_COACH_REQUESTS = 'TOO_MANY_COACH_REQUESTS',
  /**  Reviews */
  TOO_MANY_FEATURED_REVIEWS = 'TOO_MANY_FEATURED_REVIEWS',
  TOO_MANY_INTROS_SINCE_LAST_PURCHASE = 'TOO_MANY_INTROS_SINCE_LAST_PURCHASE',
  TOO_MANY_INTRO_CALLS_WITH_COACH = 'TOO_MANY_INTRO_CALLS_WITH_COACH',
  TYPEFORM_RESPONSE_NOT_FOUND = 'TYPEFORM_RESPONSE_NOT_FOUND',
  UNSUPPORTED_IMAGE_TYPE = 'UNSUPPORTED_IMAGE_TYPE',
  /**  Company or School */
  UPDATES_BLOCKED = 'UPDATES_BLOCKED',
  /**  Checkout */
  VERSION_CONFLICT = 'VERSION_CONFLICT',
  VIDEO_NOT_FOUND = 'VIDEO_NOT_FOUND',
}

export type LelandMembership = {
  __typename?: 'LelandMembership';
  active: Scalars['Boolean']['output'];
  /**  if start/end not specified, use the next 60 days */
  cycles?: Maybe<Array<Maybe<LelandMembershipCycle>>>;
  matchedCoaches?: Maybe<Array<MatchedCoach>>;
  matchesPending?: Maybe<Scalars['Boolean']['output']>;
  /**  Shows when a membership should renew without returning the entire subscription object */
  nextCreditGrantAt?: Maybe<Scalars['Long']['output']>;
  sku?: Maybe<Sku>;
  subscription?: Maybe<StripeSubscription>;
  /**  mainly used for A la carte */
  tier?: Maybe<SkuTier>;
};

export type LelandMembershipCycle = {
  __typename?: 'LelandMembershipCycle';
  end: Scalars['Long']['output'];
  start: Scalars['Long']['output'];
  /**  time in minutes */
  timeAvailable: Scalars['Int']['output'];
};

export type LelandPlus = {
  __typename?: 'LelandPlus';
  active: Scalars['Boolean']['output'];
  subscription?: Maybe<StripeSubscription>;
};

export enum LelandPlusCancelReason {
  BETTER_ALTERNATIVE = 'BETTER_ALTERNATIVE',
  INSUFFICIENT_CONTENT = 'INSUFFICIENT_CONTENT',
  LELAND_GOAL_REACHED = 'LELAND_GOAL_REACHED',
  NOT_HELPFUL = 'NOT_HELPFUL',
  NO_LONGER_NEEDED = 'NO_LONGER_NEEDED',
  OTHER = 'OTHER',
  TECHNICAL_ISSUES = 'TECHNICAL_ISSUES',
  TOO_EXPENSIVE = 'TOO_EXPENSIVE',
  VALUE_UNEXPECTED = 'VALUE_UNEXPECTED',
}

export type Lex = {
  __typename?: 'Lex';
  description?: Maybe<Scalars['String']['output']>;
  isRamped: Scalars['Boolean']['output'];
  rampHistories?: Maybe<Array<StringVersionedField>>;
  slug: Scalars['String']['output'];
};

export type LexBatchResponse = {
  __typename?: 'LexBatchResponse';
  count: Scalars['Int']['output'];
  results: Array<Lex>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LexInput = {
  description: Scalars['String']['input'];
  rampStage?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

export type LexPatchInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  rampStage?: InputMaybe<Scalars['String']['input']>;
};

export type LinkedInMember = {
  __typename?: 'LinkedInMember';
  about?: Maybe<Scalars['String']['output']>;
  educationExperiences?: Maybe<Array<EducationSuggestion>>;
  headline?: Maybe<Scalars['String']['output']>;
  professionalExperiences?: Maybe<Array<ProfessionalExperienceSuggestion>>;
};

export type LinkedinResponse = {
  __typename?: 'LinkedinResponse';
  coachRecommendation?: Maybe<Scalars['String']['output']>;
  coachingObjective?: Maybe<Scalars['String']['output']>;
  experienceLevel?: Maybe<Scalars['String']['output']>;
  tellUsMore?: Maybe<Scalars['String']['output']>;
};

export type LocalDateRange = {
  __typename?: 'LocalDateRange';
  end: Scalars['Date']['output'];
  start: Scalars['Date']['output'];
};

export type LocalDateRangeInput = {
  end: Scalars['Date']['input'];
  start: Scalars['Date']['input'];
};

export type LocalTimeRange = {
  __typename?: 'LocalTimeRange';
  end: Scalars['LocalTime']['output'];
  start: Scalars['LocalTime']['output'];
};

export type LocalTimeRangeInput = {
  end: Scalars['LocalTime']['input'];
  start: Scalars['LocalTime']['input'];
};

export type LongRange = {
  __typename?: 'LongRange';
  end?: Maybe<Scalars['Long']['output']>;
  start?: Maybe<Scalars['Long']['output']>;
};

export type LongRangeInput = {
  end?: InputMaybe<Scalars['Long']['input']>;
  start?: InputMaybe<Scalars['Long']['input']>;
};

export type MatchedCoach = {
  __typename?: 'MatchedCoach';
  applicant: Applicant;
  archived?: Maybe<Scalars['Boolean']['output']>;
  category: Category;
  claimedByOtherCoachAt?: Maybe<Scalars['Long']['output']>;
  coach?: Maybe<Coach>;
  /**  Note from ops */
  coachNote?: Maybe<Scalars['String']['output']>;
  coachingCategory?: Maybe<CategoryInformation>;
  confirmedAt?: Maybe<Scalars['Long']['output']>;
  customerSubscription?: Maybe<CustomerSubscription>;
  declinedAt?: Maybe<Scalars['Long']['output']>;
  expiredAt?: Maybe<Scalars['Long']['output']>;
  firstComeFirstServe?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['MatchedCoachUrn']['output'];
  /**  After July 1, 2024, contains intro call orders that used to be in opportunityOrder */
  introCallOrder?: Maybe<Order>;
  isOpportunity: Scalars['Boolean']['output'];
  isOpportunityExpired: Scalars['Boolean']['output'];
  isOpsRequest?: Maybe<Scalars['Boolean']['output']>;
  /**  After July 1, 2024, contains customer notes that used to be in coachNote */
  noteFromCustomer?: Maybe<Scalars['String']['output']>;
  /**  Same as above, except a session that creates this match opportunity */
  opportunityCoachingSession?: Maybe<CoachingSession>;
  /**  Intro call or order from coach profile that creates this match opportunity */
  opportunityOrder?: Maybe<Order>;
  /**  It will only return prices in matched tier for coach self view or non-ops, while return all prices for ops */
  opportunityPackages?: Maybe<Array<OfferingPackage>>;
  requestedByCustomerAt?: Maybe<Scalars['Long']['output']>;
  requestedToCoachAt?: Maybe<Scalars['Long']['output']>;
  status: MatchedCoachStatus;
  tier?: Maybe<SkuTier>;
  unitPayoutAmount: Scalars['Int']['output'];
};

export type MatchedCoachOpportunityPackagesArgs = {
  matchTierOnly?: Scalars['Boolean']['input'];
};

export type MatchedCoachBatchResponse = {
  __typename?: 'MatchedCoachBatchResponse';
  count: Scalars['Int']['output'];
  results: Array<MatchedCoach>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type MatchedCoachInput = {
  applicantUrn: Scalars['ApplicantUrn']['input'];
  categoryUrn: Scalars['CategoryUrn']['input'];
  coachNote?: InputMaybe<Scalars['String']['input']>;
  coachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
  customerSubscriptionUrn?: InputMaybe<
    Scalars['CustomerSubscriptionUrn']['input']
  >;
  firstComeFirstServe?: InputMaybe<Scalars['Boolean']['input']>;
  noteFromCustomer?: InputMaybe<Scalars['String']['input']>;
  opportunityOrderUrn?: InputMaybe<Scalars['OrderUrn']['input']>;
  opportunityPackageUrns?: InputMaybe<Array<Scalars['PackageUrn']['input']>>;
  packageOverrides?: InputMaybe<Array<MatchedCoachingPackageInput>>;
  status?: InputMaybe<MatchedCoachStatus>;
  tier?: InputMaybe<SkuTier>;
  /**  Number, in cents, this coach should be paid per hour in this match */
  unitPayoutAmount?: InputMaybe<Scalars['Int']['input']>;
};

export type MatchedCoachPatchInput = {
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  coachNote?: InputMaybe<Scalars['String']['input']>;
  customerSubscriptionUrn?: InputMaybe<
    Scalars['CustomerSubscriptionUrn']['input']
  >;
  firstComeFirstServe?: InputMaybe<Scalars['Boolean']['input']>;
  noteFromCustomer?: InputMaybe<Scalars['String']['input']>;
  opportunityOrderUrn?: InputMaybe<Scalars['OrderUrn']['input']>;
  opportunityPackageUrns?: InputMaybe<Array<Scalars['PackageUrn']['input']>>;
  packageOverrides?: InputMaybe<Array<MatchedCoachingPackageInput>>;
  status?: InputMaybe<MatchedCoachStatus>;
  tier?: InputMaybe<SkuTier>;
  /**  Number, in cents, this coach should be paid per hour in this match */
  unitPayoutAmount?: InputMaybe<Scalars['Int']['input']>;
};

export enum MatchedCoachStatus {
  /**  The coach has declined the matching */
  COACH_DECLINED = 'COACH_DECLINED',
  /**  The matching between the customer and coach has been confirmed */
  CONFIRMED = 'CONFIRMED',
  /**  The customer has declined the match */
  DECLINED = 'DECLINED',
  /**  The match has been requested */
  REQUESTED = 'REQUESTED',
}

export type MatchedCoachingPackageInput = {
  packageUrn: Scalars['PackageUrn']['input'];
  priceOverrides: Array<OfferingPackagePricePatchInput>;
};

export type MessageRequest = {
  __typename?: 'MessageRequest';
  /** @deprecated No longer supported */
  category?: Maybe<Category>;
  interest?: Maybe<IntroCallInterest>;
  isDismissed?: Maybe<Scalars['Boolean']['output']>;
  packages?: Maybe<Array<OfferingPackage>>;
};

export type Metric = {
  __typename?: 'Metric';
  amount?: Maybe<Scalars['Long']['output']>;
  metadata: MetricMetadata;
  timestamp: Scalars['Long']['output'];
};

export type MetricMetadata = {
  __typename?: 'MetricMetadata';
  actorUrn?: Maybe<Scalars['Urn']['output']>;
  type: Scalars['String']['output'];
};

export type MonthYear = {
  __typename?: 'MonthYear';
  month?: Maybe<Scalars['Int']['output']>;
  year: Scalars['Int']['output'];
};

export type MonthYearInput = {
  month?: InputMaybe<Scalars['Int']['input']>;
  year: Scalars['Int']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**  Activate the sku to create Stripe counterparts */
  activateSku: Sku;
  /**  Create a new entry for CoachingTimeLog */
  addCoachingTimeLog: CoachingTimeLog;
  addPreSignUpCustomer: PreSignUpCustomer;
  adjustOrderPayout: Order;
  allowIntroRequest: Scalars['Boolean']['output'];
  /**  Archive or un-archive a matched coach. This is a soft delete */
  archiveMatchedCoach: MatchedCoach;
  /**  Assigns a new experience order to a coach. In self serve, this works for hourly and all package orders too */
  assignPendingOrder: Order;
  /**  Batch creation of coaching outcomes */
  batchCreateCoachingOutcomes: Array<CoachingOutcome>;
  /**  Batch creation of coaching outcome vouches */
  batchCreateCoachingOutcomesForVouch: Array<CoachingOutcome>;
  batchDeleteCoachingOutcome: Scalars['Boolean']['output'];
  cancelCoachMatchRequest: Scalars['Boolean']['output'];
  /**  Cancels a session by archiving it */
  cancelCoachingSession: Scalars['Boolean']['output'];
  /**  Cancel Leland+ subscription for subscribers */
  cancelLelandPlus: Scalars['Boolean']['output'];
  cancelSubscription: CustomerSubscription;
  changeClassSession: Applicant;
  /**  Use with caution!!! */
  changeEmail: User;
  changeSubscriptionSku: CustomerSubscription;
  /**  For customer to confirm coaching completion after the coach marks the order as COACHING_COMPLETED */
  confirmCoachingCompletion: Order;
  /**  New membership mutations */
  convertCustomerToMembership: Applicant;
  /**  Apply or remove a coupon for the order. When `coupon` provided is null, it would remove any previously applied coupon */
  couponToOrder: Order;
  /**  Creates a new category (unless a category with the same name and department exists, then updates and returns existing category) */
  createCategory: Category;
  /**
   * ####################
   *   COACH MUTATIONS  #
   * ####################
   *  Creates a new draft of a group class
   */
  createClassDraft: Class;
  /**  Creates a new service (aka CoachingFocus, since names) (unless a service with the same name already exists) */
  createCoachingFocus: CoachingFocus;
  /**  Create a CoachingOutcome by applicant */
  createCoachingOutcome: CoachingOutcome;
  /**  Outcomes for vouches are slightly different, so have different mutation for it */
  createCoachingOutcomeForVouch: CoachingOutcome;
  /**  Creates a session by coach/ops */
  createCoachingSession: CoachingSession;
  /**  Creates a session by applicant */
  createCoachingSessionByApplicant: CoachingSession;
  createCompany: Company;
  /**  Create a coupon */
  createCoupon: Coupon;
  /**  Create Goal */
  createCustomerGoal: CustomerGoal;
  /**  Creates a new department (unless with a department with the same name or slug already exists) */
  createDepartment: Department;
  createJobTitle: JobTitle;
  /**  Create a leland coupon */
  createLelandCoupon: Coupon;
  createLelandPackage: OfferingPackage;
  createLex: Lex;
  /**  Create a package for the current coach */
  createOfferingPackage: OfferingPackage;
  /**  Creates an opportunity */
  createOpportunity: MatchedCoach;
  /**  Creates or updates a coach category listing */
  createOrUpdateCategoryListing: Coach;
  /**  Updates a company with the given name and optional domain */
  createOrUpdateCompany: Company;
  /**  Create or update an entity review. Update can only change anonymous and rate fields. */
  createOrUpdateEntityReview: EntityReview;
  /**  Create order for the applicant */
  createOrder: Order;
  /**  Creates a new LEAD relationship between coach/customer if a relationship does not already exist */
  createRelationshipByOps: CoachingRelationship;
  /**  Create a review by applicant */
  createReview: Review;
  createSchool: School;
  createShindigV2: Shindig;
  /**  Create a sku */
  createSku: Sku;
  createStripeSession: Scalars['String']['output'];
  /**  Creates a new sub category (unless a sub category with the same name already exists) */
  createSubCategory: SubCategory;
  /**  Create a test meeting link for coach to get familiar with Whereby */
  createTestMeetingLink: Scalars['String']['output'];
  /**  Creates a vouch (upsert) */
  createVouch: Review;
  /**  Creates a guest vouch (upsert). Since no applicant account associated, every call to this will create a new review instead of updating an existing one (if it exists). */
  createVouchByOps: Review;
  cryptoString: Scalars['String']['output'];
  deduplicateCompanies: Scalars['Boolean']['output'];
  /**  Deletes a coach category listing */
  deleteCategoryListing: Scalars['Boolean']['output'];
  /**  Archives (deletes) a class (both draft and published version if applicable) */
  deleteClass: Scalars['Boolean']['output'];
  /**  Delete the given outcome */
  deleteCoachingOutcome: Scalars['Boolean']['output'];
  deleteCoachingOutcomeBy: Scalars['Boolean']['output'];
  /**  Delete a coupon */
  deleteCouponV2: Scalars['Boolean']['output'];
  deleteCustomerGoal: Scalars['Boolean']['output'];
  deleteLelandPackage: Scalars['Boolean']['output'];
  deleteLex: Scalars['Boolean']['output'];
  deleteOfferingPackage: Scalars['Boolean']['output'];
  /**  Delete user's verified phone number */
  deletePhoneNumber: User;
  /**  Delete user profile photo for the current user */
  deleteProfilePhoto: User;
  /**  Delete the given review or vouch */
  deleteReview: Scalars['Boolean']['output'];
  /**
   *  Use `updateOrderStatus` instead
   * @deprecated No longer supported
   */
  dismissIntroCall: Scalars['Boolean']['output'];
  /**  Sets flag on new message order that the reminder for it should be dismissed. This does not affect the relationship, just removes a banner on the FE. */
  dismissIntroMessage: Scalars['Boolean']['output'];
  /**  Create an outcome by applicant (vouch outcomes still work here) */
  editCoachingOutcome: CoachingOutcome;
  /**  Edits a published class and resets the draft version to the latest published version */
  editPublishedClassByOps?: Maybe<Class>;
  /**  Allow ops to edit a review */
  editReviewByOps: Review;
  /**  Sends an email to all attendees of a session */
  emailCoachingSession: Scalars['Boolean']['output'];
  /**  End a coupon */
  endCoupon: Coupon;
  enrollFreeEvent: CoachingSession;
  extendOrderExpiration: Order;
  /**  Coach mark a review as featured for their profile */
  featureReviewByCoach: Review;
  /**  highlight/star/feature review. Review must have outcomes associated with it. */
  featureReviewByOps: Review;
  /**  Full update a coupon */
  fullUpdateCoupon: Coupon;
  /**  Full update a leland coupon */
  fullUpdateLelandCoupon: Coupon;
  generateContentForCoach: Array<Scalars['String']['output']>;
  /**  Generate SSO URL for user to access Leland+ */
  generateLearnWorldsSSOUrl: Scalars['String']['output'];
  /**  This will generate user password reset url, which is used when the user cannot receive reset email. Please confirm user identity before sharing the reset link */
  generatePasswordResetLinkByOps: Scalars['String']['output'];
  /**  Generate account management link for the current coach. If the coach is not onboarded yet, the link will be onboarding link */
  generateStripeAccountManagementLink: Scalars['String']['output'];
  /**  Generate customer portal link for the current applicant to manage their saved cards */
  generateStripeCustomerPortalLink: Scalars['String']['output'];
  getCalendarOauthUrl: Scalars['String']['output'];
  getCoachCalendarItems: Array<ScheduleItems>;
  grantCredit: CustomerCredit;
  grantFreeOrder: Order;
  /**  Grant coach leland+ access */
  grantLelandPlusToCoach: Coach;
  /**  Create account, order, and payment intent */
  guestCheckout: Order;
  /**  If `expireAt` is in the past, this will directly mark the orders as completed. Otherwise, it will extend the order expiration. */
  handleOrderExpiration: Scalars['Boolean']['output'];
  /**  Create or get stripe client secret, which is used when the applicant intents to make the payment */
  intentToPayOrder: Scalars['String']['output'];
  intentToSetup: Scalars['String']['output'];
  /**  Subscribe to a sku */
  intentToSubscribeV2: IntentToSubscribeResponse;
  /**  Invoice customer for an order from the coach */
  invoiceCustomer: Order;
  /**
   * ######################
   *   CUSTOMER MUTATIONS #
   * ######################
   *  Need to do a deeper dive into client-side. Enrollment and payment flows
   */
  joinDiscoveryList: Scalars['Boolean']['output'];
  leaveDiscoveryList: Scalars['Boolean']['output'];
  /**  Logs a feature interaction for the current user */
  logFeatureInteraction: Scalars['Boolean']['output'];
  /**  Sign in an existing user */
  login: User;
  /**  Returns a logout url to sign out the current user session, which will eventually redirect to home page */
  logoutUrl: Scalars['String']['output'];
  /**  Used when the email used to purchase Leland+ is different from their Leland account */
  manuallyCreateLearnworldsUser: Scalars['Boolean']['output'];
  manuallyRevokeLearnworldsUser: Scalars['Boolean']['output'];
  markCalendarForEmailInvites: Coach;
  /**
   *  OPS or customer creates a match between a customer and coach
   * @deprecated use createOpportunity instead
   */
  matchCoachToMembership: Applicant;
  /**  Sends a message to all attendees of a session */
  messageCoachingSession: Scalars['Boolean']['output'];
  optInOrOutLelandPackage: OfferingPackage;
  /**  Pause a subscription */
  pauseSubscription: CustomerSubscription;
  /**
   *  Post actions after using auth0 SDK, including user DTO creation, auth0 identity binding, etc.
   *  Returns a user if able to auth, otherwise return null
   */
  postAuth0Action?: Maybe<User>;
  /**  Fulfil the order status to PENDING_CHARGE or CLASS_BOOKED after making payments through Stripe. applicantUrn is required for guest postPay */
  postPayOrder: Order;
  promoteLLMPrompt: LlmPrompt;
  /**  Recovers a deleted class */
  recoverClass: Scalars['Boolean']['output'];
  /**  Fix stripe account issue by recreating a Stripe account */
  recreateStripeAccount: Scalars['Boolean']['output'];
  /**  A temporary endpoint to redeem a code for an existing user. This should go away after LinkedIn partnership is launched */
  redeemCode: User;
  refreshShindig: Shindig;
  /**  Refund order based on customer request */
  refundOrder: Order;
  /**  Register the device token for the user */
  registerMobileDevice: Scalars['Boolean']['output'];
  removeCalendarIntegration: Scalars['Boolean']['output'];
  removeRecommendedOffering: CoachingRelationship;
  reportCoachForGhosting: Scalars['Boolean']['output'];
  reportCoachingSessionIssue: Scalars['Boolean']['output'];
  reportCustomerForGhosting: Scalars['Boolean']['output'];
  /**  Initiates a relationship between a customer and a coach and sends an initial message. A prior relationship cannot exist or this will fail */
  requestIntroMessage: Order;
  /**  Request a security code from the email, for a logging-in user, which will check to make sure the email already existed */
  requestLoginSecurityCode: Scalars['Boolean']['output'];
  requestMoreCoachMatches: Applicant;
  /**  Sending verification code to the phone number, which is only eligible when the user has no phone number on file */
  requestPhoneVerificationCode: Scalars['Boolean']['output'];
  /**  Request a review from an applicant */
  requestReview: Scalars['Boolean']['output'];
  /**  Request a security code from the email, for a signing-up user, which will check to make sure the email not existed */
  requestSignupSecurityCode: Scalars['Boolean']['output'];
  /**  Resend email verification email for current user */
  resendVerificationEmail: Scalars['Boolean']['output'];
  /**
   *  Resets draft of a class to latest published version
   *  Think of this as an "undo changes" sort of thing
   *  If there is no published version, this will do nothing
   */
  resetClassDraft: Class;
  resetCoachMatchRequest: Applicant;
  /**  Reset password for an existing user */
  resetPassword: Scalars['Boolean']['output'];
  /**  Manually trigger revalidation of a static site route */
  revalidateRouteByOps: Scalars['Boolean']['output'];
  /**
   * ####################
   *    OPS MUTATIONS   #
   * ####################
   */
  reviewClassByOps: Scalars['Boolean']['output'];
  /**  Revoke coach leland+ access */
  revokeLelandPlusFromCoach: Coach;
  rollbackLLMPrompt: LlmPrompt;
  saveCalendarCode: Scalars['String']['output'];
  /**  Takes a code, returns the corresponding email for this calendar */
  saveGoogleCalendarCode: Scalars['String']['output'];
  /**  Save user's LinkedIn link. For fetching data, it would retrieve at most once per week for a user to save quota */
  saveLinkedInLink: User;
  /**  Tells OPS that this class is ready for review */
  sendClassDraftForReview: Scalars['Boolean']['output'];
  /**  Send a message (from coach or applicant based on context) */
  sendMessage: Scalars['Boolean']['output'];
  /**  Sends message from applicant to coach */
  sendMessageFromApplicantToCoach: Scalars['Boolean']['output'];
  sendRecommendedOffering: CoachingRelationship;
  sendSeoEmailCollection: Scalars['Boolean']['output'];
  /**  email is the base calendarId for the calendar connection this is looking up */
  setAdditionalCalendars: Scalars['Boolean']['output'];
  /**  Sets leland package order within a category. */
  setCoachLelandPackageOrder: Array<OfferingPackage>;
  setCoachPackageOverrides: Scalars['Boolean']['output'];
  /**  Sets package order within a category. Throws an error if packages from multiple categories are used */
  setLelandPackageOrder: Coach;
  setOpsPermissionsForUser: User;
  /**  Sets package order within a category. */
  setPackageOrder: Coach;
  /**  Set sku tier for the current applicant. This would only work if the current member is not subscribed in the recurring plan */
  setSkuTier: Applicant;
  /**  If null, removes the sku tier from the customer if possible */
  setSkuTierByOpsV2: Applicant;
  /**  Shorten a URL, when expireAt is not present, default to 1 month from now */
  shortenUrl: Scalars['String']['output'];
  /**  Helper mutation to switch between the coach's default visibility and HIDDEN visibility */
  showHideCoachingCategory: Coach;
  /**  Sign up a new user */
  signup: User;
  /**  Sign up as applicant for the login coach */
  signupAsApplicant: User;
  /**  Sign up as coach for the login applicant */
  signupAsCoach: User;
  startStandardizedPackage: Order;
  startStandardizedPackageByOps: Order;
  unReportCustomerForGhosting: Scalars['Boolean']['output'];
  uncancelSubscription: CustomerSubscription;
  unenrollFreeEvent: CoachingSession;
  /**  Use with caution!!! */
  unlinkAndRecreateAuth0?: Maybe<Scalars['Boolean']['output']>;
  unpauseSubscription: CustomerSubscription;
  /**  Update applicant */
  updateApplicantByOps: Applicant;
  /**  Updates an existing category. Update operations can only update the offerings, isFeatured, and media fields. Either categoryInput or image is required */
  updateCategory: Category;
  /**  Updates a draft of a group class and puts it into the "DRAFT" status if not already there */
  updateClassDraft: Class;
  /**  Update the given coach */
  updateCoachByOps: Coach;
  /**  Update visibility of an individual coaching category */
  updateCoachingCategoryVisibility: Coach;
  /**
   *  Update a coaching relationship
   *  Note: `archived` or `blocked` must be explicitly set to `false` to unarchive or unblock the client
   */
  updateCoachingRelationship: CoachingRelationship;
  /**  Updates a session. OPS has more freedom than COACH in doing updates. */
  updateCoachingSession: CoachingSession;
  /**  Edit an existing CoachingTimeLog */
  updateCoachingTimeLog: CoachingTimeLog;
  /**  Updates a specific company in the DB by its ID */
  updateCompanyById: Company;
  updateCompanyLogo: Company;
  /**  Update Goal */
  updateCustomerGoal: CustomerGoal;
  /**  Updates event date. Works even if there are registered attendees. Use wisely. */
  updateEventDate: CoachingSession;
  updateLelandPackage: OfferingPackage;
  updateLex: Lex;
  /**  Update category, unit payout, or status of a matched coach by ops. If only updating status, please use updateMatchedCoachStatus */
  updateMatchedCoach: MatchedCoach;
  /**  Updates status. Ops has slightly more freedom than customers and coaches to update the status */
  updateMatchedCoachStatus: MatchedCoach;
  /**  Update a given package for the current coach */
  updateOfferingPackage: OfferingPackage;
  /**  Update order by id */
  updateOrder: Order;
  /**  Update the order status by coach */
  updateOrderStatus: Order;
  updateSchoolLogo: School;
  updateShindigV2: Shindig;
  /**  Edit a sku */
  updateSku: Sku;
  /**  Change the status of the coach. Coach could only change the status to LIVE, UNDER_REVIEW, PAUSED or DEACTIVATED */
  updateStatusByCoach: Coach;
  /**  Change the status of the coach by ops */
  updateStatusByOps: Coach;
  /**  Update user information for the user in the current session */
  updateUser: User;
  /**  Change the visibility of the coach. Options are PUBLIC, PRIVATE_OPEN, PRIVATE_CLOSED */
  updateVisibilityByCoach: Coach;
  /**  Upload user profile photo for the current user */
  uploadProfilePhoto: User;
  upsertLLMPrompt: LlmPrompt;
  /**  Verify user's phone number, and save to user if successfully verified */
  verifyAndSavePhoneNumber: User;
};

export type MutationActivateSkuArgs = {
  id: Scalars['SkuUrn']['input'];
};

export type MutationAddCoachingTimeLogArgs = {
  input: CoachingTimeLogInput;
  sendUpdateToApplicant?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationAddPreSignUpCustomerArgs = {
  email: Scalars['String']['input'];
  newExperience?: Scalars['Boolean']['input'];
};

export type MutationAdjustOrderPayoutArgs = {
  orderUrn: Scalars['OrderUrn']['input'];
  payout: Scalars['Int']['input'];
};

export type MutationAllowIntroRequestArgs = {
  applicantUrn: Scalars['ApplicantUrn']['input'];
};

export type MutationArchiveMatchedCoachArgs = {
  archive?: Scalars['Boolean']['input'];
  id: Scalars['MatchedCoachUrn']['input'];
};

export type MutationAssignPendingOrderArgs = {
  coachUrn: Scalars['CoachUrn']['input'];
  orderUrn: Scalars['OrderUrn']['input'];
};

export type MutationBatchCreateCoachingOutcomesArgs = {
  input: Array<CoachingOutcomeInput>;
};

export type MutationBatchCreateCoachingOutcomesForVouchArgs = {
  coachUrn: Scalars['CoachUrn']['input'];
  input: Array<CoachingOutcomeForVouchInput>;
};

export type MutationBatchDeleteCoachingOutcomeArgs = {
  ids?: InputMaybe<Array<Scalars['CoachingOutcomeUrn']['input']>>;
};

export type MutationCancelCoachMatchRequestArgs = {
  id: Scalars['MatchedCoachUrn']['input'];
};

export type MutationCancelCoachingSessionArgs = {
  id: Scalars['CoachingSessionUrn']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCancelLelandPlusArgs = {
  cancelComment?: InputMaybe<Scalars['String']['input']>;
  cancelReasons: Array<LelandPlusCancelReason>;
};

export type MutationCancelSubscriptionArgs = {
  cancelComment?: InputMaybe<Scalars['String']['input']>;
  cancelReasons: Array<SubscriptionCancelReason>;
  id: Scalars['CustomerSubscriptionUrn']['input'];
};

export type MutationChangeClassSessionArgs = {
  applicantUrn: Scalars['ApplicantUrn']['input'];
  newClassSessionUrn: Scalars['CoachingSessionUrn']['input'];
  oldClassSessionUrn: Scalars['CoachingSessionUrn']['input'];
};

export type MutationChangeEmailArgs = {
  newEmail: Scalars['String']['input'];
  oldEmail: Scalars['String']['input'];
};

export type MutationChangeSubscriptionSkuArgs = {
  effectiveNow?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['CustomerSubscriptionUrn']['input'];
  skuUrn: Scalars['SkuUrn']['input'];
};

export type MutationConfirmCoachingCompletionArgs = {
  id: Scalars['OrderUrn']['input'];
  isCompleted: Scalars['Boolean']['input'];
};

export type MutationConvertCustomerToMembershipArgs = {
  applicantUrn: Scalars['ApplicantUrn']['input'];
  newExperience?: Scalars['Boolean']['input'];
};

export type MutationCouponToOrderArgs = {
  couponCode?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['OrderUrn']['input'];
};

export type MutationCreateCategoryArgs = {
  categoryInput: CategoryInput;
  image?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationCreateClassDraftArgs = {
  image?: InputMaybe<Scalars['Upload']['input']>;
  input: ClassInput;
};

export type MutationCreateCoachingFocusArgs = {
  coachingFocusInput: CoachingFocusInput;
};

export type MutationCreateCoachingOutcomeArgs = {
  input: CoachingOutcomeInput;
};

export type MutationCreateCoachingOutcomeForVouchArgs = {
  coachUrn: Scalars['CoachUrn']['input'];
  input: CoachingOutcomeForVouchInput;
};

export type MutationCreateCoachingSessionArgs = {
  input: CoachingSessionInput;
  message?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateCoachingSessionByApplicantArgs = {
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  input: CoachingSessionInputByApplicant;
  message?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateCompanyArgs = {
  input: CompanyInput;
  logo?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationCreateCouponArgs = {
  input: CouponInput;
};

export type MutationCreateCustomerGoalArgs = {
  input: CustomerGoalInput;
};

export type MutationCreateDepartmentArgs = {
  departmentInput: DepartmentInput;
};

export type MutationCreateJobTitleArgs = {
  name: Scalars['String']['input'];
};

export type MutationCreateLelandCouponArgs = {
  appliesToCoaches: Array<Scalars['CoachUrn']['input']>;
  discountOnLeland: Scalars['Boolean']['input'];
  input: CouponInput;
};

export type MutationCreateLelandPackageArgs = {
  image?: InputMaybe<Scalars['Upload']['input']>;
  input: OfferingPackageInput;
};

export type MutationCreateLexArgs = {
  input: LexInput;
};

export type MutationCreateOfferingPackageArgs = {
  image?: InputMaybe<Scalars['Upload']['input']>;
  input: OfferingPackageInput;
};

export type MutationCreateOpportunityArgs = {
  input: MatchedCoachInput;
  packageGlobalOverrides?: InputMaybe<Array<CoachingPackageInput>>;
  sendEmail?: Scalars['Boolean']['input'];
};

export type MutationCreateOrUpdateCategoryListingArgs = {
  info: CategoryInformationInput;
};

export type MutationCreateOrUpdateCompanyArgs = {
  companyInput: CompanyInput;
  image?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationCreateOrUpdateEntityReviewArgs = {
  input: EntityReviewInput;
};

export type MutationCreateOrderArgs = {
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  classOrder?: InputMaybe<ClassOrderInput>;
  coachUrn: Scalars['CoachUrn']['input'];
  hourlyOrder?: InputMaybe<HourlyOrderInput>;
  note?: InputMaybe<Scalars['String']['input']>;
  numberOfCharges?: Scalars['Int']['input'];
  packageOrder?: InputMaybe<PackageOrderInput>;
  tier?: InputMaybe<SkuTier>;
};

export type MutationCreateRelationshipByOpsArgs = {
  applicantUrn: Scalars['ApplicantUrn']['input'];
  coachUrn: Scalars['CoachUrn']['input'];
};

export type MutationCreateReviewArgs = {
  input: ReviewInput;
};

export type MutationCreateSchoolArgs = {
  input: SchoolInput;
  logo?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationCreateShindigV2Args = {
  input: ShindigInputV2;
};

export type MutationCreateSkuArgs = {
  input: SkuInput;
};

export type MutationCreateSubCategoryArgs = {
  subCategoryInput: SubCategoryInput;
};

export type MutationCreateVouchArgs = {
  input: ReviewVouchInput;
};

export type MutationCreateVouchByOpsArgs = {
  guestReviewer: Scalars['String']['input'];
  input: OpsVouchInput;
};

export type MutationCryptoStringArgs = {
  decrypt?: Scalars['Boolean']['input'];
  input: Scalars['String']['input'];
};

export type MutationDeduplicateCompaniesArgs = {
  companyUrn: Scalars['CompanyUrn']['input'];
  duplicateCompanyUrns: Array<Scalars['CompanyUrn']['input']>;
};

export type MutationDeleteCategoryListingArgs = {
  categoryUrn: Scalars['CategoryUrn']['input'];
};

export type MutationDeleteClassArgs = {
  id: Scalars['ClassUrn']['input'];
};

export type MutationDeleteCoachingOutcomeArgs = {
  id: Scalars['CoachingOutcomeUrn']['input'];
};

export type MutationDeleteCoachingOutcomeByArgs = {
  applicantUrn: Scalars['ApplicantUrn']['input'];
  coachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
  outcome: CoachingOutcomePatchInput;
};

export type MutationDeleteCouponV2Args = {
  id: Scalars['CouponUrn']['input'];
};

export type MutationDeleteCustomerGoalArgs = {
  id: Scalars['CustomerGoalUrn']['input'];
};

export type MutationDeleteLelandPackageArgs = {
  id: Scalars['PackageUrn']['input'];
};

export type MutationDeleteLexArgs = {
  slug: Scalars['String']['input'];
};

export type MutationDeleteOfferingPackageArgs = {
  id: Scalars['PackageUrn']['input'];
};

export type MutationDeleteReviewArgs = {
  id: Scalars['ReviewUrn']['input'];
};

export type MutationDismissIntroCallArgs = {
  id: Scalars['OrderUrn']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  status: OrderStatus;
};

export type MutationDismissIntroMessageArgs = {
  id: Scalars['OrderUrn']['input'];
};

export type MutationEditCoachingOutcomeArgs = {
  id?: InputMaybe<Scalars['CoachingOutcomeUrn']['input']>;
  input: CoachingOutcomePatchInput;
};

export type MutationEditPublishedClassByOpsArgs = {
  id: Scalars['ClassUrn']['input'];
  image?: InputMaybe<Scalars['Upload']['input']>;
  input?: InputMaybe<ClassInput>;
};

export type MutationEditReviewByOpsArgs = {
  anonymous?: InputMaybe<Scalars['Boolean']['input']>;
  categoryReviews?: InputMaybe<Array<CategoryReviewInput>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ReviewUrn']['input'];
  offerings?: InputMaybe<Array<Offering>>;
  rate?: InputMaybe<Scalars['Int']['input']>;
  schoolAdmissionInfoList?: InputMaybe<Array<SchoolAdmissionInfoInput>>;
};

export type MutationEmailCoachingSessionArgs = {
  id: Scalars['CoachingSessionUrn']['input'];
  text: Scalars['String']['input'];
};

export type MutationEndCouponArgs = {
  id: Scalars['CouponUrn']['input'];
};

export type MutationEnrollFreeEventArgs = {
  id: Scalars['CoachingSessionUrn']['input'];
};

export type MutationExtendOrderExpirationArgs = {
  newExpireAt: Scalars['Long']['input'];
  orderUrn: Scalars['OrderUrn']['input'];
};

export type MutationFeatureReviewByCoachArgs = {
  featured?: Scalars['Boolean']['input'];
  reviewUrn: Scalars['ReviewUrn']['input'];
};

export type MutationFeatureReviewByOpsArgs = {
  featured?: Scalars['Boolean']['input'];
  id: Scalars['ReviewUrn']['input'];
};

export type MutationFullUpdateCouponArgs = {
  id: Scalars['CouponUrn']['input'];
  input: CouponInput;
};

export type MutationFullUpdateLelandCouponArgs = {
  appliesToCoaches: Array<Scalars['CoachUrn']['input']>;
  discountOnLeland: Scalars['Boolean']['input'];
  id: Scalars['CouponUrn']['input'];
  input: CouponInput;
};

export type MutationGenerateContentForCoachArgs = {
  categoryUrn: Scalars['CategoryUrn']['input'];
  class?: InputMaybe<ClassInput>;
  package?: InputMaybe<OfferingPackagePatchInput>;
  promptName: LlmPromptName;
};

export type MutationGenerateLearnWorldsSsoUrlArgs = {
  utmSource?: InputMaybe<Scalars['String']['input']>;
};

export type MutationGeneratePasswordResetLinkByOpsArgs = {
  email: Scalars['String']['input'];
};

export type MutationGetCalendarOauthUrlArgs = {
  calendarType?: InputMaybe<CalendarType>;
};

export type MutationGetCoachCalendarItemsArgs = {
  coachUrn: Scalars['CoachUrn']['input'];
  dateRange?: InputMaybe<LongRangeInput>;
};

export type MutationGrantCreditArgs = {
  applicantUrn: Scalars['ApplicantUrn']['input'];
  moneyAmount: Scalars['Int']['input'];
  type: CustomerCreditType;
};

export type MutationGrantFreeOrderArgs = {
  applicantUrn: Scalars['ApplicantUrn']['input'];
  categoryUrn: Scalars['CategoryUrn']['input'];
  coachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
  payout: Scalars['Int']['input'];
  timeInMinute: Scalars['Int']['input'];
  type: CustomerCreditType;
};

export type MutationGrantLelandPlusToCoachArgs = {
  coachSlug: Scalars['String']['input'];
};

export type MutationGuestCheckoutArgs = {
  classOrder?: InputMaybe<ClassOrderInput>;
  coachUrn: Scalars['CoachUrn']['input'];
  couponCode?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  hourlyOrder?: InputMaybe<HourlyOrderInput>;
  lastName: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  numberOfCharges?: Scalars['Int']['input'];
  packageOrder?: InputMaybe<PackageOrderInput>;
  saveCard?: Scalars['Boolean']['input'];
  userSecrets?: InputMaybe<UserSecretsInput>;
};

export type MutationHandleOrderExpirationArgs = {
  applicantUrn: Scalars['ApplicantUrn']['input'];
  expireAt: Scalars['Long']['input'];
};

export type MutationIntentToPayOrderArgs = {
  creditToUse?: Scalars['Int']['input'];
  id: Scalars['OrderUrn']['input'];
  opportunityCoachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
  paymentMethod?: InputMaybe<Scalars['StripePaymentMethodUrn']['input']>;
  saveCard?: Scalars['Boolean']['input'];
  skuUrn?: InputMaybe<Scalars['SkuUrn']['input']>;
  submit?: Scalars['Boolean']['input'];
};

export type MutationIntentToSubscribeV2Args = {
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  couponCode?: InputMaybe<Scalars['String']['input']>;
  creditToUse?: Scalars['Int']['input'];
  nextSkuUrn?: InputMaybe<Scalars['SkuUrn']['input']>;
  opportunityCoachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
  paymentMethod?: InputMaybe<Scalars['StripePaymentMethodUrn']['input']>;
  skuUrn: Scalars['SkuUrn']['input'];
  submit?: Scalars['Boolean']['input'];
};

export type MutationInvoiceCustomerArgs = {
  applicantUrn: Scalars['ApplicantUrn']['input'];
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  classOrder?: InputMaybe<ClassOrderInput>;
  coachNote?: InputMaybe<Scalars['String']['input']>;
  hourlyOrder?: InputMaybe<HourlyOrderInput>;
  packageOrder?: InputMaybe<PackageOrderInput>;
};

export type MutationJoinDiscoveryListArgs = {
  id: Scalars['ClassUrn']['input'];
};

export type MutationLeaveDiscoveryListArgs = {
  id: Scalars['ClassUrn']['input'];
};

export type MutationLogFeatureInteractionArgs = {
  feature: Scalars['String']['input'];
};

export type MutationLoginArgs = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type MutationManuallyCreateLearnworldsUserArgs = {
  email: Scalars['String']['input'];
  sendEmail: Scalars['Boolean']['input'];
  stripeSubscriptionId: Scalars['String']['input'];
};

export type MutationManuallyRevokeLearnworldsUserArgs = {
  email: Scalars['String']['input'];
};

export type MutationMarkCalendarForEmailInvitesArgs = {
  calendar: Scalars['String']['input'];
  sendInvites: Scalars['Boolean']['input'];
};

export type MutationMatchCoachToMembershipArgs = {
  matchedCoach: MatchedCoachInput;
  packageGlobalOverrides?: InputMaybe<Array<CoachingPackageInput>>;
  sendEmail?: Scalars['Boolean']['input'];
};

export type MutationMessageCoachingSessionArgs = {
  file?: InputMaybe<Scalars['Upload']['input']>;
  id: Scalars['CoachingSessionUrn']['input'];
  text: Scalars['String']['input'];
};

export type MutationOptInOrOutLelandPackageArgs = {
  optIn: Scalars['Boolean']['input'];
  packageUrn: Scalars['PackageUrn']['input'];
};

export type MutationPauseSubscriptionArgs = {
  id: Scalars['CustomerSubscriptionUrn']['input'];
  monthsToPause: Scalars['Int']['input'];
};

export type MutationPostAuth0ActionArgs = {
  autoNewExperience?: Scalars['Boolean']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  redeemCode?: InputMaybe<Scalars['String']['input']>;
  target?: AuthTarget;
  userSecrets?: InputMaybe<UserSecretsInput>;
};

export type MutationPostPayOrderArgs = {
  applicantUrn?: InputMaybe<Scalars['ApplicantUrn']['input']>;
  id: Scalars['OrderUrn']['input'];
  opportunityCoachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
};

export type MutationPromoteLlmPromptArgs = {
  id: LlmPromptName;
};

export type MutationRecoverClassArgs = {
  id: Scalars['ClassUrn']['input'];
};

export type MutationRecreateStripeAccountArgs = {
  countryName?: Scalars['String']['input'];
  deleteOldStripe?: Scalars['Boolean']['input'];
  slug: Scalars['String']['input'];
};

export type MutationRedeemCodeArgs = {
  code: Scalars['String']['input'];
};

export type MutationRefreshShindigArgs = {
  id: Scalars['ShindigUrn']['input'];
};

export type MutationRefundOrderArgs = {
  amount: Scalars['Int']['input'];
  cancelSubscription?: Scalars['Boolean']['input'];
  dryRun?: Scalars['Boolean']['input'];
  id: Scalars['OrderUrn']['input'];
  lelandOnly: Scalars['Boolean']['input'];
  refundAsCredit?: Scalars['Boolean']['input'];
  timeToDeductInMinute?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationRegisterMobileDeviceArgs = {
  deviceToken: Scalars['String']['input'];
};

export type MutationRemoveCalendarIntegrationArgs = {
  calendar: Scalars['String']['input'];
};

export type MutationRemoveRecommendedOfferingArgs = {
  id: Scalars['CoachingRelationshipUrn']['input'];
  offeringId: Scalars['RecommendedOfferingUrn']['input'];
};

export type MutationReportCoachForGhostingArgs = {
  id: Scalars['CoachingSessionUrn']['input'];
};

export type MutationReportCoachingSessionIssueArgs = {
  id: Scalars['CoachingSessionUrn']['input'];
  issue: CoachingSessionIssueInput;
};

export type MutationReportCustomerForGhostingArgs = {
  id: Scalars['CoachingSessionUrn']['input'];
};

export type MutationRequestIntroMessageArgs = {
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  coachUrn: Scalars['CoachUrn']['input'];
  coachingSession?: InputMaybe<CoachingSessionInputByApplicant>;
  interest?: IntroCallInterest;
  introCallRequested?: InputMaybe<Scalars['Boolean']['input']>;
  message: Scalars['String']['input'];
  packageUrns?: InputMaybe<Array<Scalars['PackageUrn']['input']>>;
};

export type MutationRequestLoginSecurityCodeArgs = {
  email: Scalars['String']['input'];
};

export type MutationRequestPhoneVerificationCodeArgs = {
  phoneNumber: Scalars['String']['input'];
};

export type MutationRequestReviewArgs = {
  relationshipUrn: Scalars['CoachingRelationshipUrn']['input'];
};

export type MutationRequestSignupSecurityCodeArgs = {
  email: Scalars['String']['input'];
};

export type MutationResetClassDraftArgs = {
  id: Scalars['ClassUrn']['input'];
};

export type MutationResetCoachMatchRequestArgs = {
  applicantUrn: Scalars['ApplicantUrn']['input'];
};

export type MutationResetPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationRevalidateRouteByOpsArgs = {
  route: Scalars['String']['input'];
  siteTarget: SiteTarget;
};

export type MutationReviewClassByOpsArgs = {
  feedback?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ClassUrn']['input'];
  nextStatus?: InputMaybe<ClassStatus>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRevokeLelandPlusFromCoachArgs = {
  coachSlug: Scalars['String']['input'];
};

export type MutationRollbackLlmPromptArgs = {
  id: LlmPromptName;
};

export type MutationSaveCalendarCodeArgs = {
  calendarType: CalendarType;
  code: Scalars['String']['input'];
};

export type MutationSaveGoogleCalendarCodeArgs = {
  code: Scalars['String']['input'];
};

export type MutationSaveLinkedInLinkArgs = {
  fetchData?: Scalars['Boolean']['input'];
  linkedInLink: Scalars['String']['input'];
};

export type MutationSendClassDraftForReviewArgs = {
  id: Scalars['ClassUrn']['input'];
};

export type MutationSendMessageArgs = {
  id: Scalars['CoachingRelationshipUrn']['input'];
  message: Scalars['String']['input'];
};

export type MutationSendMessageFromApplicantToCoachArgs = {
  applicant: Scalars['ApplicantUrn']['input'];
  coach: Scalars['CoachUrn']['input'];
  message: Scalars['String']['input'];
};

export type MutationSendRecommendedOfferingArgs = {
  id: Scalars['CoachingRelationshipUrn']['input'];
  recommendation: RecommendedOfferingInput;
};

export type MutationSendSeoEmailCollectionArgs = {
  articleUrl?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<Scalars['CategoryUrn']['input']>>;
  email: Scalars['String']['input'];
  event?: InputMaybe<Scalars['String']['input']>;
  resource?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSetAdditionalCalendarsArgs = {
  calendarIds?: InputMaybe<Array<Scalars['String']['input']>>;
  email: Scalars['String']['input'];
};

export type MutationSetCoachLelandPackageOrderArgs = {
  categoryUrn: Scalars['CategoryUrn']['input'];
  ids: Array<Scalars['PackageUrn']['input']>;
};

export type MutationSetCoachPackageOverridesArgs = {
  packageGlobalOverrides?: InputMaybe<Array<CoachingPackageInput>>;
};

export type MutationSetLelandPackageOrderArgs = {
  categoryUrn: Scalars['CategoryUrn']['input'];
  ids?: InputMaybe<Array<Scalars['PackageUrn']['input']>>;
};

export type MutationSetOpsPermissionsForUserArgs = {
  email: Scalars['String']['input'];
  permissions: Array<OpsPermission>;
};

export type MutationSetPackageOrderArgs = {
  categoryUrn: Scalars['CategoryUrn']['input'];
  ids?: InputMaybe<Array<Scalars['PackageUrn']['input']>>;
};

export type MutationSetSkuTierArgs = {
  tier: SkuTier;
};

export type MutationSetSkuTierByOpsV2Args = {
  applicantUrn: Scalars['ApplicantUrn']['input'];
  tier?: InputMaybe<SkuTier>;
};

export type MutationShortenUrlArgs = {
  expireAt?: InputMaybe<Scalars['Long']['input']>;
  originalLink: Scalars['String']['input'];
};

export type MutationShowHideCoachingCategoryArgs = {
  categoryUrn: Scalars['CategoryUrn']['input'];
  setHidden: Scalars['Boolean']['input'];
};

export type MutationSignupArgs = {
  applicant?: InputMaybe<ApplicantInput>;
  autoNewExperience?: Scalars['Boolean']['input'];
  coach?: InputMaybe<CoachInput>;
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  redeemCode?: InputMaybe<Scalars['String']['input']>;
  userSecrets?: InputMaybe<UserSecretsInput>;
};

export type MutationSignupAsApplicantArgs = {
  applicant: ApplicantInput;
};

export type MutationSignupAsCoachArgs = {
  coach: CoachInput;
};

export type MutationStartStandardizedPackageArgs = {
  coachUrn: Scalars['CoachUrn']['input'];
  orderUrn: Scalars['OrderUrn']['input'];
};

export type MutationStartStandardizedPackageByOpsArgs = {
  applicantUrn: Scalars['ApplicantUrn']['input'];
  coachUrn: Scalars['CoachUrn']['input'];
  orderUrn: Scalars['OrderUrn']['input'];
  payout?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUnReportCustomerForGhostingArgs = {
  id: Scalars['CoachingSessionUrn']['input'];
};

export type MutationUncancelSubscriptionArgs = {
  id: Scalars['CustomerSubscriptionUrn']['input'];
};

export type MutationUnenrollFreeEventArgs = {
  id: Scalars['CoachingSessionUrn']['input'];
};

export type MutationUnlinkAndRecreateAuth0Args = {
  email: Scalars['String']['input'];
  primaryUserId: Scalars['String']['input'];
  secondaryUserId: Scalars['String']['input'];
};

export type MutationUnpauseSubscriptionArgs = {
  id: Scalars['CustomerSubscriptionUrn']['input'];
};

export type MutationUpdateApplicantByOpsArgs = {
  id: Scalars['ApplicantUrn']['input'];
  input: ApplicantInput;
};

export type MutationUpdateCategoryArgs = {
  categoryInput?: InputMaybe<CategoryPatchInput>;
  id: Scalars['CategoryUrn']['input'];
  image?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationUpdateClassDraftArgs = {
  id: Scalars['ClassUrn']['input'];
  image?: InputMaybe<Scalars['Upload']['input']>;
  input?: InputMaybe<ClassInput>;
};

export type MutationUpdateCoachByOpsArgs = {
  input: CoachInput;
  slug: Scalars['String']['input'];
};

export type MutationUpdateCoachingCategoryVisibilityArgs = {
  categoryUrn: Scalars['CategoryUrn']['input'];
  visibility: CoachVisibility;
};

export type MutationUpdateCoachingRelationshipArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['CoachingRelationshipUrn']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  packageOverrides?: InputMaybe<Array<MatchedCoachingPackageInput>>;
  tier?: InputMaybe<SkuTier>;
  unitPayoutAmount?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateCoachingSessionArgs = {
  id: Scalars['CoachingSessionUrn']['input'];
  input: CoachingSessionPatchInput;
  message?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateCoachingTimeLogArgs = {
  id: Scalars['CoachingTimeLogUrn']['input'];
  input: CoachingTimeLogInput;
};

export type MutationUpdateCompanyByIdArgs = {
  companyInput?: InputMaybe<CompanyInput>;
  id: Scalars['CompanyUrn']['input'];
  image?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationUpdateCompanyLogoArgs = {
  id: Scalars['CompanyUrn']['input'];
  logo?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationUpdateCustomerGoalArgs = {
  id: Scalars['CustomerGoalUrn']['input'];
  input: CustomerGoalInput;
};

export type MutationUpdateEventDateArgs = {
  id: Scalars['CoachingSessionUrn']['input'];
  startAt: Scalars['Long']['input'];
};

export type MutationUpdateLelandPackageArgs = {
  id: Scalars['PackageUrn']['input'];
  image?: InputMaybe<Scalars['Upload']['input']>;
  input?: InputMaybe<OfferingPackagePatchInput>;
  setActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateLexArgs = {
  input: LexPatchInput;
  slug: Scalars['String']['input'];
};

export type MutationUpdateMatchedCoachArgs = {
  id: Scalars['MatchedCoachUrn']['input'];
  input: MatchedCoachPatchInput;
  packageGlobalOverrides?: InputMaybe<Array<CoachingPackageInput>>;
  sendEmail?: Scalars['Boolean']['input'];
};

export type MutationUpdateMatchedCoachStatusArgs = {
  coachDeclineReason?: InputMaybe<DeclineReason>;
  id: Scalars['MatchedCoachUrn']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  status: MatchedCoachStatus;
};

export type MutationUpdateOfferingPackageArgs = {
  id: Scalars['PackageUrn']['input'];
  image?: InputMaybe<Scalars['Upload']['input']>;
  input?: InputMaybe<OfferingPackagePatchInput>;
  setActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateOrderArgs = {
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  classOrder?: InputMaybe<ClassOrderInput>;
  hourlyOrder?: InputMaybe<HourlyOrderInput>;
  id: Scalars['OrderUrn']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  numberOfCharges?: InputMaybe<Scalars['Int']['input']>;
  packageOrder?: InputMaybe<PackageOrderInput>;
};

export type MutationUpdateOrderStatusArgs = {
  autoPause?: Scalars['Boolean']['input'];
  customerMessage?: InputMaybe<Scalars['String']['input']>;
  declineReason?: InputMaybe<DeclineReason>;
  id: Scalars['OrderUrn']['input'];
  sessionsToCancel?: InputMaybe<Array<Scalars['CoachingSessionUrn']['input']>>;
  status: OrderStatus;
};

export type MutationUpdateSchoolLogoArgs = {
  id: Scalars['SchoolUrn']['input'];
  logo?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationUpdateShindigV2Args = {
  id: Scalars['ShindigUrn']['input'];
  input: ShindigInputV2;
};

export type MutationUpdateSkuArgs = {
  input: SkuInput;
};

export type MutationUpdateStatusByCoachArgs = {
  status: CoachStatus;
};

export type MutationUpdateStatusByOpsArgs = {
  id: Scalars['CoachUrn']['input'];
  override?: Scalars['Boolean']['input'];
  status: CoachStatus;
};

export type MutationUpdateUserArgs = {
  applicant?: InputMaybe<ApplicantInput>;
  coach?: InputMaybe<CoachInput>;
  user?: InputMaybe<UserInput>;
};

export type MutationUpdateVisibilityByCoachArgs = {
  visibility: CoachVisibility;
};

export type MutationUploadProfilePhotoArgs = {
  file: Scalars['Upload']['input'];
};

export type MutationUpsertLlmPromptArgs = {
  id: LlmPromptName;
  input: LlmPromptInput;
};

export type MutationVerifyAndSavePhoneNumberArgs = {
  code: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type NavCategoryListing = {
  __typename?: 'NavCategoryListing';
  categories: Array<Category>;
  goal?: Maybe<GoalName>;
  name: Scalars['String']['output'];
};

export enum Offering {
  AADSAS_APPLICATION_EDIT = 'AADSAS_APPLICATION_EDIT',
  ACHIEVEMENTS = 'ACHIEVEMENTS',
  ACTIVITIES_LIST_EDIT = 'ACTIVITIES_LIST_EDIT',
  AMCAS_APPLICATION_EDIT = 'AMCAS_APPLICATION_EDIT',
  ANALYTICAL_WRITING = 'ANALYTICAL_WRITING',
  APPLICATION_STRATEGY = 'APPLICATION_STRATEGY',
  BEHAVIORAL_INTERVIEW_PREP = 'BEHAVIORAL_INTERVIEW_PREP',
  CASE_INTERVIEW_PREP = 'CASE_INTERVIEW_PREP',
  CASPER_TEST = 'CASPER_TEST',
  CAS_APPLICATION_EDIT = 'CAS_APPLICATION_EDIT',
  DEAL_EXECUTION = 'DEAL_EXECUTION',
  DING_ANALYSIS = 'DING_ANALYSIS',
  DUE_DILIGENCE = 'DUE_DILIGENCE',
  EDITING = 'EDITING',
  ENGLISH = 'ENGLISH',
  ESSAYS = 'ESSAYS',
  EXECUTION = 'EXECUTION',
  EXPERIENCES = 'EXPERIENCES',
  FINANCIAL_MODELING = 'FINANCIAL_MODELING',
  FRAMEWORKS = 'FRAMEWORKS',
  /** @deprecated No longer supported */
  GMAT_TUTORING = 'GMAT_TUTORING',
  /** @deprecated No longer supported */
  GRE_TUTORING = 'GRE_TUTORING',
  INFLUENCE_AND_COLLABORATION = 'INFLUENCE_AND_COLLABORATION',
  INTEGRATED_REASONING = 'INTEGRATED_REASONING',
  INTERVIEWS = 'INTERVIEWS',
  LETTERS_OF_EVALUATION = 'LETTERS_OF_EVALUATION',
  LINKEDIN_REVIEW = 'LINKEDIN_REVIEW',
  MATH = 'MATH',
  MD_PHD_ESSAY = 'MD_PHD_ESSAY',
  MULTIPLE_MINI_INTERVIEW = 'MULTIPLE_MINI_INTERVIEW',
  NETWORKING_STRATEGY = 'NETWORKING_STRATEGY',
  PERSONAL_STATEMENT = 'PERSONAL_STATEMENT',
  PRESENTATIONS = 'PRESENTATIONS',
  PRODUCT_CASE_INTERVIEW_PREP = 'PRODUCT_CASE_INTERVIEW_PREP',
  PROMOTION_STRATEGY = 'PROMOTION_STRATEGY',
  QUANTITATIVE_REASONING = 'QUANTITATIVE_REASONING',
  READING = 'READING',
  RECOMMENDATIONS = 'RECOMMENDATIONS',
  RESUME = 'RESUME',
  RESUME_REVIEW = 'RESUME_REVIEW',
  SALARY_NEGOTIATION = 'SALARY_NEGOTIATION',
  SCHOLARSHIPS = 'SCHOLARSHIPS',
  SCHOOL_SELECTION = 'SCHOOL_SELECTION',
  SCIENCE = 'SCIENCE',
  SECONDARY_ESSAYS = 'SECONDARY_ESSAYS',
  SECONDARY_REVIEW = 'SECONDARY_REVIEW',
  SOURCING = 'SOURCING',
  SPECIALIZATION_STRATEGY = 'SPECIALIZATION_STRATEGY',
  STRATEGY_AND_VISION = 'STRATEGY_AND_VISION',
  TECHNICAL_INTERVIEW_PREP = 'TECHNICAL_INTERVIEW_PREP',
  TMDSAS_APPLICATION_EDIT = 'TMDSAS_APPLICATION_EDIT',
  TRADITIONAL_INTERVIEW = 'TRADITIONAL_INTERVIEW',
  TRANSFER_ADMISSIONS = 'TRANSFER_ADMISSIONS',
  VERBAL_REASONING = 'VERBAL_REASONING',
  WAITLIST_STRATEGY = 'WAITLIST_STRATEGY',
  WORKING_WITH_HEADHUNTERS = 'WORKING_WITH_HEADHUNTERS',
  WRITING = 'WRITING',
}

export type OfferingPackage = {
  __typename?: 'OfferingPackage';
  /** @deprecated No longer supported */
  active: Scalars['Boolean']['output'];
  category?: Maybe<Category>;
  coach: Coach;
  coachingDescription?: Maybe<Scalars['String']['output']>;
  coachingFocusList?: Maybe<Array<CoachingFocus>>;
  /**  epoch in milli-second */
  createdAt: Scalars['Long']['output'];
  defaultOptOut?: Maybe<Scalars['Boolean']['output']>;
  deliverables?: Maybe<Array<Scalars['String']['output']>>;
  deliveryFormat?: Maybe<CoachingDeliveryFormat>;
  description: Scalars['String']['output'];
  id: Scalars['PackageUrn']['output'];
  imageLink?: Maybe<Scalars['String']['output']>;
  isPackageComplete: Scalars['Boolean']['output'];
  isUniversalPackage: Scalars['Boolean']['output'];
  /**  Works only for Leland packages, and this should only be valuable when coach self view */
  lelandPackageOptIn?: Maybe<Scalars['Boolean']['output']>;
  mediaUrn?: Maybe<Scalars['MediaUrn']['output']>;
  name: Scalars['String']['output'];
  /**  Total number of times this package has been purchased (combined singlePrice/tiered purchases) */
  numberPurchased: Scalars['Int']['output'];
  offerings?: Maybe<Array<Offering>>;
  packageTerm?: Maybe<PackageTerm>;
  prices?: Maybe<Array<OfferingPackagePrice>>;
  showOnChooser?: Maybe<Scalars['Boolean']['output']>;
  showOnSrpIfPublic?: Maybe<Scalars['Boolean']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status: PackageStatus;
  subCategories?: Maybe<Array<SubCategory>>;
  subTitle?: Maybe<Scalars['String']['output']>;
  targets?: Maybe<Array<PackageTargetUnion>>;
  version: Scalars['Int']['output'];
  visibility: PackageVisibility;
  visibleTiers?: Maybe<Array<SkuTier>>;
};

export type OfferingPackageInput = {
  categoryUrn: Scalars['CategoryUrn']['input'];
  coachingDescription?: InputMaybe<Scalars['String']['input']>;
  coachingFocusUrns?: InputMaybe<Array<Scalars['CoachingFocusUrn']['input']>>;
  defaultOptOut?: InputMaybe<Scalars['Boolean']['input']>;
  deliverables?: InputMaybe<Array<Scalars['String']['input']>>;
  deliveryFormat?: InputMaybe<CoachingDeliveryFormat>;
  description: Scalars['String']['input'];
  mediaUrn?: InputMaybe<Scalars['MediaUrn']['input']>;
  name: Scalars['String']['input'];
  offerings?: InputMaybe<Array<Offering>>;
  packageTerm?: InputMaybe<PackageTermInput>;
  prices?: InputMaybe<Array<OfferingPackagePriceInput>>;
  showOnChooser?: InputMaybe<Scalars['Boolean']['input']>;
  showOnSrpIfPublic?: InputMaybe<Scalars['Boolean']['input']>;
  status?: PackageStatus;
  subCategoryUrns?: InputMaybe<Array<Scalars['SubCategoryUrn']['input']>>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  targetUrns?: InputMaybe<Array<Scalars['Urn']['input']>>;
  visibility?: PackageVisibility;
  /**  Tiers that this package is visible to. This flag is only applicable for Leland packages */
  visibleTiers?: InputMaybe<Array<SkuTier>>;
};

export type OfferingPackagePatchInput = {
  /**  Only applied if package is in DRAFT state, otherwise ignored */
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  coachingDescription?: InputMaybe<Scalars['String']['input']>;
  coachingFocusUrns?: InputMaybe<Array<Scalars['CoachingFocusUrn']['input']>>;
  defaultOptOut?: InputMaybe<Scalars['Boolean']['input']>;
  deliverables?: InputMaybe<Array<Scalars['String']['input']>>;
  deliveryFormat?: InputMaybe<CoachingDeliveryFormat>;
  description?: InputMaybe<Scalars['String']['input']>;
  mediaUrn?: InputMaybe<Scalars['MediaUrn']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offerings?: InputMaybe<Array<Offering>>;
  packageTerm?: InputMaybe<PackageTermInput>;
  prices?: InputMaybe<Array<OfferingPackagePriceInput>>;
  showOnChooser?: InputMaybe<Scalars['Boolean']['input']>;
  showOnSrpIfPublic?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<PackageStatus>;
  subCategoryUrns?: InputMaybe<Array<Scalars['SubCategoryUrn']['input']>>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  targetUrns?: InputMaybe<Array<Scalars['Urn']['input']>>;
  visibility?: InputMaybe<PackageVisibility>;
  /**  Tiers that this package is visible to. This flag is only applicable for Leland packages */
  visibleTiers?: InputMaybe<Array<SkuTier>>;
};

export type OfferingPackagePrice = {
  __typename?: 'OfferingPackagePrice';
  defaultPayout?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  freeLelandPlusPeriod?: Maybe<TimePeriod>;
  /**  Number of times this tier has been purchased */
  numberPurchased: Scalars['Int']['output'];
  packageCoachingTime?: Maybe<PackageCoachingTime>;
  payout?: Maybe<Scalars['Int']['output']>;
  /**  number in cent */
  price: Scalars['Int']['output'];
  sku?: Maybe<Scalars['String']['output']>;
  tier?: Maybe<SkuTier>;
};

export type OfferingPackagePriceInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  packageCoachingTime?: InputMaybe<PackageCoachingTimeInput>;
  payout?: InputMaybe<Scalars['Int']['input']>;
  /**  number in cent */
  price?: InputMaybe<Scalars['Int']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  tier?: InputMaybe<SkuTier>;
};

export type OfferingPackagePricePatchInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  packageCoachingTime?: InputMaybe<PackageCoachingTimeInput>;
  payout?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  tier?: InputMaybe<SkuTier>;
};

export type OneOnOneSession = {
  __typename?: 'OneOnOneSession';
  introOrder?: Maybe<Order>;
  /** @deprecated Use introOrder instead */
  order?: Maybe<Order>;
  purchaseOrder?: Maybe<Order>;
  title: Scalars['String']['output'];
};

export type OneOnOneSessionInput = {
  orderUrn?: InputMaybe<Scalars['OrderUrn']['input']>;
  purchaseOrderUrn?: InputMaybe<Scalars['OrderUrn']['input']>;
  title: Scalars['String']['input'];
};

export enum OpportunityType {
  /**  New opportunity with no unassigned orders */
  LEAD = 'LEAD',
  /**  New opportunity with unassigned orders, or the assigned order is pending match */
  NEW_CLIENT = 'NEW_CLIENT',
  /**  Subset of NEW_CLIENT, but the order is a trial order */
  TRIAL_SESSION = 'TRIAL_SESSION',
}

export enum OpsPermission {
  OPS_ADMIN = 'OPS_ADMIN',
  OPS_CAN_EDIT_CATEGORY = 'OPS_CAN_EDIT_CATEGORY',
  OPS_CAN_EDIT_LEX = 'OPS_CAN_EDIT_LEX',
  /**  Refunds access */
  OPS_CAN_PROCESS_BILLING = 'OPS_CAN_PROCESS_BILLING',
  OPS_DEBUGGING = 'OPS_DEBUGGING',
  OPS_GENERAL = 'OPS_GENERAL',
}

export type OpsVouchInput = {
  anonymous: Scalars['Boolean']['input'];
  categoryReviews?: InputMaybe<Array<CategoryReviewVouchInput>>;
  coachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  rate: Scalars['Int']['input'];
};

export type Order = {
  __typename?: 'Order';
  /**  number in cent */
  amount?: Maybe<Scalars['Int']['output']>;
  applicant: Applicant;
  approvedAt?: Maybe<Scalars['Long']['output']>;
  cancelReason?: Maybe<CancelReason>;
  category?: Maybe<Category>;
  classOrder?: Maybe<ClassOrder>;
  coach: Coach;
  /**  Intro call sessions only */
  coachingSessions?: Maybe<Array<CoachingSession>>;
  completedAt?: Maybe<Scalars['Long']['output']>;
  /**  amount in cents that is charged from customer separately, e.g., split pay fee */
  convenienceFee?: Maybe<Scalars['Int']['output']>;
  coupon?: Maybe<Coupon>;
  /**  epoch in milli-second */
  createdAt: Scalars['Long']['output'];
  /**  amount in cents, only present if a credit is used for the order */
  creditAmount?: Maybe<Scalars['Int']['output']>;
  /**  amount in cents, only present if a coupon is applied to the order */
  discountAmount?: Maybe<Scalars['Int']['output']>;
  experiment?: Maybe<Scalars['String']['output']>;
  experimentalOrder?: Maybe<ExperimentalOrder>;
  expireAt?: Maybe<Scalars['Long']['output']>;
  expireLedger?: Maybe<Ledger>;
  /**  amount in cents that is deducted from coach's earning */
  fee?: Maybe<Scalars['Int']['output']>;
  /**  number in cent, equals to amount - discountAmount (depending coach/leland) + convenienceFee (only show for applicant) */
  finalAmount?: Maybe<Scalars['Int']['output']>;
  /**  number in cent, this will always be the amount - discountAmount, excluding the convenience fees */
  finalAmountForOps?: Maybe<Scalars['Int']['output']>;
  hourlyOrder?: Maybe<HourlyOrder>;
  id: Scalars['OrderUrn']['output'];
  installment?: Maybe<Installment>;
  introCall?: Maybe<IntroCall>;
  /**  epoch in milli-second */
  lastModifiedAt: Scalars['Long']['output'];
  messageRequest?: Maybe<MessageRequest>;
  /**  optional note for the coach from the applicant. This will get sent as a message via sendbird */
  note?: Maybe<Scalars['String']['output']>;
  numberOfCharges: Scalars['Int']['output'];
  opportunities?: Maybe<Array<MatchedCoach>>;
  packageOrder?: Maybe<OfferingPackage>;
  payoutAmount?: Maybe<Scalars['Int']['output']>;
  /**  For assigned order, this will be the same as `payoutAmount`. For unassigned order, this will be calculated for coach self, and `coachUrn` param will only be honored for ops */
  payoutAmountQuote?: Maybe<Scalars['Int']['output']>;
  payoutLedgers?: Maybe<Array<Ledger>>;
  refunds?: Maybe<Array<OrderRefund>>;
  rejectedAt?: Maybe<Scalars['Long']['output']>;
  remainingPayout?: Maybe<Scalars['Int']['output']>;
  review?: Maybe<Review>;
  standardizedPackageOrder?: Maybe<Scalars['Boolean']['output']>;
  status: OrderStatus;
  /**  Only available from offline orders */
  stripeClientSecret?: Maybe<Scalars['String']['output']>;
  stripeLink?: Maybe<Scalars['String']['output']>;
  submittedAt?: Maybe<Scalars['Long']['output']>;
  subscriptionOrder?: Maybe<SubscriptionOrder>;
};

export type OrderCoachingSessionsArgs = {
  futureOnly?: Scalars['Boolean']['input'];
  status?: InputMaybe<CoachingSessionStatus>;
};

export type OrderPayoutAmountQuoteArgs = {
  coachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
};

export type OrderBatchResponse = {
  __typename?: 'OrderBatchResponse';
  count: Scalars['Int']['output'];
  results: Array<Order>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type OrderEarningResponse = {
  __typename?: 'OrderEarningResponse';
  count: Scalars['Int']['output'];
  metadata: EarningMetadata;
  results: Array<Order>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type OrderRefund = {
  __typename?: 'OrderRefund';
  actor: User;
  amount: Scalars['Int']['output'];
  lelandOnly: Scalars['Boolean']['output'];
  refundAt: Scalars['Long']['output'];
};

export enum OrderStatus {
  /**  The order is approved by the coach, which will be an actual charge */
  APPROVED_BY_COACH = 'APPROVED_BY_COACH',
  /**  The order was authorized by the applicant, but was canceled */
  CANCELED = 'CANCELED',
  /**  Class order successfully booked */
  CLASS_BOOKED = 'CLASS_BOOKED',
  /**  The coaching has completed, this will mark as past client */
  COACHING_COMPLETED = 'COACHING_COMPLETED',
  /**  The order is created */
  CREATED = 'CREATED',
  /**  Intro call request is accepted by coach */
  INTRO_CALL_ACCEPTED = 'INTRO_CALL_ACCEPTED',
  /**  Intro call cancelled, allows re-scheduling intro call */
  INTRO_CALL_CANCELLED = 'INTRO_CALL_CANCELLED',
  /**
   *  Intro call is completed
   * @deprecated new intro call flow 04/2022
   */
  INTRO_CALL_COMPLETED = 'INTRO_CALL_COMPLETED',
  /**  Intro call is dismissed for some reason */
  INTRO_CALL_DISMISSED = 'INTRO_CALL_DISMISSED',
  /**  Intro call request is ignored by coach */
  INTRO_CALL_IGNORED = 'INTRO_CALL_IGNORED',
  /**  Intro call is requested by applicant */
  INTRO_CALL_REQUESTED = 'INTRO_CALL_REQUESTED',
  /**
   *  Intro call is scheduled
   * @deprecated new intro call flow 04/2022
   */
  INTRO_CALL_SCHEDULED = 'INTRO_CALL_SCHEDULED',
  /**  Intro message is dismissed for some reason */
  INTRO_MESSAGE_DISMISSED = 'INTRO_MESSAGE_DISMISSED',
  /**  Intro message is requested by applicant (without intro call) */
  INTRO_MESSAGE_REQUESTED = 'INTRO_MESSAGE_REQUESTED',
  /**  The order is created by coach for customer to pay */
  INVOICING = 'INVOICING',
  /**  Not interested after the intro call */
  NOT_INTERESTED_BY_COACH = 'NOT_INTERESTED_BY_COACH',
  /**  The order has been paid by the applicant, which will be a pending charge */
  PENDING_CHARGE = 'PENDING_CHARGE',
  /**  The order has not matched to or assigned but not accepted by a coach */
  PENDING_MATCH = 'PENDING_MATCH',
  /**  The order was authorized by the applicant and approved by the coach, but later requested a refund */
  REFUNDED = 'REFUNDED',
  /**  The order is rejected by the coach, which will also cancel the pending charge */
  REJECTED_BY_COACH = 'REJECTED_BY_COACH',
  /**  The order is rejected by the customer */
  REJECTED_BY_CUSTOMER = 'REJECTED_BY_CUSTOMER',
}

export type OutcomeSummaryUnion =
  | CareerOutcomeSummary
  | SchoolOutcomeSummary
  | TestOutcomeSummary;

export type PackageCoachingTime = {
  __typename?: 'PackageCoachingTime';
  /**  Must fill out one or the other, but not both. Estimate time is in hours */
  estimate?: Maybe<IntRange>;
  exactTimeInMinute?: Maybe<Scalars['Int']['output']>;
};

export type PackageCoachingTimeInput = {
  /**  Estimate time is in hours */
  estimate?: InputMaybe<IntRangeInput>;
  exactTimeInMinute?: InputMaybe<Scalars['Int']['input']>;
};

export type PackageOrderInput = {
  packageUrn: Scalars['PackageUrn']['input'];
  sku?: InputMaybe<Scalars['String']['input']>;
  tier?: InputMaybe<SkuTier>;
  version: Scalars['Int']['input'];
};

export type PackageSearchResponse = {
  __typename?: 'PackageSearchResponse';
  count: Scalars['Int']['output'];
  metadata: SearchMetadata;
  results: Array<OfferingPackage>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum PackageStatus {
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  LIVE = 'LIVE',
}

export type PackageTargetUnion = Company | School;

export type PackageTerm = {
  __typename?: 'PackageTerm';
  additionalInformation?: Maybe<Scalars['String']['output']>;
  expirationTerm?: Maybe<TimePeriod>;
  refundPolicy: RefundPolicy;
};

export type PackageTermInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  expirationTerm?: InputMaybe<TimePeriodInput>;
  refundPolicy?: RefundPolicy;
};

export enum PackageVisibility {
  PRIVATE = 'PRIVATE',
  /** @deprecated Use PUBLIC instead */
  PROFILE_PUBLIC = 'PROFILE_PUBLIC',
  PUBLIC = 'PUBLIC',
}

export type PaymentCard = {
  __typename?: 'PaymentCard';
  brand: CardBrand;
  defaultCard: Scalars['Boolean']['output'];
  expMonth: Scalars['Int']['output'];
  expYear: Scalars['Int']['output'];
  fundingType: CardFundingType;
  id: Scalars['StripePaymentMethodUrn']['output'];
  /**  The last four digits of the card */
  last4: Scalars['String']['output'];
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  callingCountryCode: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  nationalFormat: Scalars['String']['output'];
};

export type PreSignUpCustomer = {
  __typename?: 'PreSignUpCustomer';
  createdAt: Scalars['Long']['output'];
  email: Scalars['String']['output'];
  newExperience: Scalars['Boolean']['output'];
};

export type PreSignUpCustomerBatchResponse = {
  __typename?: 'PreSignUpCustomerBatchResponse';
  count: Scalars['Int']['output'];
  results: Array<PreSignUpCustomer>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum PreferredPronoun {
  DO_NOT_SPECIFY = 'DO_NOT_SPECIFY',
  HE_HIM = 'HE_HIM',
  SHE_HER = 'SHE_HER',
  THEY_THEM = 'THEY_THEM',
}

export type ProfessionalExperience = {
  __typename?: 'ProfessionalExperience';
  company: Company;
  description?: Maybe<Scalars['String']['output']>;
  endAt?: Maybe<MonthYear>;
  experienceType?: Maybe<ExperienceType>;
  isFeatured?: Maybe<Scalars['Boolean']['output']>;
  jobTitle?: Maybe<JobTitle>;
  startAt: MonthYear;
};

export type ProfessionalExperienceSuggestion = {
  __typename?: 'ProfessionalExperienceSuggestion';
  company?: Maybe<Company>;
  description?: Maybe<Scalars['String']['output']>;
  endAt?: Maybe<MonthYear>;
  jobTitle?: Maybe<JobTitle>;
  startAt?: Maybe<MonthYear>;
};

export type ProfessionalExperiencesInput = {
  companyUrn: Scalars['CompanyUrn']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  endAt?: InputMaybe<MonthYearInput>;
  experienceType?: InputMaybe<ExperienceType>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitleUrn?: InputMaybe<Scalars['JobTitleUrn']['input']>;
  startAt: MonthYearInput;
};

export type Query = {
  __typename?: 'Query';
  /**  Check how much time is needed to purchase to schedule a session. coachUrn or applicantUrn should be provided */
  additionalTimeNeededToSchedule: Scalars['Int']['output'];
  /**  Returns LIVE shindigs */
  allShindigs: ShindigBatchResponse;
  /**  Get applicant by urn */
  applicant?: Maybe<Applicant>;
  /**  Search applicants by query, currently only support search by name or email */
  applicantTypeahead: Array<Applicant>;
  articleSummaries: Array<ArticleSummary>;
  /**  Find previous booked coaches */
  bookedCoaches: CoachBatchResponse;
  /**  Get a category by urn */
  category: Category;
  /**  Gets a category by slug, if it exists. Slug has the format `Department_CategoryName`, camel-case, spaces removed, '&' replaced with "And" */
  categoryBySlug: Category;
  /**  Type ahead for category */
  categoryTypeahead: Array<Category>;
  classDraft: Class;
  classDraftsForCoach: ClassBatchResponse;
  /**  Lists all drafts. Most common application should be listing all READY_FOR_REVIEW classes */
  classDraftsForOps: ClassBatchResponse;
  /**
   *  Find in the client relationship list for the current coach, or for a specific coach if ops
   *  For coach, it will search by first name only. For ops, it would search more by name or email
   */
  clientTypeahead: Array<Applicant>;
  /**  Get coach by urn for coach page, i.e., only viewable coach would be returned */
  coach?: Maybe<Coach>;
  /**  Get coach by slug for coach page, i.e., only viewable coach would be returned */
  coachBySlug?: Maybe<Coach>;
  /**  Vouches and other things need to circumvent ops/coach checks for pre-live coaches */
  coachBySlugForVouch?: Maybe<Coach>;
  /**  Gets the calendar availability for a coach if they set up the integration, otherwise null */
  coachCalendarAvailability?: Maybe<Array<LongRange>>;
  /**  Search coaches by query, currently only support search by name or email or slug */
  coachTypeahead: Array<Coach>;
  /**  Batch get coaches by slugs */
  coachesBySlugs: Array<Coach>;
  /**  Get coaches by status */
  coachesByStatus: CoachBatchResponse;
  coachingFocusTypeahead: Array<CoachingFocus>;
  /**  Get all outcomes by filter criteria */
  coachingOutcomes?: Maybe<Array<CoachingOutcome>>;
  coachingRelationship: CoachingRelationship;
  /**  Find all coaching relationships for current applicant */
  coachingRelationshipsForCurrentApplicant: CoachingRelationshipBatchResponse;
  /**  Find all coaching relationships for current coach */
  coachingRelationshipsForCurrentCoach: CoachingRelationshipBatchResponse;
  coachingSession: CoachingSession;
  /**  Returns an ics file (b64 encoded) or a pre-filled gcal add event link if createIcsFile is false */
  coachingSessionCalendarInvite: Scalars['String']['output'];
  /**  Get all enrollment information for a session */
  coachingSessionEnrollments: Array<CoachingSessionEnrollment>;
  /**  Lists all coaching sessions for the current coach */
  coachingSessionsForCoach: CoachingSessionBatchResponse;
  coachingSessionsForOps: CoachingSessionBatchResponse;
  /**  Find all coaching time logs order by coachedAt. Either coach or applicant must be present */
  coachingTimeLogs: CoachingTimeLogBatchResponse;
  /**  Decorate companies for the given ids. Return list will persist the order based on the input */
  companiesByIds: Array<Company>;
  /**  Type ahead for company */
  companyTypeahead: Array<Company>;
  /**  Get coupon by code, which also belongs to the calling applicant or coach */
  couponByCode?: Maybe<Coupon>;
  /**  Get all coupons created by the coach */
  couponsCreated: CouponBatchResponse;
  /**  Return the list of credit history for the authed customer */
  creditHistories: Array<CustomerCredit>;
  customerSubscriptions: Array<CustomerSubscription>;
  /**  Gets a department by slug, if it exists. Slug is camel-case, spaces removed, '&' replaced with "And" by default (unless another was provided at creation) */
  departmentBySlug: Department;
  departmentTypeahead: Array<Department>;
  /**  Get the departments associated with a given goal */
  departmentsForGoal: Array<Department>;
  earningsForCoach: OrderEarningResponse;
  earningsForOps: OrderEarningResponse;
  /**  Return the email in Leland record. It would throw as 404 if the email does not exist in Leland */
  emailInLeland: Scalars['String']['output'];
  enoughTimeToSchedule: Scalars['Boolean']['output'];
  /**  Estimate the payout for the time that is about to be logged */
  estimateCoachingTimeLogPayout: Scalars['Int']['output'];
  /**  Check to see whether the feature has been interacted. When `since` is not passed, it would check lifetime records. */
  featureInteracted: Scalars['Boolean']['output'];
  featuredReviews: Array<Review>;
  /** @deprecated No longer supported */
  findSku: Sku;
  findSkuBySession: Sku;
  findSkuByTime: Sku;
  /**  Type ahead for geolocations */
  geoLocationTypeahead: Array<GeoLocation>;
  /**  Get all categories. This is useful while we don't have very many, but we will likely phase this out later for the normal searchCategories command */
  getAllCategories: Array<Category>;
  /**  Gets all live coaches. This is meant to mostly just be used by the sitemap. If no visibility set, returns all visibilities */
  getLiveCoaches: CoachBatchResponse;
  groupClass: Class;
  groupClassBySlug: Class;
  /**  Lists all non-archived (deleted) classes. If no status is given, returns both DISCOVERY and APPROVED classes */
  groupClassesForCoach: ClassBatchResponse;
  /**  Lists all group classes. If no status is given, returns both DISCOVERY and APPROVED classes */
  groupClassesForOps: ClassBatchResponse;
  /**  Don't use me, here just to make this enum show up in FE gql */
  industryBackgrounds: Array<IndustryBackground>;
  isRedeemCodeValid: Scalars['Boolean']['output'];
  /**  Type ahead for job titles */
  jobTitleTypeahead: Array<JobTitle>;
  /**  Type ahead for languages */
  languageTypeahead: Array<Scalars['String']['output']>;
  /**  Find latest order for the given sku */
  latestOrderBySku: Order;
  /**  Get all leland coupons created by the ops */
  lelandCoupons: CouponBatchResponse;
  /**  Return all possible lelandErrorCodes */
  lelandErrorCodes: Array<LelandErrorCode>;
  lelandPackages: PackageSearchResponse;
  /**  Get List of coaches who contributes to Leland+. Note that, `count` is hardcoded to 4 in the backend logic */
  lelandPlusContributors: CoachBatchResponse;
  /**  Return a specific lex for ops, which will hit DB for instant result */
  lexForOps: Lex;
  /**  Examine Lex by slugs, Lex value may have up to 5 min delay. Note that lex key is case-insensitive */
  lexListBySlugs: Array<Lex>;
  /**  Get all lex list */
  lexListForOps: LexBatchResponse;
  /**  email is the base calendarId for the calendar connection this is looking up */
  listConnectedCalendars?: Maybe<Array<ConnectedCalendar>>;
  llmPrompt: LlmPrompt;
  llmPrompts: Array<LlmPrompt>;
  /**  return match between the applicant and coach. This only works for self view or ops */
  match: MatchedCoach;
  /**  Find closest matched skus by time. If tier is not provided, it will return one sku per tier */
  matchSkusByTime: Array<Sku>;
  matchedCoachesForCoach: MatchedCoachBatchResponse;
  matchedCoachesForOps: MatchedCoachBatchResponse;
  navCategories: Array<NavCategoryListing>;
  /**  Get the next payout date */
  nextPayoutDate: Scalars['Long']['output'];
  /**  Get package in its latest version by urn. Note that, for non-owner, only LIVE package will be returned. */
  offeringPackage?: Maybe<OfferingPackage>;
  offeringPackageBySlug?: Maybe<OfferingPackage>;
  /**  Batch get packages in its latest version by urns. Note that, for non-owner, only LIVE packages will be returned. */
  offeringPackages: Array<OfferingPackage>;
  /**  Return all eligible offerings/services for the given category */
  offeringsForCategory?: Maybe<Array<Offering>>;
  opsUsers: Array<User>;
  /**  Get order by urn */
  order?: Maybe<Order>;
  /**  Find all class orders using the given coaching session urn */
  orderByClassSessionV2?: Maybe<Order>;
  /**  Get the estimate for the order amount. This only contains amount, discountAmount, and finalAmount values, as well as order fields passed in. No other values (even ! fields) are guaranteed */
  orderEstimate: Order;
  /**  Find all orders by the current login coach */
  ordersByApplicant: OrderBatchResponse;
  /**  Find all orders by the current login applicant */
  ordersByCoach: OrderBatchResponse;
  /**  Find all orders using the given coupon urn */
  ordersByCoupon: OrderBatchResponse;
  /**  Find all orders for the given coach/customer by ops */
  ordersByOps: OrderBatchResponse;
  originalLink: Scalars['String']['output'];
  /**  Get all saved cards for the current login applicant */
  paymentCards: Array<PaymentCard>;
  preSignUpCustomers: PreSignUpCustomerBatchResponse;
  publicOpportunities: MatchedCoachBatchResponse;
  recentlyUpdatedCompanies: CompanyBatchResponse;
  recentlyUpdatedSchools: SchoolBatchResponse;
  /**  Recommend coaches with GPT. If inputs are not specified, it will fallback to use what's stored in DB */
  recommendedCoaches: Array<CoachRecommendation>;
  recommendedCoachesV2: Scalars['String']['output'];
  /**  Get recording links for session */
  recordingsForSession: Array<Scalars['String']['output']>;
  /**  Get all reviews created by the applicant */
  reviews: ReviewBatchResponse;
  /**  Get all reviews for the coach */
  reviewsForCoach: ReviewBatchResponse;
  /**  Find all reviews for ops */
  reviewsForOpsV2: ReviewBatchResponse;
  reviewsForPackage: ReviewBatchResponse;
  /**  Type ahead for school */
  schoolTypeahead: Array<School>;
  /**  Decorate schools for the given ids. Return list will persist the order based on the input */
  schoolsByIds: Array<School>;
  searchApplicants: ApplicantBatchResponse;
  /**  search for categories with certain filters */
  searchCategories: CategoryBatchResponse;
  searchClasses: ClassBatchResponse;
  searchClassesAndShindigs: ClassShindigBatchResponse;
  /**  search for coaches with certain filters */
  searchCoaches: CoachSearchResponse;
  /**  search for packages */
  searchPackages: PackageSearchResponse;
  shindig: Shindig;
  /**  Returns all shindigs for ops */
  shindigsForOps: ShindigBatchResponse;
  sku: Sku;
  skus: SkuBatchResponse;
  subCategoryTypeahead: Array<SubCategory>;
  /**  Guess the best category matches for the given keyword using AI embedding */
  suggestCategories: Array<Category>;
  /**  Return all available timeZones */
  timeZones: Array<TimeZone>;
  totalReviews: Scalars['Long']['output'];
  /**  Return typeform response */
  typeformResponse: Array<KeyValuePair>;
  /**  Find all unassigned orders for the current customer. Note that it cannot take both coachUrn and tier */
  unassignedOrders: Array<Order>;
  /**  Get current user information */
  user?: Maybe<User>;
  /**  Return decorated video link */
  videoLink: VideoLink;
};

export type QueryAdditionalTimeNeededToScheduleArgs = {
  applicantUrn?: InputMaybe<Scalars['ApplicantUrn']['input']>;
  coachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
  endAt: Scalars['Long']['input'];
  excludeSessions?: Array<Scalars['CoachingSessionUrn']['input']>;
  startAt: Scalars['Long']['input'];
};

export type QueryAllShindigsArgs = {
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  categoryUrns?: InputMaybe<Array<Scalars['CategoryUrn']['input']>>;
  count?: Scalars['Int']['input'];
  sortOrder?: SortOrder;
  start?: Scalars['Int']['input'];
  subCategoryUrn?: InputMaybe<Scalars['SubCategoryUrn']['input']>;
  subCategoryUrns?: InputMaybe<Array<Scalars['SubCategoryUrn']['input']>>;
  timeFilter?: TimeFilter;
};

export type QueryApplicantArgs = {
  id: Scalars['ApplicantUrn']['input'];
};

export type QueryApplicantTypeaheadArgs = {
  count?: Scalars['Int']['input'];
  query: Scalars['String']['input'];
};

export type QueryBookedCoachesArgs = {
  count?: Scalars['Int']['input'];
  start?: Scalars['Int']['input'];
};

export type QueryCategoryArgs = {
  id: Scalars['CategoryUrn']['input'];
};

export type QueryCategoryBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryCategoryTypeaheadArgs = {
  count?: Scalars['Int']['input'];
  featuredOnly?: Scalars['Boolean']['input'];
  goal?: InputMaybe<GoalName>;
  hasActiveCoachOnly?: Scalars['Boolean']['input'];
  query: Scalars['String']['input'];
  start?: Scalars['Int']['input'];
};

export type QueryClassDraftArgs = {
  id: Scalars['ClassUrn']['input'];
};

export type QueryClassDraftsForCoachArgs = {
  count?: Scalars['Int']['input'];
  isFreeEvent?: InputMaybe<Scalars['Boolean']['input']>;
  start?: Scalars['Int']['input'];
  status?: InputMaybe<Array<ClassStatus>>;
};

export type QueryClassDraftsForOpsArgs = {
  coachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
  count?: Scalars['Int']['input'];
  hasPublishedVersion?: InputMaybe<Scalars['Boolean']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isFreeEvent?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  start?: Scalars['Int']['input'];
  status?: InputMaybe<Array<ClassStatus>>;
};

export type QueryClientTypeaheadArgs = {
  coachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
  count?: Scalars['Int']['input'];
  query: Scalars['String']['input'];
};

export type QueryCoachArgs = {
  id: Scalars['CoachUrn']['input'];
};

export type QueryCoachBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryCoachBySlugForVouchArgs = {
  slug: Scalars['String']['input'];
};

export type QueryCoachCalendarAvailabilityArgs = {
  coachUrn: Scalars['CoachUrn']['input'];
  dateRange?: InputMaybe<LongRangeInput>;
  minRangeInMinutes?: Scalars['Int']['input'];
};

export type QueryCoachTypeaheadArgs = {
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  count?: Scalars['Int']['input'];
  query: Scalars['String']['input'];
  status?: InputMaybe<CoachStatus>;
  trialSessionOptIn?: Scalars['Boolean']['input'];
};

export type QueryCoachesBySlugsArgs = {
  slugs: Array<Scalars['String']['input']>;
};

export type QueryCoachesByStatusArgs = {
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  count?: Scalars['Int']['input'];
  lelandPlusContributorOnly?: Scalars['Boolean']['input'];
  rankingAdjustedOnly?: Scalars['Boolean']['input'];
  start?: Scalars['Int']['input'];
  status?: InputMaybe<CoachStatus>;
};

export type QueryCoachingFocusTypeaheadArgs = {
  count?: Scalars['Int']['input'];
  query: Scalars['String']['input'];
  start?: Scalars['Int']['input'];
};

export type QueryCoachingOutcomesArgs = {
  applicantUrn?: InputMaybe<Scalars['ApplicantUrn']['input']>;
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  coachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
  fromCurrentUserOnly?: Scalars['Boolean']['input'];
  goal?: InputMaybe<GoalName>;
};

export type QueryCoachingRelationshipArgs = {
  id: Scalars['CoachingRelationshipUrn']['input'];
};

export type QueryCoachingRelationshipsForCurrentApplicantArgs = {
  count?: Scalars['Int']['input'];
  start?: Scalars['Int']['input'];
  statuses?: InputMaybe<Array<CoachingRelationshipStatus>>;
  withoutReviewOnly?: Scalars['Boolean']['input'];
};

export type QueryCoachingRelationshipsForCurrentCoachArgs = {
  archivedByCoach?: InputMaybe<Scalars['Boolean']['input']>;
  blockedByCoach?: InputMaybe<Scalars['Boolean']['input']>;
  count?: Scalars['Int']['input'];
  start?: Scalars['Int']['input'];
  statuses?: InputMaybe<Array<CoachingRelationshipStatus>>;
  withoutReviewOnly?: Scalars['Boolean']['input'];
};

export type QueryCoachingSessionArgs = {
  id: Scalars['CoachingSessionUrn']['input'];
};

export type QueryCoachingSessionCalendarInviteArgs = {
  createIcsFile?: Scalars['Boolean']['input'];
  id: Scalars['CoachingSessionUrn']['input'];
};

export type QueryCoachingSessionEnrollmentsArgs = {
  id: Scalars['CoachingSessionUrn']['input'];
};

export type QueryCoachingSessionsForCoachArgs = {
  classUrn?: InputMaybe<Scalars['ClassUrn']['input']>;
  count?: Scalars['Int']['input'];
  dateRange?: InputMaybe<LongRangeInput>;
  filterByEndAt?: Scalars['Boolean']['input'];
  sessionType?: InputMaybe<CoachingSessionType>;
  sortOrder?: SortOrder;
  start?: Scalars['Int']['input'];
};

export type QueryCoachingSessionsForOpsArgs = {
  classUrn?: InputMaybe<Scalars['ClassUrn']['input']>;
  coachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
  count?: Scalars['Int']['input'];
  dateRange?: InputMaybe<LongRangeInput>;
  filterByEndAt?: Scalars['Boolean']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  percentAvailable?: Scalars['Float']['input'];
  sessionType?: InputMaybe<CoachingSessionType>;
  sortOrder?: SortOrder;
  start?: Scalars['Int']['input'];
};

export type QueryCoachingTimeLogsArgs = {
  applicantUrn?: InputMaybe<Scalars['ApplicantUrn']['input']>;
  coachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
  count?: Scalars['Int']['input'];
  start?: Scalars['Int']['input'];
};

export type QueryCompaniesByIdsArgs = {
  ids: Array<Scalars['CompanyUrn']['input']>;
};

export type QueryCompanyTypeaheadArgs = {
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  categoryUrns?: InputMaybe<Array<Scalars['CategoryUrn']['input']>>;
  count?: Scalars['Int']['input'];
  onlyUseDB?: Scalars['Boolean']['input'];
  query: Scalars['String']['input'];
  start?: Scalars['Int']['input'];
};

export type QueryCouponByCodeArgs = {
  code: Scalars['String']['input'];
};

export type QueryCouponsCreatedArgs = {
  count?: Scalars['Int']['input'];
  start?: Scalars['Int']['input'];
};

export type QueryCustomerSubscriptionsArgs = {
  coachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
  count?: Scalars['Int']['input'];
  includePendingForCoach?: Scalars['Boolean']['input'];
  skuUrn?: InputMaybe<Scalars['SkuUrn']['input']>;
  start?: Scalars['Int']['input'];
  statuses?: InputMaybe<Array<CustomerSubscriptionStatus>>;
};

export type QueryDepartmentBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryDepartmentTypeaheadArgs = {
  count?: Scalars['Int']['input'];
  goal?: InputMaybe<GoalName>;
  query: Scalars['String']['input'];
  start?: Scalars['Int']['input'];
};

export type QueryDepartmentsForGoalArgs = {
  activeOnly?: Scalars['Boolean']['input'];
  goal: GoalName;
};

export type QueryEarningsForCoachArgs = {
  applicantUrn?: InputMaybe<Scalars['ApplicantUrn']['input']>;
  count?: Scalars['Int']['input'];
  start?: Scalars['Int']['input'];
};

export type QueryEarningsForOpsArgs = {
  applicantUrn?: InputMaybe<Scalars['ApplicantUrn']['input']>;
  coachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
  count?: Scalars['Int']['input'];
  start?: Scalars['Int']['input'];
};

export type QueryEmailInLelandArgs = {
  email: Scalars['String']['input'];
};

export type QueryEnoughTimeToScheduleArgs = {
  coachUrn: Scalars['CoachUrn']['input'];
  endAt: Scalars['Long']['input'];
  startAt: Scalars['Long']['input'];
};

export type QueryEstimateCoachingTimeLogPayoutArgs = {
  applicantUrn: Scalars['ApplicantUrn']['input'];
  timeUsedInMinute: Scalars['Int']['input'];
};

export type QueryFeatureInteractedArgs = {
  feature: Scalars['String']['input'];
  maxInitialInteraction?: Scalars['Int']['input'];
  maxPostCoolOffInteraction?: Scalars['Int']['input'];
  since?: InputMaybe<Scalars['Long']['input']>;
};

export type QueryFeaturedReviewsArgs = {
  shuffle?: Scalars['Boolean']['input'];
};

export type QueryFindSkuArgs = {
  family?: SkuFamily;
  numberOfSessions?: InputMaybe<Scalars['Int']['input']>;
  period: SubscriptionInterval;
  tier: SkuTier;
  timeInMinutes?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryFindSkuBySessionArgs = {
  family?: SkuFamily;
  numberOfSessions: Scalars['Int']['input'];
  period: SubscriptionInterval;
  tier: SkuTier;
};

export type QueryFindSkuByTimeArgs = {
  family?: SkuFamily;
  period: SubscriptionInterval;
  tier: SkuTier;
  timeInMinutes: Scalars['Int']['input'];
};

export type QueryGeoLocationTypeaheadArgs = {
  count?: Scalars['Int']['input'];
  query: Scalars['String']['input'];
  start?: Scalars['Int']['input'];
};

export type QueryGetLiveCoachesArgs = {
  count?: Scalars['Int']['input'];
  start?: Scalars['Int']['input'];
  visibility?: InputMaybe<Array<CoachVisibility>>;
};

export type QueryGroupClassArgs = {
  id: Scalars['ClassUrn']['input'];
};

export type QueryGroupClassBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryGroupClassesForCoachArgs = {
  count?: Scalars['Int']['input'];
  start?: Scalars['Int']['input'];
  status?: InputMaybe<ClassStatus>;
};

export type QueryGroupClassesForOpsArgs = {
  coachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
  count?: Scalars['Int']['input'];
  daysSinceLastUpdate?: InputMaybe<Scalars['Int']['input']>;
  discoveryListSize?: InputMaybe<Scalars['Int']['input']>;
  hasUpcomingSessions?: InputMaybe<Scalars['Boolean']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  start?: Scalars['Int']['input'];
  status?: InputMaybe<ClassStatus>;
};

export type QueryIsRedeemCodeValidArgs = {
  code: Scalars['String']['input'];
};

export type QueryJobTitleTypeaheadArgs = {
  count?: Scalars['Int']['input'];
  query: Scalars['String']['input'];
  start?: Scalars['Int']['input'];
};

export type QueryLanguageTypeaheadArgs = {
  count?: Scalars['Int']['input'];
  query: Scalars['String']['input'];
  start?: Scalars['Int']['input'];
};

export type QueryLatestOrderBySkuArgs = {
  skuUrn: Scalars['SkuUrn']['input'];
};

export type QueryLelandCouponsArgs = {
  count?: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  start?: Scalars['Int']['input'];
};

export type QueryLelandPackagesArgs = {
  applicantUrn?: InputMaybe<Scalars['ApplicantUrn']['input']>;
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  categoryUrns?: InputMaybe<Array<Scalars['CategoryUrn']['input']>>;
  coachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
  optInOnly?: Scalars['Boolean']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  srpView?: Scalars['Boolean']['input'];
  status?: InputMaybe<PackageStatus>;
  tier?: InputMaybe<SkuTier>;
  visibilities?: InputMaybe<Array<PackageVisibility>>;
  visibility?: InputMaybe<PackageVisibility>;
  visibleTierOnly?: Scalars['Boolean']['input'];
};

export type QueryLelandPlusContributorsArgs = {
  category: Scalars['CategoryUrn']['input'];
};

export type QueryLexForOpsArgs = {
  slug: Scalars['String']['input'];
};

export type QueryLexListBySlugsArgs = {
  slugs: Array<Scalars['String']['input']>;
};

export type QueryLexListForOpsArgs = {
  activeOnly?: Scalars['Boolean']['input'];
  count?: Scalars['Int']['input'];
  start?: Scalars['Int']['input'];
};

export type QueryListConnectedCalendarsArgs = {
  email: Scalars['String']['input'];
};

export type QueryLlmPromptArgs = {
  id: LlmPromptName;
};

export type QueryMatchArgs = {
  applicantUrn: Scalars['ApplicantUrn']['input'];
  coachUrn: Scalars['CoachUrn']['input'];
};

export type QueryMatchSkusByTimeArgs = {
  family?: SkuFamily;
  period?: SubscriptionInterval;
  tier?: InputMaybe<SkuTier>;
  timeInMinute: Scalars['Int']['input'];
};

export type QueryMatchedCoachesForCoachArgs = {
  count?: Scalars['Int']['input'];
  showPending?: Scalars['Boolean']['input'];
  start?: Scalars['Int']['input'];
  status?: InputMaybe<MatchedCoachStatus>;
};

export type QueryMatchedCoachesForOpsArgs = {
  applicantUrn?: InputMaybe<Scalars['ApplicantUrn']['input']>;
  categoryUrns?: InputMaybe<Array<Scalars['CategoryUrn']['input']>>;
  coachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
  count?: Scalars['Int']['input'];
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  opsRequestsOnly?: Scalars['Boolean']['input'];
  start?: Scalars['Int']['input'];
  status?: InputMaybe<MatchedCoachStatus>;
  tiers?: InputMaybe<Array<SkuTier>>;
  trialCoachRequestsOnly?: Scalars['Boolean']['input'];
  type?: InputMaybe<OpportunityType>;
};

export type QueryOfferingPackageArgs = {
  id: Scalars['PackageUrn']['input'];
  tier?: InputMaybe<SkuTier>;
  visibleTierOnly?: Scalars['Boolean']['input'];
};

export type QueryOfferingPackageBySlugArgs = {
  coach: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  tier?: InputMaybe<SkuTier>;
  visibleTierOnly?: Scalars['Boolean']['input'];
};

export type QueryOfferingPackagesArgs = {
  ids: Array<Scalars['PackageUrn']['input']>;
  tier?: InputMaybe<SkuTier>;
  visibleTierOnly?: Scalars['Boolean']['input'];
};

export type QueryOfferingsForCategoryArgs = {
  category: Scalars['CategoryUrn']['input'];
};

export type QueryOrderArgs = {
  id: Scalars['OrderUrn']['input'];
};

export type QueryOrderByClassSessionV2Args = {
  coachingSessionUrn: Scalars['CoachingSessionUrn']['input'];
};

export type QueryOrderEstimateArgs = {
  classOrder?: InputMaybe<ClassOrderInput>;
  coachUrn: Scalars['CoachUrn']['input'];
  couponCode?: InputMaybe<Scalars['String']['input']>;
  hourlyOrder?: InputMaybe<HourlyOrderInput>;
  packageOrder?: InputMaybe<PackageOrderInput>;
};

export type QueryOrdersByApplicantArgs = {
  applicant?: InputMaybe<Scalars['ApplicantUrn']['input']>;
  count?: Scalars['Int']['input'];
  standardizedPackageOrderOnly?: Scalars['Boolean']['input'];
  start?: Scalars['Int']['input'];
  statusSet?: InputMaybe<Array<OrderStatus>>;
};

export type QueryOrdersByCoachArgs = {
  coach?: InputMaybe<Scalars['CoachUrn']['input']>;
  count?: Scalars['Int']['input'];
  standardizedPackageOrderOnly?: Scalars['Boolean']['input'];
  start?: Scalars['Int']['input'];
  statusSet?: InputMaybe<Array<OrderStatus>>;
};

export type QueryOrdersByCouponArgs = {
  count?: Scalars['Int']['input'];
  couponUrn: Scalars['CouponUrn']['input'];
  start?: Scalars['Int']['input'];
};

export type QueryOrdersByOpsArgs = {
  applicant?: InputMaybe<Scalars['ApplicantUrn']['input']>;
  categoryUrns?: InputMaybe<Array<Scalars['CategoryUrn']['input']>>;
  coach?: InputMaybe<Scalars['CoachUrn']['input']>;
  count?: Scalars['Int']['input'];
  excludeCoaches?: InputMaybe<Array<Scalars['CoachUrn']['input']>>;
  standardizedPackageOrderOnly?: Scalars['Boolean']['input'];
  start?: Scalars['Int']['input'];
  statusSet?: InputMaybe<Array<OrderStatus>>;
};

export type QueryOriginalLinkArgs = {
  id: Scalars['String']['input'];
};

export type QueryPreSignUpCustomersArgs = {
  count?: Scalars['Int']['input'];
  start?: Scalars['Int']['input'];
};

export type QueryPublicOpportunitiesArgs = {
  alwaysIncludeTrialOpportunities?: Scalars['Boolean']['input'];
  categoryUrns?: InputMaybe<Array<Scalars['CategoryUrn']['input']>>;
  coachCategoriesOnly?: Scalars['Boolean']['input'];
  count?: Scalars['Int']['input'];
  start?: Scalars['Int']['input'];
  tiers?: InputMaybe<Array<SkuTier>>;
  type?: InputMaybe<OpportunityType>;
};

export type QueryRecentlyUpdatedCompaniesArgs = {
  count?: Scalars['Int']['input'];
  start?: Scalars['Int']['input'];
};

export type QueryRecentlyUpdatedSchoolsArgs = {
  count?: Scalars['Int']['input'];
  start?: Scalars['Int']['input'];
};

export type QueryRecommendedCoachesArgs = {
  category?: InputMaybe<Scalars['CategoryUrn']['input']>;
  questionnaire?: InputMaybe<ApplicantQuestionnaireInput>;
};

export type QueryRecommendedCoachesV2Args = {
  categoryUrns?: InputMaybe<Array<Scalars['CategoryUrn']['input']>>;
  request: Scalars['String']['input'];
};

export type QueryRecordingsForSessionArgs = {
  id: Scalars['CoachingSessionUrn']['input'];
};

export type QueryReviewsArgs = {
  count?: Scalars['Int']['input'];
  start?: Scalars['Int']['input'];
};

export type QueryReviewsForCoachArgs = {
  categoryUrnFilter?: InputMaybe<Scalars['CategoryUrn']['input']>;
  categoryUrnSort?: InputMaybe<Scalars['CategoryUrn']['input']>;
  count?: Scalars['Int']['input'];
  id: Scalars['CoachUrn']['input'];
  sortBy?: ReviewSortBy;
  sortOrder?: SortOrder;
  start?: Scalars['Int']['input'];
  type?: InputMaybe<ReviewType>;
};

export type QueryReviewsForOpsV2Args = {
  coachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
  count?: Scalars['Int']['input'];
  featuredOnly?: Scalars['Boolean']['input'];
  newOnly?: Scalars['Boolean']['input'];
  reviewType?: InputMaybe<ReviewType>;
  start?: Scalars['Int']['input'];
};

export type QueryReviewsForPackageArgs = {
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  count?: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['PackageUrn']['input']>;
  sortBy?: ReviewSortBy;
  sortOrder?: SortOrder;
  start?: Scalars['Int']['input'];
  type?: InputMaybe<ReviewType>;
};

export type QuerySchoolTypeaheadArgs = {
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  categoryUrns?: InputMaybe<Array<Scalars['CategoryUrn']['input']>>;
  count?: Scalars['Int']['input'];
  query: Scalars['String']['input'];
  start?: Scalars['Int']['input'];
};

export type QuerySchoolsByIdsArgs = {
  ids: Array<Scalars['SchoolUrn']['input']>;
};

export type QuerySearchApplicantsArgs = {
  count?: Scalars['Int']['input'];
  matchesPending?: InputMaybe<Scalars['Boolean']['input']>;
  newExperience?: InputMaybe<Scalars['Boolean']['input']>;
  start?: Scalars['Int']['input'];
};

export type QuerySearchCategoriesArgs = {
  activeCoachFilter?: InputMaybe<Scalars['Boolean']['input']>;
  count?: Scalars['Int']['input'];
  departmentsFilter?: InputMaybe<Array<Scalars['DepartmentUrn']['input']>>;
  featuredFilter?: InputMaybe<Scalars['Boolean']['input']>;
  goalsFilter?: InputMaybe<Array<GoalName>>;
  sortOrder?: InputMaybe<SortOrder>;
  start?: Scalars['Int']['input'];
};

export type QuerySearchClassesArgs = {
  categoryFilter?: InputMaybe<Array<Scalars['CategoryUrn']['input']>>;
  coachFilter?: InputMaybe<Array<Scalars['CoachUrn']['input']>>;
  count?: Scalars['Int']['input'];
  excludeCategories?: InputMaybe<Array<Scalars['CategoryUrn']['input']>>;
  freeEvent?: InputMaybe<Scalars['Boolean']['input']>;
  goalFilter?: InputMaybe<GoalName>;
  hasOpenSessions?: InputMaybe<Scalars['Boolean']['input']>;
  seatsAvailableFilter?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<ClassSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  start?: Scalars['Int']['input'];
  timeFilter?: TimeFilter;
};

export type QuerySearchClassesAndShindigsArgs = {
  categoryFilter?: InputMaybe<Array<Scalars['CategoryUrn']['input']>>;
  coachFilter?: InputMaybe<Array<Scalars['CoachUrn']['input']>>;
  count?: Scalars['Int']['input'];
  excludeCategories?: InputMaybe<Array<Scalars['CategoryUrn']['input']>>;
  freeEvent?: InputMaybe<Scalars['Boolean']['input']>;
  goalFilter?: InputMaybe<GoalName>;
  seatsAvailableFilter?: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['Long']['input'];
  subCategoryUrns?: InputMaybe<Array<Scalars['SubCategoryUrn']['input']>>;
  timeFilter?: TimeFilter;
};

export type QuerySearchCoachesArgs = {
  categoryUrn?: InputMaybe<Scalars['CategoryUrn']['input']>;
  coachingFocusListFilter?: InputMaybe<
    Array<Scalars['CoachingFocusUrn']['input']>
  >;
  companyFilter?: InputMaybe<Array<Scalars['CompanyUrn']['input']>>;
  count?: Scalars['Int']['input'];
  excludeLotteryCoaches?: Scalars['Boolean']['input'];
  goalsFilter?: InputMaybe<Array<GoalName>>;
  hasAdmissionCommitteeFilter?: InputMaybe<Scalars['Boolean']['input']>;
  hasInternationalServiceFilter?: InputMaybe<Scalars['Boolean']['input']>;
  hasPackageFilter?: InputMaybe<Scalars['Boolean']['input']>;
  hourlyRateFilter?: InputMaybe<IntRangeInput>;
  idsToExclude?: InputMaybe<Array<Scalars['CoachUrn']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  offeringsFilter?: InputMaybe<Array<Offering>>;
  professionalCoachFilter?: InputMaybe<Scalars['Boolean']['input']>;
  reviewRateFilter?: InputMaybe<IntRangeInput>;
  schoolsFilter?: InputMaybe<Array<Scalars['SchoolUrn']['input']>>;
  skuTiers?: InputMaybe<Array<SkuTier>>;
  sortBy?: InputMaybe<CoachSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  specialBackgroundsFilter?: InputMaybe<Array<SpecialBackground>>;
  start?: Scalars['Int']['input'];
  subCategoryListFilter?: InputMaybe<Array<Scalars['SubCategoryUrn']['input']>>;
};

export type QuerySearchPackagesArgs = {
  categoryUrn: Scalars['CategoryUrn']['input'];
  coachesFilter?: InputMaybe<Array<Scalars['CoachUrn']['input']>>;
  coachingFocusListFilter?: InputMaybe<
    Array<Scalars['CoachingFocusUrn']['input']>
  >;
  companiesFilter?: InputMaybe<Array<Scalars['CompanyUrn']['input']>>;
  companiesSort?: InputMaybe<Array<Scalars['CompanyUrn']['input']>>;
  count?: Scalars['Int']['input'];
  idsToExclude?: InputMaybe<Array<Scalars['PackageUrn']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  offeringsFilter?: InputMaybe<Array<Offering>>;
  priceFilter?: InputMaybe<IntRangeInput>;
  schoolsFilter?: InputMaybe<Array<Scalars['SchoolUrn']['input']>>;
  schoolsSort?: InputMaybe<Array<Scalars['SchoolUrn']['input']>>;
  sortBy?: InputMaybe<CoachSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  start?: Scalars['Int']['input'];
  subCategoriesFilter?: InputMaybe<Array<Scalars['SubCategoryUrn']['input']>>;
};

export type QueryShindigArgs = {
  id: Scalars['ShindigUrn']['input'];
};

export type QueryShindigsForOpsArgs = {
  count?: Scalars['Int']['input'];
  sortOrder?: SortOrder;
  start?: Scalars['Int']['input'];
  statusSet?: Array<ShindigStatus>;
  timeFilter?: TimeFilter;
};

export type QuerySkuArgs = {
  id: Scalars['SkuUrn']['input'];
};

export type QuerySkusArgs = {
  count?: Scalars['Int']['input'];
  family?: SkuFamily;
  numberOfSessions?: InputMaybe<Scalars['Int']['input']>;
  period?: InputMaybe<SubscriptionInterval>;
  query?: InputMaybe<Scalars['String']['input']>;
  start?: Scalars['Int']['input'];
  tier?: InputMaybe<SkuTier>;
  timeInMinutes?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerySubCategoryTypeaheadArgs = {
  count?: Scalars['Int']['input'];
  query: Scalars['String']['input'];
  start?: Scalars['Int']['input'];
};

export type QuerySuggestCategoriesArgs = {
  count?: Scalars['Int']['input'];
  keyword: Scalars['String']['input'];
};

export type QueryTypeformResponseArgs = {
  fields: Array<Scalars['String']['input']>;
  formId: Scalars['String']['input'];
  responseId: Scalars['String']['input'];
};

export type QueryUnassignedOrdersArgs = {
  coachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
  tier?: InputMaybe<SkuTier>;
};

export type QueryVideoLinkArgs = {
  input: VideoLinkInput;
};

export type RankingAdjustment = {
  __typename?: 'RankingAdjustment';
  /**  categories that the ranking adjustment applies. if null or empty, it means applying to all */
  categories?: Maybe<Array<Category>>;
  /**  length of ranking changes in days. if null, it means adjusting it permanently */
  period?: Maybe<TimePeriod>;
  /**  where the coach should be placed to in the ranking, which is in the range of 1 (top) - 100 (bottom) */
  rankPercentile: Scalars['Int']['output'];
  startAt: Scalars['Long']['output'];
};

export type RankingAdjustmentInput = {
  categoryUrns?: InputMaybe<Array<Scalars['CategoryUrn']['input']>>;
  period?: InputMaybe<TimePeriodInput>;
  rankPercentile: Scalars['Int']['input'];
};

/**  OfferingRecommendation is a recommendation for a package, hourly, or subscription order */
export type RecommendedOffering = {
  __typename?: 'RecommendedOffering';
  couponCode?: Maybe<Scalars['String']['output']>;
  hourlyOrder?: Maybe<HourlyOrder>;
  id: Scalars['RecommendedOfferingUrn']['output'];
  packageOrder?: Maybe<OfferingPackage>;
  sentAt: Scalars['Long']['output'];
  subscriptionOrder?: Maybe<Sku>;
};

export type RecommendedOfferingInput = {
  couponCode?: InputMaybe<Scalars['String']['input']>;
  hourlyOrder?: InputMaybe<HourlyOrderInput>;
  packageOrder?: InputMaybe<PackageOrderInput>;
  subscriptionOrder?: InputMaybe<Scalars['SkuUrn']['input']>;
};

export enum RefundPolicy {
  FLEXIBLE = 'FLEXIBLE',
  MODERATE = 'MODERATE',
  STRICT = 'STRICT',
}

export type Review = {
  __typename?: 'Review';
  anonymous: Scalars['Boolean']['output'];
  applicant?: Maybe<Applicant>;
  categoryReviews?: Maybe<Array<CategoryReview>>;
  coach: Coach;
  comment?: Maybe<Scalars['String']['output']>;
  /**  epoch in milli-second */
  createdAt: Scalars['Long']['output'];
  eventSession?: Maybe<CoachingSession>;
  featured?: Maybe<Scalars['Boolean']['output']>;
  featuredByCoach?: Maybe<Scalars['Boolean']['output']>;
  /**  Present when this review is submitted outside the site. When this is present, applicant should always be null, and anonymous should be false */
  guestReviewer?: Maybe<Scalars['String']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  id: Scalars['ReviewUrn']['output'];
  mostRecentClassSession?: Maybe<CoachingSession>;
  mostRecentOrder?: Maybe<Order>;
  order?: Maybe<Order>;
  outcomes?: Maybe<Array<CoachingOutcomeUnion>>;
  /**  Computed field of all category sub ratings plus the base rate of the review */
  overallRate: Scalars['Float']['output'];
  rate: Scalars['Int']['output'];
  reviewedAt: Scalars['Long']['output'];
  status: ReviewStatus;
  /**  Resolved so it's non-null (or backfilled) */
  type: ReviewType;
  version: Scalars['Int']['output'];
  viewedByOps?: Maybe<Scalars['Boolean']['output']>;
};

export type ReviewBatchResponse = {
  __typename?: 'ReviewBatchResponse';
  count: Scalars['Int']['output'];
  results: Array<Review>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ReviewInput = {
  anonymous: Scalars['Boolean']['input'];
  categoryReviews?: InputMaybe<Array<CategoryReviewInput>>;
  coachUrn?: InputMaybe<Scalars['CoachUrn']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  /**  Event session that this review is for */
  eventSessionUrn?: InputMaybe<Scalars['CoachingSessionUrn']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  /**  previously deprecated in favor of coachUrn, then resurrected */
  orderUrn?: InputMaybe<Scalars['OrderUrn']['input']>;
  rate: Scalars['Int']['input'];
};

export enum ReviewSortBy {
  CREATED_AT = 'CREATED_AT',
  RATING = 'RATING',
  RELEVANCE = 'RELEVANCE',
}

export enum ReviewStatus {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
}

export enum ReviewType {
  REVIEW = 'REVIEW',
  VOUCH = 'VOUCH',
}

export type ReviewVouchInput = {
  anonymous: Scalars['Boolean']['input'];
  coachUrn: Scalars['CoachUrn']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  rate: Scalars['Int']['input'];
};

export type SmsEnrollment = {
  __typename?: 'SMSEnrollment';
  enrolled: Scalars['Boolean']['output'];
  enrollmentGroup: SmsEnrollmentGroup;
};

export enum SmsEnrollmentGroup {
  COACH_COACHING_SESSION_REMINDER = 'COACH_COACHING_SESSION_REMINDER',
  CUSTOMER_COACHING_SESSION_REMINDER = 'CUSTOMER_COACHING_SESSION_REMINDER',
}

export type ScheduleItems = {
  __typename?: 'ScheduleItems';
  calendarId: Scalars['String']['output'];
  endAt: Scalars['Long']['output'];
  /**  Outlook calendar setting */
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  startAt: Scalars['Long']['output'];
  /**  Outlook calendar setting (free/busy); google only returns busy */
  status?: Maybe<Scalars['String']['output']>;
};

export type School = {
  __typename?: 'School';
  domain: Scalars['String']['output'];
  id: Scalars['SchoolUrn']['output'];
  lastModifiedBy?: Maybe<User>;
  logoLink?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type SchoolAdmissionInfo = {
  __typename?: 'SchoolAdmissionInfo';
  school: School;
  status?: Maybe<SchoolAdmissionStatus>;
};

export type SchoolAdmissionInfoInput = {
  schoolUrn: Scalars['SchoolUrn']['input'];
  status?: InputMaybe<SchoolAdmissionStatus>;
};

export enum SchoolAdmissionStatus {
  ADMITTED = 'ADMITTED',
}

export type SchoolBatchResponse = {
  __typename?: 'SchoolBatchResponse';
  count: Scalars['Int']['output'];
  results: Array<School>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type SchoolCategoryFeaturedSection = {
  __typename?: 'SchoolCategoryFeaturedSection';
  description: Scalars['String']['output'];
  schools?: Maybe<Array<School>>;
  title: Scalars['String']['output'];
};

export type SchoolInput = {
  domain: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/**  Aggregate across all school categories */
export type SchoolOutcomeSummary = {
  __typename?: 'SchoolOutcomeSummary';
  number: Scalars['Int']['output'];
  schools: Array<School>;
};

export type SearchMetadata = {
  __typename?: 'SearchMetadata';
  categoryFacets?: Maybe<Array<FacetCategoryContainer>>;
  highlightedWords?: Maybe<Array<Scalars['String']['output']>>;
  offeringFacets?: Maybe<Array<FacetOfferingContainer>>;
  priceFacets?: Maybe<Array<FacetIntRangeContainer>>;
};

export type Shindig = {
  __typename?: 'Shindig';
  categories?: Maybe<Array<CategorySelection>>;
  category: Category;
  coaches?: Maybe<Array<Coach>>;
  date: Scalars['Long']['output'];
  durationInMinute: Scalars['Int']['output'];
  eventManagementUrl?: Maybe<Scalars['String']['output']>;
  guests?: Maybe<Array<Guest>>;
  id: Scalars['ShindigUrn']['output'];
  imageLink?: Maybe<Scalars['String']['output']>;
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
  numGuests?: Maybe<Scalars['Int']['output']>;
  status: ShindigStatus;
  subCategories?: Maybe<Array<SubCategory>>;
};

export type ShindigBatchResponse = {
  __typename?: 'ShindigBatchResponse';
  count: Scalars['Int']['output'];
  results: Array<Shindig>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ShindigInput = {
  categoryUrn: Scalars['CategoryUrn']['input'];
  categoryUrns?: InputMaybe<Array<Scalars['CategoryUrn']['input']>>;
  coachUrns?: InputMaybe<Array<Scalars['CoachUrn']['input']>>;
  date: Scalars['Long']['input'];
  durationInMinute: Scalars['Int']['input'];
  eventManagementUrl?: InputMaybe<Scalars['String']['input']>;
  link: Scalars['String']['input'];
  name: Scalars['String']['input'];
  status?: ShindigStatus;
  subCategoryUrns?: InputMaybe<Array<Scalars['SubCategoryUrn']['input']>>;
};

export type ShindigInputV2 = {
  categoryUrns: Array<Scalars['CategoryUrn']['input']>;
  coachUrns?: InputMaybe<Array<Scalars['CoachUrn']['input']>>;
  date: Scalars['Long']['input'];
  durationInMinute: Scalars['Int']['input'];
  eventManagementUrl?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  status?: ShindigStatus;
  subCategoryV2Urns?: InputMaybe<Array<Scalars['SubCategoryV2Urn']['input']>>;
};

export enum ShindigStatus {
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  LIVE = 'LIVE',
}

export enum SiteTarget {
  APPLICANT = 'APPLICANT',
  COACH = 'COACH',
  SITE = 'SITE',
}

export type Sku = {
  __typename?: 'Sku';
  activated: Scalars['Boolean']['output'];
  deprecated: Scalars['Boolean']['output'];
  family: SkuFamily;
  id: Scalars['SkuUrn']['output'];
  name: Scalars['String']['output'];
  numberOfCustomers?: Maybe<Scalars['Int']['output']>;
  numberOfSessions: Scalars['Int']['output'];
  period: SubscriptionInterval;
  phases?: Maybe<Array<SkuPhase>>;
  price: Scalars['Int']['output'];
  salesFullPayout?: Maybe<Scalars['Int']['output']>;
  salesUnitPrice?: Maybe<Scalars['Int']['output']>;
  tier: SkuTier;
  timeInMinutes: Scalars['Int']['output'];
  unitPayout: Scalars['Int']['output'];
  unitPrice: Scalars['Int']['output'];
};

export type SkuBatchResponse = {
  __typename?: 'SkuBatchResponse';
  count: Scalars['Int']['output'];
  results: Array<Sku>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum SkuFamily {
  LINKEDIN = 'LINKEDIN',
  NATIVE = 'NATIVE',
}

export type SkuInput = {
  deprecated?: Scalars['Boolean']['input'];
  family?: SkuFamily;
  id: Scalars['SkuUrn']['input'];
  name: Scalars['String']['input'];
  period: SubscriptionInterval;
  price: Scalars['Int']['input'];
  tier: SkuTier;
  timeInMinutes: Scalars['Int']['input'];
  unitPayout: Scalars['Int']['input'];
  unitPrice: Scalars['Int']['input'];
};

export type SkuPhase = {
  __typename?: 'SkuPhase';
  numberOfCycles: Scalars['Int']['output'];
  phaseType?: Maybe<SkuPhaseType>;
  price: Scalars['Int']['output'];
  timeInMinutes: Scalars['Int']['output'];
};

export enum SkuPhaseType {
  FREE_TRIAL = 'FREE_TRIAL',
  PROMOTIONAL = 'PROMOTIONAL',
}

export enum SkuTier {
  BASE = 'BASE',
  ELITE = 'ELITE',
  ELITE_PLUS = 'ELITE_PLUS',
  PRO = 'PRO',
}

export enum SortOrder {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export enum SpecialBackground {
  FIRST_GENERATION = 'FIRST_GENERATION',
  INTERNATIONAL = 'INTERNATIONAL',
  LGBTQ = 'LGBTQ',
  LOW_INCOME = 'LOW_INCOME',
  VETERAN = 'VETERAN',
}

export type StringVersionedField = {
  __typename?: 'StringVersionedField';
  actor?: Maybe<User>;
  timestamp: Scalars['Long']['output'];
  value: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type StripeInvoice = {
  __typename?: 'StripeInvoice';
  amountPaid: Scalars['Int']['output'];
  createdAt: Scalars['Long']['output'];
  id: Scalars['String']['output'];
  sku?: Maybe<Sku>;
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  cancelAt?: Maybe<Scalars['Long']['output']>;
  interval: SubscriptionInterval;
  invoices: Array<StripeInvoice>;
  isTrial: Scalars['Boolean']['output'];
  price: Scalars['Int']['output'];
  renewAt?: Maybe<Scalars['Long']['output']>;
};

export type SubCategory = {
  __typename?: 'SubCategory';
  id: Scalars['SubCategoryUrn']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  v2Urn: Scalars['SubCategoryV2Urn']['output'];
};

export type SubCategoryInput = {
  name: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  /**  Receive CoachingRelationship updates for current applicant */
  coachingRelationshipUpdateForCurrentApplicant: CoachingRelationship;
  /**  Receive CoachingRelationship updates for current coach */
  coachingRelationshipUpdateForCurrentCoach: CoachingRelationship;
};

export type SubscriptionCoachingRelationshipUpdateForCurrentApplicantArgs = {
  statuses?: InputMaybe<Array<CoachingRelationshipStatus>>;
};

export type SubscriptionCoachingRelationshipUpdateForCurrentCoachArgs = {
  archivedByCoach?: InputMaybe<Scalars['Boolean']['input']>;
  blockedByCoach?: InputMaybe<Scalars['Boolean']['input']>;
  statuses?: InputMaybe<Array<CoachingRelationshipStatus>>;
};

export enum SubscriptionCancelReason {
  BETTER_ALTERNATIVE = 'BETTER_ALTERNATIVE',
  INSUFFICIENT_CONTENT = 'INSUFFICIENT_CONTENT',
  LELAND_GOAL_REACHED = 'LELAND_GOAL_REACHED',
  NOT_HELPFUL = 'NOT_HELPFUL',
  NO_LONGER_NEEDED = 'NO_LONGER_NEEDED',
  OTHER = 'OTHER',
  TECHNICAL_ISSUES = 'TECHNICAL_ISSUES',
  TOO_EXPENSIVE = 'TOO_EXPENSIVE',
  VALUE_UNEXPECTED = 'VALUE_UNEXPECTED',
}

export enum SubscriptionInterval {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY',
  ONE_TIME = 'ONE_TIME',
  QUARTERLY = 'QUARTERLY',
}

export type SubscriptionOrder = {
  __typename?: 'SubscriptionOrder';
  customerSubscription: CustomerSubscription;
  phaseType?: Maybe<SkuPhaseType>;
  sku: Sku;
  timeInMinute: Scalars['Int']['output'];
};

export enum TestExam {
  GMAT = 'GMAT',
  GRE = 'GRE',
  NOT_TAKEN = 'NOT_TAKEN',
}

/**  Broken apart into individual tests */
export type TestOutcomeSummary = {
  __typename?: 'TestOutcomeSummary';
  category: Category;
  number: Scalars['Int']['output'];
  scoreRange?: Maybe<Scalars['String']['output']>;
  testName: Scalars['String']['output'];
};

export type TestScore = {
  __typename?: 'TestScore';
  score: Scalars['String']['output'];
  test: TestExam;
  tookAt?: Maybe<Scalars['Long']['output']>;
};

export type TestScoreInput = {
  score: Scalars['String']['input'];
  test: TestExam;
  tookAt?: InputMaybe<Scalars['Long']['input']>;
};

export type TestScoreV2 = {
  __typename?: 'TestScoreV2';
  /**  Note: a score of -1 means a score does not exist. See commit: https://github.com/leland-dev/leland-backend/commit/e2f5b4fa5be3a509ca072005443170d17cc55cb4 */
  score: Scalars['Float']['output'];
  testName: Scalars['String']['output'];
};

export type TestScoreV2Input = {
  categoryUrn: Scalars['CategoryUrn']['input'];
  score: Scalars['Float']['input'];
};

export type TestScoreV2ScoreOptionalInput = {
  categoryUrn: Scalars['CategoryUrn']['input'];
  score?: InputMaybe<Scalars['Float']['input']>;
};

export enum TimeFilter {
  PAST = 'PAST',
  UPCOMING = 'UPCOMING',
}

export type TimePeriod = {
  __typename?: 'TimePeriod';
  amount: Scalars['Int']['output'];
  unit: TimeUnit;
};

export type TimePeriodInput = {
  amount: Scalars['Int']['input'];
  unit: TimeUnit;
};

export enum TimeUnit {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
  WEEKS = 'WEEKS',
}

export type TimeZone = {
  __typename?: 'TimeZone';
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  applicant?: Maybe<Applicant>;
  coach?: Maybe<Coach>;
  /**  epoch in milli-second */
  createdAt: Scalars['Long']['output'];
  email: Scalars['String']['output'];
  ethnicities?: Maybe<Array<Ethnicity>>;
  firstName: Scalars['String']['output'];
  gender?: Maybe<Gender>;
  id: Scalars['UserUrn']['output'];
  /**  If present, it means an impersonator is login as the user */
  impersonator?: Maybe<User>;
  interactedFeatures?: Maybe<Array<Scalars['String']['output']>>;
  isOps?: Maybe<Scalars['Boolean']['output']>;
  languages?: Maybe<Array<Scalars['String']['output']>>;
  lastName: Scalars['String']['output'];
  linkedInLink?: Maybe<Scalars['String']['output']>;
  linkedInMember?: Maybe<LinkedInMember>;
  opsPermissions?: Maybe<Array<OpsPermission>>;
  optOutTranscription: Scalars['Boolean']['output'];
  phoneNumber?: Maybe<PhoneNumber>;
  pictureLink?: Maybe<Scalars['String']['output']>;
  preferredPronoun?: Maybe<PreferredPronoun>;
  source?: Maybe<UserSource>;
  userSecrets?: Maybe<UserSecrets>;
};

export type UserInput = {
  ethnicities?: InputMaybe<Array<Ethnicity>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  linkedInLink?: InputMaybe<Scalars['String']['input']>;
  optOutTranscription?: InputMaybe<Scalars['Boolean']['input']>;
  pictureLink?: InputMaybe<Scalars['String']['input']>;
  preferredPronoun?: InputMaybe<PreferredPronoun>;
};

export type UserOrGuest = Guest | User;

export type UserSecrets = {
  __typename?: 'UserSecrets';
  firstContact?: Maybe<FirstContact>;
  id: Scalars['UserUrn']['output'];
};

export type UserSecretsInput = {
  firstContact?: InputMaybe<FirstContactInput>;
};

export type UserSource = {
  __typename?: 'UserSource';
  type: UserSourceType;
};

export enum UserSourceType {
  COACH_REFERRER = 'COACH_REFERRER',
  CUSTOMER_REFERRER = 'CUSTOMER_REFERRER',
  LINKEDIN = 'LINKEDIN',
  LINKEDIN_TEST = 'LINKEDIN_TEST',
}

export type VideoLink = {
  __typename?: 'VideoLink';
  duration: Scalars['String']['output'];
  link: Scalars['String']['output'];
  thumbnailLink: Scalars['String']['output'];
  title: Scalars['String']['output'];
  youtubeId: Scalars['String']['output'];
};

export type VideoLinkInput = {
  link: Scalars['String']['input'];
};
