import { addMinutes, isDateAfter } from '@leland-dev/leland-ui-library';

import { CoachStatus } from '../../../__generated-gql-types__/globalTypes';
import {
  FeatureInteraction,
  useFeatureInteractions,
} from '../../../context/FeatureInteractionsContext';
import { LexSlug, useLex } from '../../../context/LexContext';
import { useOnce } from '../../../utils/hooks/useOnce';

import { useTrialSessionOnboardingModal } from './TrialSessionOnboardingModal';
import { useDisplayTrialSessionOnboardingUserQuery } from './__generated-gql-types__/DisplayTrialSessionOnboardingModal.generated';

const FEATURE = FeatureInteraction.TRIAL_SESSION_ONBOARDING_MODAL;

const DISPLAY_CUTOFF_DATE = new Date('2024-12-05T07:00:00.000Z');

export const DisplayTrialSessionOnboardingModal = () => {
  const { isFeatureInteracted, logFeatureInteraction } =
    useFeatureInteractions();
  const { data: userData } = useDisplayTrialSessionOnboardingUserQuery();

  const isOptInTrialSessions = userData?.user?.coach?.optInTrialSessions;

  const { isLexEnabled } = useLex();

  const isEligibleForTrialSessionOnboarding =
    userData?.user?.coach?.status === CoachStatus.LIVE &&
    (!userData.user.coach.liveAt ||
      // coach went live after the display cutoff date and 5 minutes have passed since going live
      (isDateAfter(userData.user.coach.liveAt, DISPLAY_CUTOFF_DATE) &&
        isDateAfter(Date.now(), addMinutes(userData.user.coach.liveAt, 5))));

  const {
    setOpen: setCoachOnboardingModalOpen,
    Modal: TrialSessionOnboardingModal,
  } = useTrialSessionOnboardingModal();

  useOnce(
    () => setCoachOnboardingModalOpen(true),
    isEligibleForTrialSessionOnboarding &&
      !isOptInTrialSessions &&
      isLexEnabled(LexSlug.TRIAL_SESSION_ONBOARDING_MODAL) &&
      !isFeatureInteracted(FEATURE) &&
      // wait until the session summary modal has been interacted with
      isFeatureInteracted(FeatureInteraction.SESSION_SUMMARY_MODAL),
  );

  return (
    <TrialSessionOnboardingModal
      onClose={async () => {
        if (!isFeatureInteracted(FEATURE)) {
          await logFeatureInteraction(FEATURE);
        }
      }}
    />
  );
};
