import { isDateBefore } from '@leland-dev/leland-ui-library';

import { useAuth } from '../../context/AuthContext';
import {
  FeatureInteraction,
  useFeatureInteractions,
} from '../../context/FeatureInteractionsContext';
import { LexSlug, useLex } from '../../context/LexContext';
import { useOnce } from '../../utils/hooks/useOnce';

import { useSessionSummaryModal } from './SessionSummaryModal';

const DISPLAY_CUTOFF_DATE = new Date('2024-11-20T19:00:00.000Z');

const DisplaySessionSummaryModal = () => {
  const { isLexEnabled } = useLex();
  const { currentUser } = useAuth();

  const { isFeatureInteracted, logFeatureInteraction } =
    useFeatureInteractions();

  const { setOpen: setSessionSummaryModalOpen, Modal: SessionSummaryModal } =
    useSessionSummaryModal();

  useOnce(
    () => setSessionSummaryModalOpen(true),
    isLexEnabled(LexSlug.FEATURE_SESSION_SUMMARIES) &&
      !isFeatureInteracted(FeatureInteraction.SESSION_SUMMARY_MODAL) &&
      !!currentUser &&
      isDateBefore(currentUser.createdAt, DISPLAY_CUTOFF_DATE),
  );

  return (
    <SessionSummaryModal
      onClose={async () => {
        if (!isFeatureInteracted(FeatureInteraction.SESSION_SUMMARY_MODAL)) {
          await logFeatureInteraction(FeatureInteraction.SESSION_SUMMARY_MODAL);
        }
      }}
    />
  );
};

export default DisplaySessionSummaryModal;
