// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import { SetDesiredPayoutCoachFragmentDoc } from '../../__generated-gql-types__/SetDesiredPayout.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DisplayTrialSessionOnboardingUserQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type DisplayTrialSessionOnboardingUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    coach?: {
      __typename?: 'Coach';
      id: string;
      optInTrialSessions?: boolean | null;
      liveAt?: number | null;
      status: Types.CoachStatus;
      hourlyRate?: number | null;
      desiredPayout?: number | null;
      openToLowerThanDesiredPayout?: boolean | null;
    } | null;
  } | null;
};

export const DisplayTrialSessionOnboardingUserDocument = gql`
  query DisplayTrialSessionOnboarding_User {
    user {
      id
      coach {
        id
        optInTrialSessions
        liveAt
        status
        ...SetDesiredPayout_Coach
      }
    }
  }
  ${SetDesiredPayoutCoachFragmentDoc}
`;

/**
 * __useDisplayTrialSessionOnboardingUserQuery__
 *
 * To run a query within a React component, call `useDisplayTrialSessionOnboardingUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplayTrialSessionOnboardingUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplayTrialSessionOnboardingUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useDisplayTrialSessionOnboardingUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DisplayTrialSessionOnboardingUserQuery,
    DisplayTrialSessionOnboardingUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DisplayTrialSessionOnboardingUserQuery,
    DisplayTrialSessionOnboardingUserQueryVariables
  >(DisplayTrialSessionOnboardingUserDocument, options);
}
export function useDisplayTrialSessionOnboardingUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DisplayTrialSessionOnboardingUserQuery,
    DisplayTrialSessionOnboardingUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DisplayTrialSessionOnboardingUserQuery,
    DisplayTrialSessionOnboardingUserQueryVariables
  >(DisplayTrialSessionOnboardingUserDocument, options);
}
export function useDisplayTrialSessionOnboardingUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DisplayTrialSessionOnboardingUserQuery,
    DisplayTrialSessionOnboardingUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DisplayTrialSessionOnboardingUserQuery,
    DisplayTrialSessionOnboardingUserQueryVariables
  >(DisplayTrialSessionOnboardingUserDocument, options);
}
export type DisplayTrialSessionOnboardingUserQueryHookResult = ReturnType<
  typeof useDisplayTrialSessionOnboardingUserQuery
>;
export type DisplayTrialSessionOnboardingUserLazyQueryHookResult = ReturnType<
  typeof useDisplayTrialSessionOnboardingUserLazyQuery
>;
export type DisplayTrialSessionOnboardingUserSuspenseQueryHookResult =
  ReturnType<typeof useDisplayTrialSessionOnboardingUserSuspenseQuery>;
export type DisplayTrialSessionOnboardingUserQueryResult = Apollo.QueryResult<
  DisplayTrialSessionOnboardingUserQuery,
  DisplayTrialSessionOnboardingUserQueryVariables
>;
