import {
  Button,
  ButtonColor,
  ButtonWidth,
  formatPrice,
  Modal,
  ModalContent,
  type ModalHook,
  ModalSize,
  Tag,
  TagColor,
  TagRounding,
  ToastDuration,
  ToastType,
  useControlledModalState,
  useToast,
} from '@leland-dev/leland-ui-library';
import Image from 'next/image';
import React, { type FC, useCallback, useState } from 'react';

import { SkuTier } from '../../../__generated-gql-types__/globalTypes';
import TrialSessionsImage from '../../../assets/images/trial-session-image.png';
import {
  FeatureInteraction,
  useFeatureInteractions,
} from '../../../context/FeatureInteractionsContext';
import { wasMutationSuccessful } from '../../../utils/mutations';
import SwitchInput from '../../inputs/SwitchInput';

import {
  useTrialSessionOnboardingOneSessionMonthlySkuQuery,
  useTrialSessionOnboardingUpdateOptInTrialSessionsMutation,
  useTrialSessionOnboardingUserQuery,
} from './__generated-gql-types__/TrialSessionOnboardingModal.generated';

interface TrialSessionOnboardingModalHookProps {
  onClose: () => void;
}

interface TrialSessionOnboardingModalProps
  extends TrialSessionOnboardingModalHookProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const TrialSessionOnboardingModalContent: FC<
  TrialSessionOnboardingModalProps
> = ({ open, setOpen: setOpenProp, onClose }) => {
  const { data: userData } = useTrialSessionOnboardingUserQuery();

  const [isOptInTrialSessions, setIsOptInTrialSessions] = useState(true);

  const currentTier = userData?.user?.coach?.tier;

  const { data: maxHourlyPayoutForCoachData } =
    useTrialSessionOnboardingOneSessionMonthlySkuQuery({
      variables: { tier: currentTier ?? SkuTier.BASE },
      skip: !currentTier,
    });

  const maxHourlyPayoutForCoach =
    maxHourlyPayoutForCoachData?.findSkuBySession.unitPayout;

  const coachTakeHomeForTrialSession = Math.min(
    userData?.user?.coach?.desiredPayout ?? Infinity,
    maxHourlyPayoutForCoach ?? Infinity,
  );

  const [
    updateOptInTrialSessions,
    { loading: updateOptInTrialSessionsInFlight },
  ] = useTrialSessionOnboardingUpdateOptInTrialSessionsMutation();

  const { isFeatureInteracted, logFeatureInteraction } =
    useFeatureInteractions();

  const { showToast } = useToast();

  const setOpen = useCallback(
    (value: boolean) => {
      setOpenProp(value);
      if (!value) {
        onClose();
      }
    },
    [setOpenProp, onClose],
  );

  const handleOptInTrialSession = useCallback(
    async (optIn: boolean) => {
      if (!userData?.user?.coach) {
        return;
      }
      setIsOptInTrialSessions(optIn);
      try {
        const res = await updateOptInTrialSessions({
          variables: { optInTrialSessions: optIn },
          optimisticResponse: {
            __typename: 'Mutation',
            updateUser: {
              __typename: 'User',
              id: userData.user.id,
              coach: {
                __typename: 'Coach',
                id: userData.user.coach.id,
                optInTrialSessions: optIn,
              },
            },
          },
        });

        if (!wasMutationSuccessful(res)) {
          showToast({
            type: ToastType.ERROR,
            duration: ToastDuration.SHORT,
            message: `Error opting ${optIn ? 'in' : 'out'}. Please try again.`,
          });
          console.warn(res.errors);
        }
      } catch (e) {
        console.warn(e);
      }
    },
    [
      showToast,
      updateOptInTrialSessions,
      userData?.user?.coach,
      userData?.user?.id,
    ],
  );

  const handleLooksGood = useCallback(async () => {
    if (
      !isFeatureInteracted(FeatureInteraction.TRIAL_SESSION_ONBOARDING_MODAL)
    ) {
      await logFeatureInteraction(
        FeatureInteraction.TRIAL_SESSION_ONBOARDING_MODAL,
      );
    }
    await handleOptInTrialSession(isOptInTrialSessions);
    setOpen(false);
  }, [
    logFeatureInteraction,
    isFeatureInteracted,
    handleOptInTrialSession,
    isOptInTrialSessions,
    setOpen,
  ]);

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalContent size={ModalSize.SMALL}>
        <div className="ml-6 mt-6">
          <Tag
            text="Get more leads!"
            tagColor={TagColor.PRIMARY}
            rounding={TagRounding.SM}
          />
        </div>

        <h3 className="my-3 ml-6 text-xl font-medium leading-7">
          Introducing Leland’s Partner Program
        </h3>
        <Image
          src={TrialSessionsImage}
          className="mx-auto h-auto w-[90%]"
          alt=""
        />
        <p className="mx-6 my-3 text-lg text-leland-gray-light">
          Leland is beginning to offer trial sessions for bootcamp participants
          and partner organizations. Trial sessions are generally 30 minutes and
          will pay you{' '}
          {formatPrice(Math.min(coachTakeHomeForTrialSession, 5000))}.
        </p>
        <p className="mx-6 text-lg text-leland-gray-light">
          Beyond the trial session, you will be able to continue working with
          those clients at{' '}
          {coachTakeHomeForTrialSession <= 5000
            ? 'that same rate'
            : `${formatPrice(Math.min(coachTakeHomeForTrialSession, 7500))}/hour`}{' '}
          (plus additional bonuses for you).{' '}
          <a
            href="https://joinleland.notion.site/Trial-Sessions-and-Partnerships-114a84f4c7d28058bb46e76ad2677de6"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            {' '}
            Learn more here
          </a>
        </p>
        <hr className="m-6 bg-leland-gray-stroke" />
        <div className="mx-6 flex items-start gap-2">
          <div className="flex-[8]">
            <h5 className="text-lg font-medium leading-7">
              Receive partner program opportunities
            </h5>
            <p className="mb-6 text-lg text-leland-gray-light">
              These opportunities include a trial session. You can edit this
              setting in your Opportunities page.
            </p>
          </div>
          <div className="-mt-1 flex-1">
            <SwitchInput
              label=""
              labelClassName="!text-leland-gray-light"
              className="mt-3 w-[10%]"
              isDisabled={updateOptInTrialSessionsInFlight}
              isChecked={!!isOptInTrialSessions}
              onToggle={handleOptInTrialSession}
            />
          </div>
        </div>
        <div className="mb-5 w-full px-6">
          <Button
            label="Looks good"
            buttonColor={ButtonColor.PRIMARY}
            width={ButtonWidth.FULL}
            onClick={handleLooksGood}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};

export const useTrialSessionOnboardingModal: ModalHook<
  TrialSessionOnboardingModalHookProps
> = () => {
  const [open, setOpen] = useControlledModalState();
  const TrialSessionOnboardingModal: FC<
    TrialSessionOnboardingModalHookProps
  > = (props) => {
    return open ? (
      <TrialSessionOnboardingModalContent
        {...props}
        open={open}
        setOpen={setOpen}
      />
    ) : null;
  };
  return { open, setOpen, Modal: TrialSessionOnboardingModal };
};

export default TrialSessionOnboardingModalContent;
